import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SimpleCard from '../components/SimpleCard';
import { Link } from '@mui/material';
import financeCover from '../images/finance.png'
import EaCourses from '../components/EaCourses';
import NotionDataFetcher from '../components/NotionDataFetcher';
import UserProfile from './UserProfile';
import { UpdateUser } from './UpdateUser';

const StyledPaper = styled(Paper)({
    padding: '1rem',
    height: '100%',
    background: '#eeeeee'
});

const courseData = [
    {
        id: '1',
        title: 'Course outline',
        status: 'Reviewing',
    },
    {
        id: '2',
        title: 'Scripting',
        status: 'Reviewing',
    },
    {
        id: '3',
        title: 'Recording',
        status: 'In Progress',
    },
    {
        id: '4',
        title: 'Editing',
        status: 'In Progress',
    },
    {
        id: '5',
        title: 'Final Review',
        status: 'In Review',
    },
    {
        id: '6',
        title: 'Publishing',
        status: 'Completed',
    },
];

export const Sop = () => {
    const [data, setData] = React.useState(courseData);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(data);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setData(items);
    };

    return (
        <Container sx={{ mt: 4 }}>
     <UpdateUser/>

            {/* <NotionDataFetcher/> */}


            <Grid sx={{ mt: 2 }} container spacing={2}>
        
            <Grid item xs={12} md={2}>

<Box
    sx={{
        height: '100%',
    }}
>
   
      
</Box>
</Grid>


            </Grid>
        </Container>
    )
};
