import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button,
  Grid
} from '@mui/material';
import { Page, Text, View, Document, PDFDownloadLink, StyleSheet } from '@react-pdf/renderer';
import { fetchAgenda } from '../services/apiService'; // Adjust the import path as necessary
import moment from 'moment-timezone'; // Import moment-timezone to handle timezone conversion

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #000',
    paddingBottom: 8,
    marginVertical: 5,
  },
  text: {
    fontSize: 12,
  },
  boldText: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  heading: {
    fontSize: 16,
    marginBottom: 10,
  },
});

// Function to convert time to MST
const convertToStandardTime = (time) => {
  return moment(time, "HH:mm:ss").tz("America/Denver").format("hh:mm A");
};

// Function to convert date to MST
const convertToMSTDate = (date) => {
  return moment(date).tz("America/Denver").format("dddd, MMM Do");
};

// Helper function to format description with line breaks
const formatDescriptionWithLineBreaks = (description) => {
  if (!description) return null;
  return description.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
};

// PDF component for generating the PDF agenda
const PDFAgenda = ({ agendaData, locationDetails }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.heading}>{locationDetails.title}</Text>
      <Text style={styles.text}>{locationDetails.location}</Text>
      <Text style={styles.text}>{locationDetails.address}</Text>
      <Text style={styles.text}>{locationDetails.city}, {locationDetails.state} {locationDetails.zip}</Text>

      {agendaData.map((day, dayIndex) => (
        <View key={dayIndex}>
          <Text style={{ fontSize: 12, marginTop: 20, marginBottom: 10 }}>
            {convertToMSTDate(day.attributes.Date)}
          </Text>
          {day.attributes.agenda_events.data
            .sort((a, b) => moment(a.attributes.time, "HH:mm:ss") - moment(b.attributes.time, "HH:mm:ss"))
            .map((event, eventIndex) => (
              <View key={eventIndex} style={styles.table}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text style={styles.boldText}>{convertToStandardTime(event.attributes.time)}</Text>
                  <Text style={{ textAlign: 'left', fontSize: 10, marginLeft: 12 }}>{event.attributes.title}</Text>
                </View>

                {event.attributes.description && (
                  <Text style={{ fontSize: 9, marginTop: 2 }}>
                    {event.attributes.description.split('\n').map((line, i) => (
                      <Text key={i}>{line}{'\n'}</Text>
                    ))}
                  </Text>
                )}

                {event.attributes.presenter && event.attributes.presenter.length > 0 && (
                  <Text style={{ fontSize: 8, marginTop: 3 }}>
                   {event.attributes.presenter.map((p) => p.name).join(', ')}
                  </Text>
                )}
              </View>
            ))}
        </View>
      ))}
    </Page>
  </Document>
);

export default function AgendaTable() {
  const [agendaData, setAgendaData] = useState([]);
  const [locationDetails, setLocationDetails] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchAgenda();
        setAgendaData(response.attributes.agenda_days.data);
        setLocationDetails({
          title: response.attributes.title,
          location: response.attributes.location,
          address: response.attributes.address,
          city: response.attributes.city,
          state: response.attributes.state,
          zip: response.attributes.zip,
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching agenda:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  
  return (
    <>
      <Box
        sx={{
          my: 2,
          mb: 4,
          display: 'flex',
          flexDirection: 'row',  // Change to row for horizontal alignment
          alignItems: 'flex-start',
          mt: 4
        }}
      >
        <Box  sx={{mr: 2, maxWidth: 100, mt: 1}}>  {/* Add margin to the right */}
          <img style={{width: '100%'}} src={'https://utahsbdc.org/wp-content/uploads/2020/11/Logo-COLOR_1.png'} alt="React logo" />
        </Box>

        <Box>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            sx={{ fontWeight: 'bold' }}
          >
            {locationDetails.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {locationDetails.location}<br />
            {locationDetails.address}<br />
            {locationDetails.city}, {locationDetails.state} {locationDetails.zip}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ width: '100%' }}>
        {agendaData.map((day, dayIndex) => (
          <React.Fragment key={dayIndex}>
            {/* Proper margin and alignment for date */}
            <Box sx={{ mt: 3, mb: 2, textAlign: 'left' }}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontSize: 18, fontWeight: 'bold' }}
              >
                {convertToMSTDate(day.attributes.Date)}
              </Typography>
            </Box>

            <TableContainer component={Paper}>
              <Table sx={{  }} aria-label={`${convertToMSTDate(day.attributes.Date)} agenda table`}>
                <TableHead>
                  <TableRow>
                    <TableCell>Time</TableCell>
                    <TableCell align="right">Agenda Item</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {day.attributes.agenda_events.data
                    .sort((a, b) => moment(a.attributes.time, "HH:mm:ss") - moment(b.attributes.time, "HH:mm:ss"))
                    .map((event, eventIndex) => (
                      <TableRow
                        key={eventIndex}
                        sx={{
                          backgroundColor: eventIndex % 2 === 0 ? 'action.hover' : 'background.default',
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
                            {convertToStandardTime(event.attributes.time)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>{event.attributes.title}</Typography>

                          {event.attributes.description && (
                            <Typography sx={{ fontSize: 10 }}>
                              {formatDescriptionWithLineBreaks(event.attributes.description)}
                            </Typography>
                          )}

                          {event.attributes.presenter && event.attributes.presenter.length > 0 && (
                            <Typography sx={{ fontSize: 10, fontStyle: 'italic' }}>
                              {event.attributes.presenter.map((p) => p.name).join(', ')}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        ))}
      </Box>

      <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
        <PDFDownloadLink
          document={<PDFAgenda agendaData={agendaData} locationDetails={locationDetails} />}
          fileName="2024_SBDC_Directors_Meeting_Agenda.pdf"
          style={{ textDecoration: 'none' }}
        >
          {({ loading }) =>
            loading ? (
              <Button variant="contained" color="primary">
                Loading PDF...
              </Button>
            ) : (
              <Button variant="contained" color="primary">
                Download PDF
              </Button>
            )
          }
        </PDFDownloadLink>
      </Grid>
    </>
  );
}
