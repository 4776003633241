import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PieChart from "../components/charts/CountyChart";
import CenterChart from "../components/charts/centerChart";
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useLocation} from "react-router-dom";
import {PageWrapper} from "../pages/PageWrapper";
import { MarketSegmentationTableDetails } from "../components/MarketSegmentationTableDetails";
import { useParams } from "react-router-dom";
import { MarketSegmentationCenterCodeDetails } from "../components/MarketSegmentationCenterCodeDetails";
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '../utils/localStorage';
import CountyContacts from "./CountyContacts";

const cacheDuration = 60 * 60 * 1000; // 1 hour

export const MarketSegmentationCountyCodeDetails = (props) => {
    const location = useLocation();

    const { codeId } = useParams();

    const naicsCodes = ['11', '21', '22', '23', '31', '32', '33', '42', '44', '45', '48', '49', '51', '52', '53', '54', '55', '56', '61', '62', '71', '72', '81'];

const categories = [
    { title: 'Agriculture, Forestry, Fishing and Hunting', visualCodes: '11', codes: ['11'] },
    { title: 'Mining, Quarrying, and Oil and Gas Extraction', visualCodes: '21', codes: ['21'] },
    { title: 'Utilities', visualCodes: '22', codes: ['22'] },
    { title: 'Construction', visualCodes: '23',codes: ['23'] },
    { title: 'Manufacturing', visualCodes: '31-33', codes: ['31', '32', '33'] },
    { title: 'Wholesale Trade', visualCodes: '42', codes: ['42'] },
    { title: 'Retail Trade', visualCodes: '44-45', codes: ['44', '45'] },
    { title: 'Transportation and Warehousing', visualCodes: '48-49', codes: ['48', '49'] },
    { title: 'Information', visualCodes: '51', codes: ['51'] },
    {title: 'Finance and Insurance', visualCodes: '52', codes: ['52'] },
{ title: 'Real Estate and Rental and Leasing', visualCodes: '53', codes: ['53'] },
{ title: 'Professional, Scientific, and Technical Services', visualCodes: '54', codes: ['54'] },
{ title: 'Management of Companies and Enterprises', visualCodes: '55', codes: ['55'] },
{ title: 'Administrative and Support and Waste Management and Remediation Services', visualCodes: '56', codes: ['56'] },
{ title: 'Educational Services', visualCodes: '61', codes: ['61'] },
{ title: 'Health Care and Social Assistance', visualCodes: '62', codes: ['62'] },
{ title: 'Arts, Entertainment, and Recreation', visualCodes: '71', codes: ['71'] },
{ title: 'Accommodation and Food Services', visualCodes: '72', codes: ['72'] },
{ title: 'Other Services (except Public Administration)', visualCodes: '81', codes: ['81'] },
];


function getTitleByVisualCode(visualCode) {
 
    for (let category of categories) {
        if (category.visualCodes == visualCode) {
            return category.title;
        }
    }
    return 'Not found';
}



  

    const { user } = useAuth0();
    const [data, setData] = useState(null);
    const [total, setTotal] = useState();
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(true);


    const [responseData, setResponseData] = useState({});




    const dataArray = Array.from(responseData);

    const categoryCounts = categories.reduce((acc, category) => {
      acc[category.title] = { count: 0, vCodes: category.visualCodes, codes: category.codes };
      return acc;
    }, {});
  
    // dataArray.forEach(entry => {
    //   const code = entry['NAICS (2 Digit)'];
    //   const category = Object.values(categoryCounts).find(cat => cat.codes.includes(code));
    //   if (category) {
    //     category.count += 1;
    //   }
    // });


// Helper function to find 'codes' for a given 'codeId'


function findCodesByCodeId(codeId) {
    if (codeId.includes('-')) {
      const [start, end] = codeId.split('-').map(Number);
      let codes = [];
      for (let i = start; i <= end; i++) {
        codes.push(i.toString());
      }
      return codes;
    } else {
      return [codeId];
    }
  }
  
  
  const targetCodes = findCodesByCodeId(codeId);
  const filteredDataArray = dataArray.filter(entry => targetCodes.includes(entry['NAICS (2 Digit)']));
  
  filteredDataArray.forEach(entry => {
    const code = entry['NAICS (2 Digit)'];
    const category = Object.values(categoryCounts).find(cat => cat.codes.includes(code));
    if (category) {
      category.count += 1;
    }
  });
  
    // Use categoryCounts object for your purpose
    console.log(dataArray, 'dataARRAYY');



    useEffect(() => {
        const fetchCollectionData = async (collectionName) => {
            // ... previous code ...
            const apiUrl = "/.netlify/functions/findData";
            const endpoint = `${apiUrl}?database=marketSegmentation&collection=clients&center=${user.center}`;
            
            const cachedData = getLocalStorageWithExpiry(`marketSegmentation-${user.center}`);
    
            if (cachedData) {
                setResponseData(cachedData);
                setProgress(100);
            } else {
                try {
                    const response = await axios.get(endpoint);
                    const data = response.data;
                    setResponseData(data);
                    setProgress(100);
                    setLocalStorageWithExpiry(`marketSegmentation-${user.center}`, data, cacheDuration);
                } catch (error) {
                    console.error(error);
                }
            }
        };
    
        fetchCollectionData(user.center);
    }, []);




   






    if (!user) {
        return null;
    } else if (!responseData) {
        return null;
    }




    const totalCount = filteredDataArray.length;
    console.log(location.state, 'LOCATION!');
if(location) {


    return (


<PageWrapper title="2021-2022 Market Segmentation" secondaryTitle={location.state.county.county + " County"} subtitle={getTitleByVisualCode(codeId)}>

                        {/* <Grid lg={4}>

        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography sx={{ fontSize: 14, textAlign: 'center', textTransform: 'capitalize' }} gutterBottom>
                    {user.center} Center
                </Typography>
                <Typography sx={{ textAlign: 'center', fontSize: 10 }} variant="h5" color="text.secondary" component="div">
                    Total # of Startup Businesses
                </Typography>



                <Typography sx={{ textAlign: 'center', fontSize: 20, mt: 1.5, mb: 1.5, fontWeight: 'bold' }} variant="h5" color="text.secondary" component="div">
                    {progress == 100
                        ? totalCount
                        : <CircularProgress />
                    }
               

                </Typography>

                <CenterChart title="SBDC Center" baseUrl="market-segmentation/center-detail-code" data={categoryCounts} />
               
            </CardContent>
            <CardActions>
                <Button size="small">See Details</Button>
            </CardActions>
        </Card>

        




</Grid> */}
<Grid lg={12}>


<Card sx={{ minWidth: 275 }}>
    <CardContent>

    <CountyContacts fips={location.state.county.fips}/>
      
        
        {/* <MarketSegmentationTable data={data} /> */}

    </CardContent>
    <CardActions>

    </CardActions>
</Card>










</Grid>

        </PageWrapper>

    );
} else {

    return(null)
}
};
