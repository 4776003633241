import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Card, CardContent, CircularProgress } from '@mui/material';
import axios from 'axios';

const CourseList = ({ title, courses }) => {
  return (
    <>
      <Typography variant="h2" sx={{ fontSize: 18, color: '#222', textAlign: 'left', width: '100%', mt: 4, backgroundColor: '#002d62', color: '#fff', padding: 3, borderRadius: '4px' }}>
        {title}
      </Typography>
      {courses.map((course) => (
        <Grid item xs={12} sm={6} md={4} key={course.id}>
          <Card>
            <CardContent>
              <img src={course.courseImage} alt={course.title} style={{ width: '100%', height: 'auto' }} />
              <Typography variant="h2" sx={{ fontSize: 18, mt: 2 }} gutterBottom>{course.title}</Typography>
              <Typography sx={{ fontWeight: '100', fontSize: 12 }}>
                Session Time: {(course.analytics.total_study_time / 3600).toFixed(2)} hours
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
};

const EntrepreneurAcademy = () => {
  const [loading, setLoading] = useState(true); // Loading state
  const [totalUsers, setTotalUsers] = useState(0);
  const [neverLoggedInUsers, setNeverLoggedInUsers] = useState(0);
  const [eCounselingCourses, setECounselingCourses] = useState([]);
  const [legacyCourses, setLegacyCourses] = useState([]);
  const [totalStudyHours, setTotalStudyHours] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const yesterday = Math.floor(Date.now() / 1000) - 86400; // UNIX timestamp for yesterday

      try {
        // Fetch total users
        const usersResponse = await axios.get('/.netlify/functions/fetchEaUsers', {
          params: { registration_before: yesterday }
        });
        if (usersResponse.data && usersResponse.data.meta) {
          setTotalUsers(usersResponse.data.meta.totalItems);
        } else {
          console.error('Invalid users response:', usersResponse);
        }

        // Fetch never logged in users
        const neverLoggedInResponse = await axios.get('/.netlify/functions/fetchEaNeverLoggedInUsers', {
          params: { registration_before: yesterday }
        });
        if (neverLoggedInResponse.data && neverLoggedInResponse.data.meta) {
          setNeverLoggedInUsers(neverLoggedInResponse.data.meta.totalItems);
        } else {
          console.error('Invalid never logged in users response:', neverLoggedInResponse);
        }

        // Fetch eCounseling courses
        const eCounselingResponse = await axios.get('/.netlify/functions/fetchEaCourses', {
          params: { categories: 'eCounseling' }
        });
        if (eCounselingResponse.data) {
          const sortedECounselingCourses = eCounselingResponse.data.sort((a, b) => b.analytics.total_study_time - a.analytics.total_study_time);
          setECounselingCourses(sortedECounselingCourses);
        } else {
          console.error('Invalid eCounseling courses response:', eCounselingResponse);
        }

        // Fetch legacy courses
        const legacyResponse = await axios.get('/.netlify/functions/fetchEaCourses', {
          params: { categories: 'legacy' }
        });
        if (legacyResponse.data) {
          const sortedLegacyCourses = legacyResponse.data.sort((a, b) => b.analytics.total_study_time - a.analytics.total_study_time);
          setLegacyCourses(sortedLegacyCourses);
        } else {
          console.error('Invalid legacy courses response:', legacyResponse);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, []);

  // Recalculate the total study hours whenever eCounselingCourses or legacyCourses change
  useEffect(() => {
    if (eCounselingCourses.length > 0 || legacyCourses.length > 0) {
      const totalHours = [...eCounselingCourses, ...legacyCourses].reduce(
        (acc, course) => acc + (course.analytics?.total_study_time || 0), 
        0
      ) / 3600; // Convert from seconds to hours
      setTotalStudyHours(totalHours.toFixed(2));
    }
  }, [eCounselingCourses, legacyCourses]);

  const loggedInUsers = totalUsers - neverLoggedInUsers;

  return (
    <Grid container spacing={4}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ width: '100%', textAlign: 'center', mb: 0, mt: 2 }}>
            <img src="https://lwfiles.mycourse.app/631e631a474491b58ad0d9c0-public/56794b587298baf4b7072eef5aba21c5.png" alt="logo" style={{ maxWidth: 500, marginTop: 10 }} />
            <Typography>Data Since February 5, 2024</Typography>
          </Box>

          <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
            <Grid item xs={12} sm={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Total Users</Typography>
                  <Typography variant="h4">{totalUsers}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Users That Have Logged In</Typography>
                  <Typography variant="h4">{loggedInUsers}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Total Study Hours</Typography>
                  <Typography variant="h4">{totalStudyHours} hours</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <CourseList title="eCounseling Modules" courses={eCounselingCourses} />
          <CourseList title="Legacy Modules" courses={legacyCourses} />
        </>
      )}
    </Grid>
  );
};

export default EntrepreneurAcademy;
