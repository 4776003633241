import { useEffect, useState } from 'react';
import { fetchData } from './utils/contactsApi';
import { useParams } from "react-router-dom";
import ApiResponseTable from './ApiResponseTable';
import { useNavigate } from "react-router-dom";
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '../utils/localStorage';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';
const CountyContacts = ({fips}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const fipsCode = fips; // Replace with desired FIPS code
  const { codeId } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [approved, setApproved] = useState(false);

  const navigate = useNavigate();

  // Array of naics codes
  const newArray = [];




function getSectorIdsByVisualCode(visualCode) {


  const sectors = [
    { "sectorIds": ["11"], "sectorName": "Agriculture, Forestry, Fishing and Hunting", "visualCode": "11" },
    { "sectorIds": ["21"], "sectorName": "Mining, Quarrying, and Oil and Gas Extraction", "visualCode": "21" },
    { "sectorIds": ["22"], "sectorName": "Utilities", "visualCode": "22" },
    { "sectorIds": ["23"], "sectorName": "Construction", "visualCode": "23" },
    { "sectorIds": ["31", "32", "33"], "sectorName": "Manufacturing", "visualCode": "31-33" },
    { "sectorIds": ["42"], "sectorName": "Wholesale Trade", "visualCode": "42" },
    { "sectorIds": ["44", "45"], "sectorName": "Retail Trade", "visualCode": "44-45" },
    { "sectorIds": ["48", "49"], "sectorName": "Transportation & Warehousing", "visualCode": "48-49" },
    { "sectorIds": ["51"], "sectorName": "Information", "visualCode": "51" },
    { "sectorIds": ["52"], "sectorName": "Finance & Insurance", "visualCode": "52" },
    { "sectorIds": ["53"], "sectorName": "Real Estate, Rental & Leasing", "visualCode": "53" },
    { "sectorIds": ["54"], "sectorName": "Professional, Scientific & Technical Services", "visualCode": "54" },
    { "sectorIds": ["55"], "sectorName": "Management, Companies, Enterprises", "visualCode": "55" },
    { "sectorIds": ["56"], "sectorName": "Administrative, Support, Waste Management & Remediation", "visualCode": "56" },
    { "sectorIds": ["61"], "sectorName": "Educational Services", "visualCode": "61" },
    { "sectorIds": ["62"], "sectorName": "Health Care & Social Assistance", "visualCode": "62" },
    { "sectorIds": ["71"], "sectorName": "Arts, Entertainment & Recreation", "visualCode": "71" },
    { "sectorIds": ["72"], "sectorName": "Accommodation & Food Services", "visualCode": "72" },
    { "sectorIds": ["81"], "sectorName": "Other Services", "visualCode": "81" }
]
  

  const matchingSectorIds = sectors
      .filter(sector => sector.visualCode === visualCode)
      .map(sector => sector.sectorIds);

  return matchingSectorIds.length > 0 ? matchingSectorIds[0] : null;
}

const visualCode =codeId;
const sectorIds = getSectorIdsByVisualCode(visualCode);
console.log(sectorIds);


  




  newArray.push(codeId);

  
  useEffect(() => {
    const cacheKey = `test-${fipsCode}-${newArray[0]}`;
    const cachedData = getLocalStorageWithExpiry(cacheKey);
    // localStorage.clear(); 
   
    if (cachedData) {
      setData(cachedData);
      setLoading(false);
    } else {

      setOpenDialog(true);
      
      const getData = async () => {
        try {
          const response = await fetchData(fipsCode,  sectorIds);
          setData(response);
          setLoading(false);
          setLocalStorageWithExpiry(cacheKey, response, 3600000); // Cache data for 1 hour
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      };

      if(approved) {
        getData();
      }

      
    }
  }, [approved]);

  const handleClose = () => {
    setOpenDialog(false);
    navigate(`/market-segmentation/`);
};

const handleApproved = () => {
  setApproved(true);
  setOpenDialog(false);
};



  if (loading) {
    return <><Dialog open={openDialog} onClose={handleClose}>
    <DialogTitle>Confirmation</DialogTitle>
    <DialogContent>
        <DialogContentText>
            Are you sure you want to continue?
            <Typography>This will use credits, please only use this if you are sure you need this data.</Typography>
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleApproved}>Continue</Button>
    </DialogActions>
</Dialog></>;
  }

  return (
    <div>




      {/* Use data in your component */}
       {!loading
       ?  <ApiResponseTable apiResponse={data.responseData} />
      : null
      }
     
      {/* <pre>{JSON.stringify(data.responseData, null, 2)}</pre> */}
    </div>
  );
};

export default CountyContacts;
