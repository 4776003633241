import React from 'react';
import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography } from '@mui/material';
import HotelInformation from './HotelInformation';
// import Logo from './path-to-your-logo.png'; // Please update with your actual logo path
// import HotelImage from './imag'; // Please update with your actual hotel image path


const agendaData = [
  { time: '8:00AM', event: 'Hosted Breakfast' },
  { time: '8:30AM', event: 'Mike Finnerty – Welcome and Introductions' },
  { time: '8:45AM', event: 'Mike Finnerty – Preparations for State Senators Vickers and Ipson' },
  { time: '9:15AM', event: 'Mike Finnerty – SWOT Analysis' },
  { time: '10:45AM', event: 'Break' },
  { time: '11:00AM', event: 'State Senator Vickers and Ipson Discussion' },
  { time: '12:00PM', event: 'Hosted Lunch' },
  { time: '1:00PM', event: 'Mike Finnerty, Aaron Griffiths – SBDC Dashboard, Market Segmentation, and Entrepreneur Academy' },
  { time: '2:30PM', event: 'Mike Finnerty – SBDC Goals, BEDL Committee Strategy, Accreditation' },
  { time: '2:45PM', event: 'Break' },
  { time: '3:00PM', event: 'Mike Finnerty – SBDC Huddle, Marketing Plans, Success Stories' },
  { time: '3:30PM', event: 'Jason Yerka – Operations Manual, CenterIC Training, Sub-Awards' },
  { time: '4:30PM', event: 'Mike Finnerty - Wrap Up' },
  { time: '4:45PM', event: 'Adjourn' },
  { time: '5:30PM', event: 'Hosted Dinner' },
];

export default function AgendaTable() {
  return (
    <>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', my: 2, mb: 4}}>
      {/* <img src={Logo} alt="Conference Logo" style={{ maxWidth: '200px' }} /> Adjust size as needed */}
      <Typography variant="h6" component="div" gutterBottom sx={{fontWeight: 'bold'}} sx={{mb: 2, fontWeight: 'bold'}}>
        Tuesday, October 24, 2023
      </Typography>
        <HotelInformation/>
    </Box>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Tentative Agenda</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {agendaData.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                backgroundColor: index % 2 === 0 ? 'action.hover' : 'background.default', // alternating colors
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                <Typography sx={{fontWeight: 'bold'}}>
                {row.time}
                </Typography>
          
              </TableCell>
              <TableCell align="right">
                <Typography sx={{fontSize: 12}}>
                {row.event}
                </Typography>
               </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
  );
}
