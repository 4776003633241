import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { PageWrapper } from '../../pages/PageWrapper';
import { useAuth0 } from "@auth0/auth0-react";
import { Select, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DateDisplay from '../DateDisplay';
import CoursesList from '../charts/CoursesList';


const CounselorCertification = () => {
  const [courses, setCourses] = useState([]); 

console.log(courses);
  



// Example data structure for user certifications
const userCertifications = [
  { title: 'Forming Your Business', certifiedDate: '2023-10-01' },
  { title: 'Marketing Your Business', certifiedDate: '2023-10-03' },
  // ... other certifications
];
  

  const {
    isAuthenticated,
    logout,
    user
  } = useAuth0();
  useEffect(() => {
    


    
     

  }, []);

  

  useEffect(() => {
    if (isAuthenticated && user) {
      let userEmail = user.email;
  
      axios.post('/.netlify/functions/fetchCourses', {
        email: userEmail // Send email in the request body
      })
      .then(response => {
        setCourses(response.data); // Update your state with the response
      })
      .catch(error => {
        console.error('Error fetching courses from Lambda:', error);
      });
    }
  }, [isAuthenticated]);



  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

  return (
<>
<PageWrapper title="Counselor Certification" secondaryTitle={" "}>



<CoursesList courses={courses} userCertifications={userCertifications} />




      </PageWrapper>

</>
  
  );
};

export default CounselorCertification;


