import React from 'react';
import Paper from '@mui/material/Paper';

const HeyzineViewer = ({ flipbookUrl }) => {
    return (
      <Paper elevation={3} style={{ width: '100%', height: '100%' }}>
        
<iframe
      allowFullScreen="allowFullScreen"
      scrolling="no"
      className="fp-iframe"
      src={flipbookUrl}
      style={{ border: '1px solid lightgray', width: '100%', height: '600px' }}
      title="My iframe"
    ></iframe>
      </Paper>
    );
  };
  
  export default HeyzineViewer;