import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CountyChart from "./charts/CountyChart";
import CenterChart from "./charts/centerChart";

import CircularProgress from '@mui/material/CircularProgress';
import { Link, useLocation} from "react-router-dom";
import categories from "./charts/configs/naicsSegments";
import sectorMap from "./charts/sectorMap";
import { fetchCountyData } from "./utils/county";

 const cacheDuration = 60 * 60 * 1000; // 1 hour


 export const CountyData = ({ county }) => {
    const cacheKey = `countyDataCache_${county.fips}`;
    const { user } = useAuth0();
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [responseData, setResponseData] = useState({});
    const [newFormattedData, setNewFormattedData] = useState(null);
  
    useEffect(() => {
      if (isLoaded) return;
      // localStorage.clear();
  
      const cachedData = localStorage.getItem(cacheKey);
  
      if (cachedData) {
        const { responseData: storedResponseData, formattedData: storedFormattedData, timestamp } = JSON.parse(cachedData);
  
        if (Date.now() - timestamp < cacheDuration) {
          setNewFormattedData(storedFormattedData);
          setResponseData(storedResponseData);
          setProgress(100);
          setIsLoaded(true);
          return;
        }
      }
  
      async function fetchDataAsync() {
        const { responseData, formattedData } = await fetchCountyData(county.fips);
        setResponseData(responseData);
        setNewFormattedData(formattedData);
        localStorage.setItem(
          cacheKey,
          JSON.stringify({ responseData, formattedData, timestamp: Date.now() })
        );
        setProgress(100);
        setIsLoaded(true);
      }
  
      fetchDataAsync();
    }, [county.fips, isLoaded]);
    
    if (!user) {
      return null;
    } else if (!responseData) {
      return null;
    }
  
    const dataArray = Array.from(responseData);
    const categoryCounts = categories.reduce((acc, category) => {
      acc[category.title] = { count: 0, codes: category.codes };
      return acc;
    }, {});
    dataArray.forEach(entry => {
      const code = entry['NAICS (2 Digit)'];
      const category = Object.values(categoryCounts).find(cat => cat.codes.includes(code));
      if (category) {
        category.count += 1;
      }
    });
  
    const totalCount = Object.values(responseData).reduce((acc, cur) => acc + cur.count, 0);
    const formattedTotalCount = totalCount.toLocaleString();
  
    if (categoryCounts != null) {
      return (
        <Link to="/market-segmentation/detail" state={{ county: county, data: newFormattedData }}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography sx={{ fontSize: 14, textAlign: 'center', textTransform: 'capitalize' }} gutterBottom>
                {county.county} County
              </Typography>
              <Typography sx={{ textAlign: 'center', fontSize: 10 }} variant="h5" color="text.secondary" component="div">
                Total # of Startup Businesses
              </Typography>
  
              <Typography sx={{ textAlign: 'center', fontSize: 20, mt: 1.5, mb: 1.5, fontWeight: 'bold' }} variant="h5" color="text.secondary" component="div">
                {progress == 100
                  ? formattedTotalCount
                  : <CircularProgress />
                }
              </Typography>
              {newFormattedData
                ? <CenterChart county={county} data={newFormattedData} title="County" baseUrl="google" description={"# of Startups"} />
                : null}
  
            </CardContent>
            <CardActions>
              <Button size="small">See Details</Button>
            </CardActions>
          </Card>
        </Link>
      )
    } else {
      return null
    }
  };