import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button'; // Import MUI Button component

export default function LwReports({ data = [] }) {
    const navigate = useNavigate();

    // Updated columns definition to include Registered Date
    const columns = [
        {
            field: 'registered',
            headerName: 'Registered Date',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        { field: 'user', headerName: 'User', headerAlign: 'center', align: 'center', flex: 1 },
        { field: 'email', headerName: 'Email', headerAlign: 'center', align: 'center', flex: 1 },
        { field: 'center', headerName: 'Center', headerAlign: 'center', align: 'center', flex: 1 },
        { field: 'customerid', headerName: 'Customer ID', headerAlign: 'center', align: 'center', flex: 1 },
       
        {
            field: 'action',
            headerName: '',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent row click event
                        navigate(`/ea-reports/user-details/${params.id}`);
                    }}
                >
                    User Details
                </Button>
            ),
            flex: 1,
        },
    ];

    const processedData = Array.isArray(data) ? data.map(item => ({
        id: item.id,
        email: item.email,
        user: `${item.fields.cf_firstname} ${item.fields.cf_lastname}`,
        center: item.fields.cf_center,
        customerid: item.fields.cf_customerid,
        registered: item.created ? new Date(item.created * 1000).toLocaleDateString("en-US") : 'N/A', // Assuming 'registered' is a Unix timestamp
    })) : [];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={processedData}
                columns={columns}
                getRowId={(row) => row.id}
                onRowClick={(params) => navigate(`/ea-reports/user-details/${params.id}`)}
            />
        </div>
    );
}
