import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box, Divider, CardMedia } from '@mui/material';

import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

function CourseProgress({ userId }) {
  const [courses, setCourses] = useState([]);
  const [courseDetails, setCourseDetails] = useState({});

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const response = await axios.get(`/.netlify/functions/fetchProgress/${userId}`);
        const coursesData = Array.isArray(response.data.data) ? response.data.data : [];
        setCourses(coursesData);
        fetchCourseDetails(coursesData);
      } catch (error) {
        console.error('Error fetching course progress:', error);
      }
    };

    const fetchCourseDetails = async (coursesData) => {
      const details = {};
      // Perform a fetch for each course to get its details
      await Promise.all(coursesData.map(async (course) => {
        try {
          const response = await axios.get(`/.netlify/functions/fetchCourse/${course.course_id}`);
          details[course.course_id] = response.data;
          console.log(response.data, 'courseDetails');
        } catch (error) {
          console.error(`Error fetching details for course ${course.course_id}:`, error);
        }
      }));
      setCourseDetails(details);
    };

    fetchProgress();
  }, [userId]);

  // Convert total time from seconds to hours and round to 2 decimal places
  const totalTimeInSeconds = courses.reduce((total, course) => total + course.time_on_course, 0);

  // Decide whether to display in hours or minutes
  let displayTime;
  if (totalTimeInSeconds > 0 && totalTimeInSeconds < 3600) {
    displayTime = `${(totalTimeInSeconds / 60).toFixed(2)} minutes`;
  } else {
    displayTime = `${(totalTimeInSeconds / 3600).toFixed(2)} hours`;
  }

  return (
    <div>
      <Box sx={{ background: '#002d62', p: 3, mb: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ fontSize: 20, mt: 1, color: '#fff' }}>
          <AccessTimeFilledIcon sx={{ display: 'inline', mr: 2 }} />
          Total Time in eCounseling Modules: {displayTime}
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }}>eCounseling Modules</Divider>
      <Grid container spacing={2}>
        {courses.map((course) => (
          <Grid item xs={12} sm={6} md={4} key={course.course_id}>
            <Card variant="outlined">
              <CardContent>
              {courseDetails[course.course_id] && courseDetails[course.course_id].courseImage && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={courseDetails[course.course_id].courseImage}
                    alt={`Photo of ${course.course_id}`}
                  />
                )}
               
                  {courseDetails[course.course_id] && courseDetails[course.course_id].title && (
                     <Typography variant="h5" component="div">
 {courseDetails[course.course_id].title}
 </Typography>
                  )}
               
            
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Status: {course.status}
                </Typography>
                <Typography variant="body2">
                  Progress: {course.progress_rate}%
                </Typography>
                <Typography variant="body2">
                  Time on Course: {
                    course.time_on_course < 3600
                      ? `${(course.time_on_course / 60).toFixed(0)} minutes`
                      : `${(course.time_on_course / 3600).toFixed(2)} hours`
                  }
                </Typography>
                <Typography variant="body2">
                  Completed Units: {course.completed_units}/{course.total_units}
                </Typography>
             
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default CourseProgress;
