import React from 'react';
import { 
  Typography, Paper, Table, TableBody, TableCell, TableHead, TableRow, Box, Container, Grid, TableContainer 
} from '@mui/material';
import { Bar, Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

// Utility function to format string labels
const formatLabel = (label) => {
  return label.replace(/([A-Z])/g, ' $1').trim();
};

const DataDashboard = () => {

    const options = {
        layout: {
          padding: {
            top: 10, // Adjust this value as needed to add margin outside the legend
            right: 10, // Adjust according to your legend's position
            bottom: 10, // Adjust as needed
            left: 10, // Adjust as needed
          },
        },
   
    }

  const noLegend = {
        plugins: {
          legend: {
            display: false
          }
        }
      }
  

  const data = {
    Invitations: 2304,
    Responses: 157,
    Rate: "6.8%",
    AssistanceSought: { 
      BusinessPlanning: 100,
      MarketingAndResearch: 37,
      Financing: 35,
      HiringEmployees: 8,
      GrowingMyBusiness: 48,
      Other: 19
    },
    SBDCAdvisorInteraction: {
      PromptInContacting: { Yes: 139, No: 17 },
      Knowledgeable: { Yes: 141, No: 15 },
      ProfessionalAndCourteous: { Yes: 148, No: 8 },
      ProvidedNeededInformation: { Yes: 136, No: 20 },
      AssistanceBeneficial: { Yes: 133, No: 23 },
      HelpedMakeGoodDecisions: { Yes: 132, No: 24 }
    },
    Accomplishments: {
        StartedABusiness: 62,
        ObtainedFinancing: 17,
        MoreEffectiveMarketing: 34,
        RetainEmployees: 0,
        HireNewEmployees: 1,
        Other: 76
      },
      AdvisorAssistanceQuality: {
        StronglyAgree: 96,
        Agree: 37,
        Neutral: 9,
        Disagree: 6,
        StronglyDisagree: 8
      },
      Recommendation: {
        StronglyAgree: 111,
        Agree: 24,
        Neutral: 9,
        Disagree: 5,
        StronglyDisagree: 7
      },
  };


  const totalAccomplishments = Object.values(data.Accomplishments).reduce((acc, value) => acc + value, 0);
  const totalQuality = Object.values(data.AdvisorAssistanceQuality).reduce((acc, value) => acc + value, 0);
  const totalRecommendation = Object.values(data.Recommendation).reduce((acc, value) => acc + value, 0);

  // Bar Chart Data for Invitations vs Responses
  const invitationsResponsesChartData = {
    labels: ['Invitations', 'Responses'],
    datasets: [{
      label: 'Count',
      data: [data.Invitations, data.Responses],
      backgroundColor: ['#4BC0C0', '#FF6384'],
    }]
  };
 // Method to create table rows
 const createTableRows = (dataObject, total = null) => {
    return Object.entries(dataObject).map(([key, value]) => {
      let percentage = "";
      if (total) {
        percentage = ` (${((value / total) * 100).toFixed(2)}%)`; // Calculate percentage, keep 2 decimal places
      }
      return (
        <TableRow key={key}>
          <TableCell component="th" scope="row">{formatLabel(key)}</TableCell>
          <TableCell align="right">{`${value}${percentage}`}</TableCell>
        </TableRow>
      );
    });
  };

  const totalAssistanceSought = Object.values(data.AssistanceSought).reduce((acc, value) => acc + value, 0);

  // Accomplishments Bar Chart Data
  const accomplishmentsChartData = {
    labels: Object.keys(data.Accomplishments).map(formatLabel),
    datasets: [{
      label: 'Accomplishments',
      data: Object.values(data.Accomplishments),
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#E7E9ED', '#4BC0C0', '#F77825'],
    }]
  };

  // Advisor Assistance Quality Bar Chart Data
  const advisorAssistanceQualityChartData = {
    labels: Object.keys(data.AdvisorAssistanceQuality).map(formatLabel),
    datasets: [{
      label: 'Quality of Assistance',
      data: Object.values(data.AdvisorAssistanceQuality),
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#E7E9ED', '#4BC0C0', '#F77825'],
    }]
  };

  // Recommendation Bar Chart Data
  const recommendationChartData = {
    labels: Object.keys(data.Recommendation).map(formatLabel),
    datasets: [{
      label: 'Recommendation',
      data: Object.values(data.Recommendation),
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#E7E9ED', '#4BC0C0', '#F77825'],
    }]
  };

  return (
    <Container sx={{mt: 2}}>
    
      <Box sx={{ mb: 4, mt: 2, p: 5}}  component={Paper}>
        <Typography variant="h6" gutterBottom>
          Invitations vs Responses
        </Typography>
        <Bar data={invitationsResponsesChartData} options={noLegend}/>
        <TableContainer  sx={{ mt: 2 }}>
          <Table aria-label="Invitations vs Responses">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell align="right">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Invitations</TableCell>
                <TableCell align="right">{data.Invitations}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Responses</TableCell>
                <TableCell align="right">{data.Responses}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* Continue with other components */}
      <Box sx={{ mb: 4, mt: 8, p: 5}}  component={Paper} >
        <Typography variant="h6" gutterBottom sx={{my: 4}}>
          Assistance Sought
        </Typography>
        <Doughnut options={options} data={{
          labels: Object.keys(data.AssistanceSought).map(formatLabel),
          datasets: [{
            label: 'Assistance Sought',
            data: Object.values(data.AssistanceSought),
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#E7E9ED', '#4BC0C0', '#F77825'],
            hoverOffset: 4
          }]
        }} />
        <TableContainer  sx={{ mt: 2 }}>
  <Table aria-label="Assistance Sought">
    <TableHead>
      <TableRow>
        <TableCell>Category</TableCell>
        <TableCell align="right">Count (Percentage)</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {createTableRows(data.AssistanceSought, totalAssistanceSought)}
    </TableBody>
  </Table>
</TableContainer>
      </Box> 
      {/* Render SBDCAdvisorInteraction sections */}
      {Object.entries(data.SBDCAdvisorInteraction).map(([key, values]) => {
  const total = values.Yes + values.No;
  return (
    <Box sx={{ mb: 4, mt: 8, p: 5}} key={key} component={Paper}>
      
              <Typography variant="h6" gutterBottom>
                {formatLabel(key)} Interaction
              </Typography>
  
 <Bar
 options={noLegend}
                data={{
                  labels: ['Yes', 'No'],
                  datasets: [
                    {
                      label: 'Responses',
                      data: [values.Yes, values.No],
                      backgroundColor: ['#4BC0C0', '#FF6384'],
                    },
                  ],
                }}
              />
      <TableContainer>
        <Table aria-label={`${formatLabel(key)} table`}>
          <TableHead>
            <TableRow>
              <TableCell>Response</TableCell>
              <TableCell align="right">Count (Percentage)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {createTableRows(values, total)}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
})}

<Box sx={{ mb: 8, mt: 8, p:5 }} component={Paper}>
        <Typography variant="h6" gutterBottom>
          Accomplishments
        </Typography>
        <Bar data={accomplishmentsChartData} options={noLegend} />
        {/* Accomplishments Table */}
<TableContainer  sx={{ mt: 2 }}>
  <Table aria-label="Accomplishments">
    <TableHead>
      <TableRow>
        <TableCell>Accomplishment</TableCell>
        <TableCell align="right">Count (Percentage)</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {createTableRows(data.Accomplishments, totalAccomplishments)}
    </TableBody>
  </Table>
</TableContainer>
      </Box>
      <Box sx={{ mb: 4, p:5 }} component={Paper}>
        <Typography variant="h6" gutterBottom>
          Advisor Assistance Quality
        </Typography>
        <Bar data={advisorAssistanceQualityChartData} options={noLegend} />
        {/* Advisor Assistance Quality Table */}
        <TableContainer  sx={{ mt: 2 }}>
          <Table aria-label="Advisor Assistance Quality">
            <TableHead>
              <TableRow>
                <TableCell>Assessment</TableCell>
                <TableCell align="right">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {createTableRows(data.AdvisorAssistanceQuality, totalQuality)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ mb: 4, p:5  }} component={Paper}>
        <Typography variant="h6" gutterBottom>
          Would Recommend the Utah SBDC
        </Typography>
        <Bar data={recommendationChartData} options={noLegend} />
        <TableContainer  sx={{ mt: 2 }}>
          <Table aria-label="Advisor Assistance Quality">
            <TableHead>
              <TableRow>
                <TableCell>Assessment</TableCell>
                <TableCell align="right">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {createTableRows(data.Recommendation, totalRecommendation)}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
    </Container>
  );
};

export default DataDashboard;
