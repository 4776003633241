import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';

const formatNumber = (value) => {
  return value.toLocaleString();
};

const TrainingsEventsTable = ({ trainings }) => {
  return (
    <Box sx={{ width: '100%', padding: 0 }}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ backgroundColor: '#002d62', color: '#fff', padding: 3, borderRadius: '4px 4px 0 0' }}
      >
        Trainings & Events
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Event Count</strong></TableCell>
              <TableCell align="right"><strong>Distinct Customers</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{formatNumber(trainings['Event Count'])}</TableCell>
              <TableCell align="right">{formatNumber(trainings['Distinct Customers'])}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TrainingsEventsTable;