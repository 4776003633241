// DateDisplay.js
import React from 'react';
import { Typography } from '@mui/material';
import { format } from 'date-fns';

const DateDisplay = ({ date }) => {
  if (!date) {
    return null;
  }
  
  const formattedDate = format(new Date(date), 'PPpp');
  
  return <Typography variant="body1">{formattedDate}</Typography>;
};

export default DateDisplay;