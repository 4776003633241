import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, CardContent, Grid, Divider, CardHeader, Button } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link
import axios from 'axios';

const StrategicPlan = () => {
  const [strategicElements, setStrategicElements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStrategicElements = async () => {
      try {
        const response = await axios.get('/.netlify/functions/fetchTeams');
        setStrategicElements(response.data);
      } catch (error) {
        console.error('Error fetching strategic elements:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStrategicElements();
  }, []);

  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
      <Card style={{ marginBottom: '2rem' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            MISSION
          </Typography>
          <Typography variant="body1" sx={{fontSize: 14}}>
            Our mission is to equip every Utah entrepreneur with the essential knowledge, skills, and opportunities to bring their business dreams to fruition and make significant contributions to the dynamic economic landscape of our state.
          </Typography>
        </CardContent>
      </Card>

      <Card style={{ marginBottom: '2rem' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            VISION
          </Typography>
          <Typography variant="body1" sx={{fontSize: 14}}>
            The Utah Small Business Development Center envisions a thriving Utah economy powered by innovative, sustainable, and successful small businesses. Our vision is to be the leading resource and advocate for small business owners and aspiring entrepreneurs, fostering economic growth and prosperity through expert guidance, strategic partnerships, and comprehensive tools and resources.
          </Typography>
        </CardContent>
      </Card>

      <Card style={{ marginBottom: '2rem' }}>
        <CardContent sx={{fontSize: 18}}>
          <Typography variant="h5" gutterBottom>
            ORGANIZATIONAL VALUES
          </Typography>
          <ul>
            <li>
              <Typography sx={{fontSize: 14}} variant="body1">Integrity: The USBDC is committed to high ethical standards, always doing the right things for the right reasons.</Typography>
            </li>
            <li>
              <Typography sx={{fontSize: 14}} variant="body1">Commitment: Responsively serving clients is our core mission and first priority.</Typography>
            </li>
            <li>
              <Typography sx={{fontSize: 14}}variant="body1">Professionalism: We demonstrate unwavering respect and courtesy for clients, stakeholders, and ourselves.</Typography>
            </li>
            <li>
              <Typography  sx={{fontSize: 14}} variant="body1">Confidentiality: We vow to maintain the confidentiality of client information, ideas, and results.</Typography>
            </li>
            <li>
              <Typography  sx={{fontSize: 14}} variant="body1">Accountability: We hold ourselves to the highest order of responsibility and stewardship.</Typography>
            </li>
          </ul>
        </CardContent>
      </Card>

      <Typography variant="h5" sx={{ mb: 2 }} align="left" gutterBottom>
        STRATEGIC ELEMENTS
      </Typography>
      <Divider sx={{my: 2}}/>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Grid container spacing={4}>
          {strategicElements.map((element, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card>
                <CardHeader title={element.title} sx={{ backgroundColor: element.color || "#003366", color: "#fff" }} />
                <CardContent>
                  <Typography variant="body2" sx={{fontSize: 12}}>
                    {element.mission}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    component={Link}
                    sx={{mt: 2}}
                    to={`/strategic-plan/detail/${element.id}`} // Link to the detail page
                  >
                    View More Details
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Divider style={{ margin: '2rem 0' }} />

      <Typography variant="body1" align="center">
        Developed 10/11/2024
      </Typography>
    </Container>
  );
};

export default StrategicPlan;