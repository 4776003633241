import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { PageWrapper } from '../../pages/PageWrapper';
import { useAuth0 } from "@auth0/auth0-react";
import { Select, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DateDisplay from '../DateDisplay';
import ImpactList from '../charts/ImpactList';
import PieChartFunding from '../PieChartFunding';
import { Button, Card, CardContent, CardMedia, Grid, Typography, Avatar } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const SixYearImpact = () => {

  const impact = [
    { title: 'Clients Served',  value: "21,197", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/clientsServed.jpg' },
    { title: 'Contact Hours', value: "48,751", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/hours.png' },
    { title: 'Prep / Research Hours', value: "35,390", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/hours.png' },
    { title: 'Avg Hours Per Client', value: "4.0", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/hours.png' },
    { title: 'Business Starts', value: "1,869", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/businessStarts.jpg' },
    // { title: 'Loans Obtained',   value: "$100.0 Million", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/LoansObtained.jpg' },
  { title: 'Clients Sales Increase', value: "$407.8 Million", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/ClientSales.jpg' },
    { title: 'Jobs Created', value: "7,536", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/JobsCreated.jpg' },
    { title: 'Jobs Retained', value: "1,565", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/JobsCreated.jpg' },
    { title: 'Loans Obtained', value: "$541.8 Million", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/LoansObtained.jpg' },
 ];
  

// Example data structure for user certifications
const userCertifications = [
  { title: 'Forming Your Business', certifiedDate: '2023-01-15' },
  { title: 'Marketing Your Business', certifiedDate: '2023-05-20' },
  // ... other certifications
];
  

  const {
    isAuthenticated,
    logout,
    user
  } = useAuth0();
  useEffect(() => {
    
     

  }, []);

  



  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

  return (
<>
      <PageWrapper title="Six Year Impact" secondaryTitle={" "} subtitle={"2018 - 2023"}>
        <ImpactList courses={impact} userCertifications={userCertifications} />
      </PageWrapper>
      <PageWrapper title="Funding Overview" secondaryTitle={" "}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Card sx={{p: 5}}>
                <Typography sx={{textAlign: 'center', mb: 2}}>
                   Program Funding 2023
                </Typography>
                <PieChartFunding />
              
            </Card>
          </Grid>

          <Grid item xs={5}>
            <Card sx={{p: 5}}>
                <Typography sx={{textAlign: 'center',  mb: 2}}>
                   Program Funding 2023
                </Typography>
               
                <Box display="flex" alignItems="center" sx={{ m: 2 }}>
                  <Avatar style={{ backgroundColor: '#ff0000', marginRight: '8px' }} >
                    <AccountBalanceIcon/>
                    </Avatar>  {/* Color for SBA */}
                  <Typography sx={{fontSize: 20, fontWeight: 'bold', ml: 1}}>
                    Congress - $1,275,009.00
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" sx={{ m: 2 }}>
                  <Avatar style={{ backgroundColor: '#16A9BC', marginRight: '8px' }} >
                  <AccountBalanceIcon/>
                    </Avatar>  {/* Color for GO Utah */}
                  <Typography sx={{fontSize: 20, fontWeight: 'bold', ml: 1}}>
                    Utah Legislature - $764,332.00
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" sx={{ m: 2 }}>
                  <Avatar style={{ backgroundColor: '#00356b', marginRight: '8px' }}>
                  <AccountBalanceIcon/>
                    </Avatar>  {/* Color for Host Match */}
                  <Typography sx={{fontSize: 20, fontWeight: 'bold', ml: 1}}>
                    Host Match - $542,868.00
                  </Typography>
                </Box>
            </Card>
          </Grid>
          
        </Grid>
      </PageWrapper>
    </>
  
  );
};

export default SixYearImpact;


