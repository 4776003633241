import React from 'react';
import { Grid, Card, CardContent, Typography, Link, Box } from '@mui/material';

const exhibitData = [

      {
        category: 'Sample subrecipient agreement (Standard 1.1)',
        files: [  { name: 'Subaward Template', link: 'https://drive.google.com/file/d/1_4sWcVlCvoQyZFPKtwgwd5vn78qjMdwT/view?usp=sharing' }],
      },
      {
        category: 'Center reviews 2023, 2024 – template or agenda for center reviews',
        files: [  { name: 'Site Evaluation Form', link: 'https://docs.google.com/spreadsheets/d/18We956OpNhOj2zKJYpgs6orblQXbKrPI/edit?usp=sharing&ouid=106465230678327610275&rtpof=true&sd=true' },
    ],  },
   
    {
        category: '3-year trend data employee engagement (Standard 5.3)',
        files: [  { name: 'Employee Satisfaction Survey', link: 'https://docs.google.com/spreadsheets/d/1FIg8UHDVV0eDa0TeVpxCVYEdFI-9ULiV/edit?usp=sharing&ouid=106465230678327610275&rtpof=true&sd=true' },
    ],  
      },

      
      {
        category: 'New hires / Directors Onboarding course (Standard 5.2)',
        files: [ { name: 'Onboarding Course', link: ' https://online.utahsbdc.org/path-player?courseid=onboarding&unit=663afaed450fcb5dd90a6aecUnit'}],
        msg: 'To access this, make sure you are logged in to the EA as a staff member. You can access it via these credentials: accreditation@utahsbdc.org, pw: Utah2024'
       
      },
    {

    category: 'Agendas and related materials',
    files: [
      { name: '5-2023 Directors Meeting Agenda.pdf', link: 'https://drive.google.com/file/d/1a3Jb2-id4vWkqUfrPNn-_uMzkSml4EzF/view?usp=sharing' },
      { name: '5-2024 Dirctors Meeting Agenda.pdf', link: 'https://drive.google.com/file/d/1TdmRHJdweOQ78AK0gQ-v9_Sm9EK5s8JK/view?usp=sharing' },
    ],
  },
  {
    category: 'Advisory Board information',
    files: [
      { name: 'Agenda Advisory Committee 10-23.docx', link: 'https://docs.google.com/document/d/1TNFvwB-tyrqK6Fmby-AuaNfgQKzIq5Gk/edit?usp=sharing&ouid=106465230678327610275&rtpof=true&sd=true' },
      { name: 'SBDC Advisory committee members 2024.xlsx', link: 'https://docs.google.com/spreadsheets/d/1Wy6e4J2NYtI2nkNgSA74vyeXr-uaPjdU/edit?usp=sharing&ouid=106465230678327610275&rtpof=true&sd=true' },
    ],
  },
  {
    category: 'Performance to goal by individual center',
    files: [{ name: 'Center Goal to Actual Trend.xlsx', link: 'https://docs.google.com/spreadsheets/d/1yBbI2fN0aYt_hCuJF8NtyoW-rXqxcOaZ/edit?usp=sharing&ouid=106465230678327610275&rtpof=true&sd=true' }],
  },
  {
    category: '3-year trend data client satisfaction',
    files: [{ name: '3 Year Client Satisfaction Trend.xlsx', link: 'https://docs.google.com/spreadsheets/d/1u28DmLgd9Sjih8e_f0aNT3CvkOooZ-q7/edit?usp=sharing&ouid=106465230678327610275&rtpof=true&sd=true' }],
  },
  {
    category: '3-year trend data of performance measures',
    files: [{ name: 'Impact Trend 2019 to 2023.xlsx', link: 'https://docs.google.com/spreadsheets/d/1OeO1NwLDLjNQUMyaMLRh4CYOMDbTF6Av/edit?usp=sharing&ouid=106465230678327610275&rtpof=true&sd=true' }],
  },
  {
    category: 'Goal calculators for each center - 2024',
    files: [{ name: 'Subaward Goal Calculator.xlsx', link: 'https://docs.google.com/spreadsheets/d/1NXPH6jHJn_uZZ46OIm9hu_OTBZj76sBe/edit?usp=sharing&ouid=106465230678327610275&rtpof=true&sd=true' }],
  },
];
const ExhibitList = () => {
    return (
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {exhibitData.map((exhibit, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    {exhibit.category}
                  </Typography>
                  {exhibit.files.map((file, fileIndex) => (
                    <div key={fileIndex}>
                      <Typography variant="body2" component="div">
                        <Link href={file.link} target="_blank" rel="noopener">
                          {file.name}
                        </Link>
                      </Typography>
                      {exhibit.msg && (
                        <Typography sx={{ mt: 2 }} variant="body2" color="textSecondary">
                          {exhibit.msg}
                        </Typography>
                      )}
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

export default ExhibitList;