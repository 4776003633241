import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";

export const PageLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box 

    >
      <Box 
        className="page-layout__content"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          flexGrow: 1,
          padding: theme.spacing(2),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};