import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';

import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, CircularProgress, Box, Card, CardContent, Alert, Stack } from '@mui/material';

import LwReports from "../components/LwReports"; // Adjust import as needed

export const ReportsPageLW = () => {
    const { user } = useAuth0();
    const [responseData, setResponseData] = useState([]);
    const [totalCourseTime, setTotalCourseTime] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`/.netlify/functions/fetchUsers/${user.center}`, {
                    method: 'GET',
                    headers: { 'Accept': 'application/json' },
                });
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
                const data = await response.json();
                setResponseData(data.data);
                // Now fetch the progress for each user
                fetchUserProgress(data.data);
            } catch (error) {
                console.error("Failed to fetch user data:", error);
            }
        };

        const fetchUserProgress = async (users) => {
            try {
                const progressPromises = users.map(user =>
                    axios.get(`/.netlify/functions/fetchProgress/${user.id}`).then(res => res.data.data)
                );
                const progressResults = await Promise.all(progressPromises);
                const totalSeconds = progressResults.flat().reduce((acc, { time_on_course }) => acc + time_on_course, 0);
                setTotalCourseTime((totalSeconds / 3600).toFixed(2)); // Convert seconds to hours and fix to 2 decimal points
            } catch (error) {
                console.error("Failed to fetch user progress:", error);
            } finally {
                setLoading(false);
            }
        };

        if (user?.center) {
            fetchUserData();
        }
    }, [user]);

    if (loading) {
        return (
            <Box 
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100vh' // This ensures the Box takes the full viewport height
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <PageLayout>
         
             <Typography sx={{ textTransform: 'capitalize', mb: 2 }}>
                <h1 id="page-title">{user.center} Center Usage Report</h1>
            </Typography>
      
                <Typography variant="h6" sx={{ mb: 4, textTransform: 'capitalize' }}>
                    Total Course Time For {user.center} Center Clients: {totalCourseTime} hours
                </Typography>

                <Stack sx={{ width: '100%', mb: 2 }} spacing={2}>
                    <Alert variant="outlined" severity="info" sx={{display: "flex", flexWrap: "wrap", justifyContent: "center", alignContent: "center", p: 0}}>
                        This list shows any of the clients from your center that have activated their account and logged in at least one time to the Entrepreneur Academy. This will only show accounts for our new system (1/1/2024 forward)
                    </Alert>
                </Stack>
         

                <Grid container spacing={2}>
                    <Grid item lg={12}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <LwReports data={responseData} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
         
        </PageLayout>
    );
};
