import React from "react";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

export const MarketSegmentationCenterCodeDetails = ({ data, fullData }) => {
    const navigate = useNavigate();
    const handleRowClick = (param, event) => {
        // Handle the row click event here
        console.log('Row clicked:', param.row);
        navigate(`/market-segmentation/center-detail-code/${param.row.id}`);

      };




      
    

    

 console.log(data, 'tableDAta')
    const sectorLists = Object.values(data).map(({ sectorId, vCodes, count }) => ({
        'id': 44,
        'name': 'test',
        vCodes,
    }));


    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
      }


      function PhoneNumberFormatter({ value }) {
        return <>{formatPhoneNumber(value)}</>;
      }


    const sectorList = fullData.map((entry) => ({
        id: entry._id, // Use the unique _id field as the id property
        businessName: entry["Business Name"],
        firstName: entry["First"],
        lastName: entry["Last Name"],
        naicsCode: entry["NAICS (2 Digit)"],
        naicsCode6: entry["NAICS (6 Digit)"],
        address: entry["Address"],
        city: entry["City"],
        state: entry["State"],
        zipCode: entry["Zip Code (5 digit)"],
        email: entry["Email Address"],
        phone: entry["Primary Phone"],
        center: entry["Center"],
      }));
      

      console.log(sectorList, 'sectorList');




      const columns = [
       
        { field: 'businessName', headerName: 'Business Name', width: 200 },
        { field: 'firstName', headerName: 'First Name', width: 150 },
        { field: 'lastName', headerName: 'Last Name', width: 150 },
        { field: 'address', headerName: 'Address', width: 200 },
        { field: 'city', headerName: 'City', width: 150 },
        { field: 'state', headerName: 'State', width: 100 },
        { field: 'zipCode', headerName: 'Zip Code', width: 120 },
        { field: 'email', headerName: 'Email', width: 200 },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 150,
            renderCell: (params) => <PhoneNumberFormatter value={params.value} />,
          },
      ];
 


    return (
        <div style={{ height: 465, width: '100%' }}>
            
        
            <DataGrid
                rows={sectorList}
                columns={columns}
                pageSize={25}
                rowsPerPageOptions={[24]}
                components={{
                    Toolbar: GridToolbar
                }} 
            />

        </div>
    );
};
