import React from 'react';
import { List, ListItem, ListItemText, Typography, Container, Box } from '@mui/material';
import PDFViewer from './PDFViewer';

function SurveyQuestions() {
  const questions = [
    "What type of assistance were you seeking?",
    "Was the SBDC Advisor prompt in contacting?",
    "Was the SBDC advisor knowledgeable?",
    "Was the SBDC advisor professional & courteous?",
    "Did the SBDC advisor provide you with the information you were seeking?",
    "Was the assistance you received beneficial to you or your business?",
    "Did the assistance you received help you make good decisions for your business?",
    "What were you able to accomplish as a result of the assistance you received?",
    "The SBDC advisor provided excellent assistance to me.",
    "I would recommend the SBDC to another person."
  ];

  return (
<Box sx={{m:1}}>
      <Typography variant="h5" component="h2" gutterBottom>
        Internal Overview of Client Satisfaction Survey Questions
      </Typography>
      <Typography paragraph fontSize={12}>
        This page provides an overview of the questions we currently include in our client satisfaction survey. It's designed for internal review to ensure our survey effectively captures the insights we need for continuous improvement and to better understand our client's needs and experiences.
      </Typography>
      <List>
        {questions.map((question, index) => (
          <ListItem key={index} sx={{}}>
            <ListItemText  primary={question} />
          </ListItem>
        ))}
      </List>
</Box>
  );
}

export default SurveyQuestions;
