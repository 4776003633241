import React from 'react';
import { Card, CardContent, CardMedia, Typography, Icon, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import check-circle icon


const CardValue = ({ title, value, image, isCertified, certifiedDate }) => {


  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column'  }} sx={{textAlign: "center"}}>
      <CardMedia
        component="img"
        sx={{ }} // 16:9 aspect ratio
        image={image}
        alt={title}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography sx={{fontSize: 30, fontWeight: 'bold'}}>
            {value}
        </Typography>
        <Typography gutterBottom component="div" sx={{fontSize: 20}}>
          {title}
        </Typography>
        {isCertified && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Icon sx={{ color: 'green' }}>
              <CheckCircleIcon />
            </Icon>
            <Typography variant="body2">
              Certified on: {certifiedDate}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CardValue;
