import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

export const UserDetails = ({ userId }) => {
    const { user } = useAuth0();
    const [activities, setActivities] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const url = `/.netlify/functions/fetchActivity/${userId}`;
                const response = await axios.get(url);
                console.log(response, 'activity');
                setActivities(response.data.data); // Assuming you're correctly accessing the path in the response
            } catch (error) {
                console.error("Failed to fetch user data:", error);
            }
        };

        fetchUserData();
    }, []);

    if (!activities.length) {
        return <div>Loading...</div>; // Show a loading state while fetching data
    }

    return (
        <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
            <Timeline position="alternate">
                {activities.map((activity, index) => (
                    <TimelineItem key={index}>
                        <TimelineSeparator>
                            <TimelineDot />
                            {index < activities.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span">
                                {activity.description}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ display: 'block', alignItems: 'center', gap: 0.5, mt: 0.5 }}>
                                <AccessTimeFilledIcon fontSize="small" style={{marginRight: 10}} /> 
                                {new Date(activity.created * 1000).toLocaleString()}
                            </Typography>
                            {activity.additional_info && activity.additional_info.course && (
                                <Typography display="block" sx={{ mt: 0}} color="text.secondary">
                                    Course: {activity.additional_info.course}
                                </Typography>
                            )}
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </Box>
    );
};
