import React from 'react';
import { Grid, Box, Card, CardContent, CardMedia, Typography } from '@mui/material';

const formatCurrency = (value) => {
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(1)} Million`;
  }
  return `$${value.toLocaleString()}`;
};

const formatNumber = (value) => {
  return value.toLocaleString();
};

const PartnerImpact = ({ totals }) => {
  const data = [
    { title: 'Business Starts', value: formatNumber(totals.BusinessStarts), image: 'https://utahsbdc.org/wp-content/uploads/2023/10/businessStarts.jpg' },
    { title: 'Capital', value: formatCurrency(totals.Capital), image: 'https://utahsbdc.org/wp-content/uploads/2023/10/LoansObtained.jpg' },
    { title: 'Clients', value: formatNumber(totals.Clients), image: 'https://utahsbdc.org/wp-content/uploads/2023/10/clientsServed.jpg' },
    { title: 'Sales', value: formatCurrency(totals.Sales), image: 'https://utahsbdc.org/wp-content/uploads/2023/10/ClientSales.jpg' },
    { title: 'Jobs', value: formatNumber(totals.Jobs), image: 'https://utahsbdc.org/wp-content/uploads/2023/10/JobsCreated.jpg' },
    { title: 'Hours', value: formatNumber(totals.Hours), image: 'https://utahsbdc.org/wp-content/uploads/2024/06/Time.jpg' },
  ];

  return (
    <Box sx={{ p: 0, m: 0, mb: 2}}>
      <Grid container spacing={2} justifyContent="flex-start">
        {data.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
            <Card sx={{ display: 'flex', alignItems: 'center' }}>
              <CardMedia
                component="img"
                sx={{ width: 80, height: 80, objectFit: 'cover' }}
                image={item.image}
                alt={item.title}
              />
              <CardContent>
                <Typography variant="h6" sx={{}}>{item.title}</Typography>
                <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: 18}}>{item.value}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PartnerImpact;