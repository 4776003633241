import React from 'react';
import { Grid, Typography, Card, CardContent, Link, Box, Button, ButtonGroup } from '@mui/material';


function NotionPage({ data }) {
    if (!data || data.length === 0) return <p>No data provided</p>;

    const getRichTextContent = (properties, property) => {
        return properties[property]?.rich_text?.[0]?.plain_text || 'Not available';
    };

    const getEmbedCode = (properties) => {
        const embedHtml = properties?.Embed?.rich_text?.map(text => text.plain_text).join('') || '';
        return embedHtml;
    };

    return (
    
            <Grid container spacing={2}> {/* Grid container */}
            {data.map((item, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}> {/* Grid item */}
                <Card key={index} sx={{ maxWidth: 600, m: 2 }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            {item.properties?.Client?.title?.[0]?.plain_text || 'No Title'}
                        </Typography>
                        
                        <Typography variant="body1">
                            Year: {getRichTextContent(item.properties, 'Year')}
                        </Typography>
                        <Typography variant="body1">
                            Center: {item.properties?.Center?.select?.name || 'Not available'}
                        </Typography>
                        <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ mt: 2 }}>
                                <Button 
                                    href={item.properties?.WebsiteLink?.rich_text?.[0]?.href || '#'} 
                                    target="_blank"
                                    disabled={!item.properties?.WebsiteLink?.rich_text?.[0]?.href}>
                                    Website Article
                                </Button>
                                <Button 
                                    href={item.properties?.PhotoLink?.rich_text?.[0]?.href || '#'} 
                                    target="_blank"
                                    disabled={!item.properties?.PhotoLink?.rich_text?.[0]?.href}>
                                    Photos
                                </Button>
                                <Button 
                                    href={item.properties?.VideoLink?.rich_text?.[0]?.href || '#'} 
                                    target="_blank"
                                    disabled={!item.properties?.VideoLink?.rich_text?.[0]?.href}>
                                    Video
                                </Button>
                            </ButtonGroup>
                        <Box sx={{ mt: 2, p: 2, backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
                            <Typography variant="body2">
                          
                            </Typography>
                            <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: getEmbedCode(item.properties) }} />
                        </Box>

                         {/* Embed Code Display */}
                         <Box sx={{ mt: 2, p: 2, backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
                            <Typography variant="body2">
                                Embed Code:
                            </Typography>
                        
                            {/* Code Box for Plain Text Display */}
                            <Typography variant="body2" component="pre" style={{ whiteSpace: 'pre-wrap' }}>
                                <code>{getEmbedCode(item.properties)}</code>
                            </Typography>
                        </Box>
                        
                    </CardContent>
                </Card>
                </Grid>
            ))}
        </Grid>
    );
}

export default NotionPage;
