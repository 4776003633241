import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PageWrapper } from '../../pages/PageWrapper';
import { useAuth0 } from "@auth0/auth0-react";
import Typography from '@mui/material/Typography';
import CoursesList from '../charts/CoursesList';
import CourseAnalytics from '../CourseAnalytics';
import CourseStats from '../charts/CourseStats';

const Modules = () => {
  const [courses, setCourses] = useState([]);

  const { isAuthenticated, user } = useAuth0();

  // Example data structure for user certifications
  const userCertifications = [
    { title: 'Forming Your Business', certifiedDate: '2023-10-01' },
    { title: 'Marketing Your Business', certifiedDate: '2023-10-03' },
    // ... other certifications
  ];

  useEffect(() => {
    if (isAuthenticated && user) {
      let userEmail = user.email;

      // Using Promise.all to fetch data from both endpoints in parallel
      Promise.all([
        axios.get('/.netlify/functions/fetchModules', { params: { email: userEmail }}),
        axios.get('/.netlify/functions/fetchLegacy', { params: { email: userEmail }})
      ])
      .then(([modulesResponse, legacyResponse]) => {
        // Assuming both responses have a data property that is an array
        const combinedCourses = [...modulesResponse.data.data, ...legacyResponse.data.data];
        setCourses(combinedCourses);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  }, [isAuthenticated, user]); // Depend on isAuthenticated and user to re-fetch when they change

  return (
    <>
      <PageWrapper title="eCounseling Modules" secondaryTitle={" "}>
          <CoursesList courses={courses} userCertifications={userCertifications} />
   
      </PageWrapper>

    </>
  );
};

export default Modules;
