import React from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Button, ListItemSecondaryAction, Box } from '@mui/material';

const assets = [
  { name: 'Annual Report / SBDC Day Stylesheet', type: 'pdf', action: 'Download', url: 'https://americassbdc.org/wp-content/uploads/2024/01/SBDC_StyleGuide_2024_draft2.pdf', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/Toolkit-grid-1.jpg?w=250&ssl=1' },
  { name: 'Annual Report/SBDC Day Powerpoint Slides for Graphics & Presentations', type: 'pptx', action: 'Download',  url: 'https://americassbdc.org/wp-content/uploads/2024/01/SBDC_Day_2024_template_draft1.pptx', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/pptx-file.jpg?w=250&ssl=1'},
  { name: 'SBDC Day logo', type: 'jpg', action: 'Download',  url: 'https://americassbdc.org/wp-content/uploads/2023/01/SBDCDay-Logo-JPG-2.jpg', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/SBDCDay-Logo-JPG-sm.jpg?w=250&ssl=1' },
  { name: 'SBDC Day Letterhead', type: 'docx', action: 'Download',  url: 'https://americassbdc.org/wp-content/uploads/2024/01/SBDC_Day_2024_letterhead_draft1.docx', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/SBDC_Day_2024_letterhead_draft1-sm.jpg?w=250&ssl=1' },
  { name: 'Theme Graphic', type: 'jpg', action: 'Download',  url: 'https://americassbdc.org/wp-content/uploads/2024/01/SBDC_Day_2024_THEME_draft2C.jpg', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/SBDC_Day_2024_THEME_draft2C-sm.jpg?w=250&ssl=1' },
  { name: 'Annual Report Graphic', type: 'jpg', action: 'Download' ,  url: 'https://americassbdc.org/wp-content/uploads/2024/01/SBDC_Day_2024_THEME_draft2A.jpg', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/SBDC_Day_2024_THEME_draft2A-sm.jpg?w=250&ssl=1' },
  { name: 'SBDC Day Graphic', type: 'jpg', action: 'Download' ,  url: 'https://americassbdc.org/wp-content/uploads/2024/01/SBDC_Day_2024_THEME_draft2B.jpg', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/SBDC_Day_2024_THEME_draft2B-sm.jpg?w=250&ssl=1' },
  { name: 'Gradient Overlay Graphic', type: 'png', action: 'Download' ,  url: 'https://americassbdc.org/wp-content/uploads/2024/01/SBDC_Day_2024_THEME_draft2-gradient.png', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/SBDC_Day_2024_THEME_draft2-gradient-sm.jpg?w=250&ssl=1' },
  { name: 'Printable Talking Points Cards 4×4', type: 'pdf', action: 'Download',  url: 'https://americassbdc.org/wp-content/uploads/2024/01/Americas_SBDC_2024_talking-points_draft3B.pdf', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/Americas_SBDC_2024_talking-points-sm.jpg?w=250&ssl=1' },
  { name: 'Bifold Impact Brochure', type: 'pdf', action: 'Download' ,  url: 'https://americassbdc.org/wp-content/uploads/2024/01/Americas_SBDC_spring_meeting_2024_bifold_draft4-WEB.pdf', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/Americas_SBDC_spring_meeting_2024_bifold-sm.jpg?w=250&ssl=1' },
  { name: 'Social Media templates', type: 'Canva', action: 'View' ,  url: 'https://docs.google.com/document/d/1GCAC941Uu4n6LSlW4S7QL_olAN5SKk1VCjOovwb_cXs/edit?usp=sharing', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/CANVA.jpg?w=250&ssl=1' },
  { name: 'Social Media templates', type: 'PowerPoint', action: 'View' ,  url: 'https://1drv.ms/f/s!Ahniu4wipJb8hnF0gidIhKXIhAYj?e=hj5I0o', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/CANVA.jpg?w=250&ssl=1' },
  { name: 'Promo video', type: 'mp4 download', action: 'Download' ,  url: 'https://drive.google.com/file/d/1wftUouH-yGzMNtiF00SVQkID-fTwDOGr/view?usp=sharing', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/YT.jpg?w=250&ssl=1' },
  { name: 'Promo video', type: 'YouTube', action: 'View' ,  url: 'https://www.youtube.com/watch?v=9EVMJ_5TW7w', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/YT.jpg?w=250&ssl=1' },
  { name: 'Editorial Calendar', type: '', action: 'Download' ,  url: 'https://docs.google.com/document/d/1w7lYNb8DkIUXzx4kqEi5aniurGAS-_MeNKYX_ICu-0I/edit?usp=sharing', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/02/2024-calendar-and-pen-concept-2024-sm.jpg?w=250&ssl=1' },
  { name: 'SBDC Day Save the Date', type: '', action: 'Download' ,  url: 'https://americassbdc.org/wp-content/uploads/2024/02/SBDC_social_media_2024_statsquotes_draft2.jpg', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/02/SBDC_social_media_2024_statsquotes_draft-sm.jpg?w=250&ssl=1' },
  { name: 'Social Media Graphics Kit', type: '', action: 'Open & Download' ,  url: 'https://drive.google.com/drive/folders/1Qzw31cXf6AFM-LHJ2s89uRKh6MSmjpK7?usp=share_link', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/SBDC_Day_2024_THEME_draft2C-sm.jpg?w=250&ssl=1' },
  { name: 'Ambassador toolkit', type: 'Guide and Social Media Graphics', action: 'Download' ,  url: 'https://americassbdc.org/wp-content/uploads/2024/03/Ambassador-toolkit.zip', thumbnail: 'https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/03/ambassador-toolkit.jpg?w=250&ssl=1' },
];

function handleButtonClick(url) {
  window.open(url, '_blank');
}
function AssetsList() {
  return (
    <>
    <Box>
    <img width="100%" src=" https://i0.wp.com/americassbdc.org/wp-content/uploads/2024/01/header-2024-Annual-Report-SBDC-Day-Toolkit-sm.jpg?w=2400&ssl=1" />
    </Box>
    <List sx={{ width: '100%' }}>
      {assets.map((asset, index) => (
        <ListItem key={index} divider sx={{ alignItems: 'flex-start' }}>
          <ListItemAvatar>
            <Avatar
              alt={asset.name}
              src={asset.thumbnail}
              variant="rounded"
              sx={{ width: 100, height: 100 }} // Adjust avatar size as needed
            />
          </ListItemAvatar>
          <ListItemText primary={asset.name} secondary={asset.type ? `Type: ${asset.type}` : ''} sx={{ margin: 'auto 0', pl: 2 }} />
          <ListItemSecondaryAction>
            <Button variant="contained" color="primary" onClick={() => handleButtonClick(asset.url)}>
              {asset.action}
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
    </>
  );
}

export default AssetsList;
