import React from 'react';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { Chart, ArcElement, Tooltip } from 'chart.js'
import categories from './configs/naicsSegments';
import getColorByLabel from './configs/colors';
import createChartOptions from './configs/chartOptions';



Chart.register(ArcElement, Tooltip);





const CenterChart = ({title, isCounty, county, baseUrl, data}) => {
    const navigate = useNavigate();

   
    
   


   console.log(data, 'centerChart');


   console.log("THE COUNTY", county);

   const categoriesArray = Object.entries(data).map(([title, { count, vCodes }], index) => ({
    id: vCodes, sectorName: title,  count
  }));

  console.log(categoriesArray, 'categoriesArray!')

  
  // Sort the array by count in descending order
  categoriesArray.sort((a, b) => b.count - a.count);

  console.log(categoriesArray, 'categoriesSortedArray!')

//   // Create an array of labels and counts from the sorted array
  const sortedLabels = categoriesArray.map(category => category.sectorName);
  const sortedCounts = categoriesArray.map(category => category.count);
  const sectorIds = categoriesArray.map(category => category.id);

  console.log(sortedLabels);
  console.log('sectorIDSCountyChart', sectorIds);
  

  const backgroundColor = sortedLabels.map(label => getColorByLabel(label));
  const hoverBackgroundColor = backgroundColor;

  const chartData = {
    labels: sortedLabels,
    datasets: [
      {
        label: null,
        data: sortedCounts,
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        borderWidth: 1,
      },
    ],
  };

  // Update the createChartOptions function
  const chartOptions = createChartOptions(title, county,  baseUrl, navigate, sectorIds);



  return <Pie data={chartData} options={chartOptions} />;
return null;
};

export default CenterChart;

// <Pie data={data} options={options} />