import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';

import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CardMedia, Divider } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';
import { fetchStaffMembers } from "../services/apiService";
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { PageWrapper } from "./PageWrapper";

// EmployeeCard component with improved styling
const EmployeeCard = ({ employee }) => {
    const serverUrl = process.env.REACT_APP_CMS_SERVER_URL;
    let thumb = '';
    if (employee.thumbnail?.data) {
        thumb = employee.thumbnail.data.attributes.url;
    }

    return (
        <Card sx={{ maxWidth: 345, mx: 'auto', mb: 3 }}>
            <CardContent>
                <CardMedia
                    component="img"
                    height="160"
                    image={thumb}
                    alt={employee.name}
                    sx={{ borderRadius: 2, objectFit: 'cover' }}
                />

                <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: 'bold', textAlign: 'center', textTransform: 'capitalize', mt: 1 }}
                    gutterBottom
                >
                    {employee.firstName} {employee.lastName}
                </Typography>

                <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ textAlign: 'center', mb: 1 }}
                >
                    {employee.position}
                    <br />
                    {employee.center} Center
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                    <LocalPhoneIcon fontSize="small" />
                    <Typography variant="body2">{employee.phone}</Typography>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" gap={1} mt={1}>
                    <EmailIcon fontSize="small" />
                    <Typography variant="body2">{employee.email}</Typography>
                </Box>
            </CardContent>
        </Card>
    );
}

export const NetworkDirectoryPage = () => {
    const { user } = useAuth0();
    const [staffMembers, setStaffMembers] = useState([]);
    const [filteredStaff, setFilteredStaff] = useState([]); // Filtered staff state
    const [loading, setLoading] = useState(true); // Added loading state
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState(''); // Search input state
    const [selectedCenter, setSelectedCenter] = useState('All Centers'); // Default to 'All Centers'

    // Extract unique centers for dropdown and sort them
    const centers = React.useMemo(() => {
        const allCenters = staffMembers.map((employee) => employee.attributes?.center || '');
        const uniqueCenters = Array.from(new Set(allCenters)).filter(center => center);
        return uniqueCenters.filter(center => center !== 'Lead').sort((a, b) => a.localeCompare(b));
    }, [staffMembers]);

    // Sort staff members alphabetically by last name
    const sortedStaff = React.useMemo(() => {
        return [...filteredStaff].sort((a, b) => {
            const lastNameA = a.attributes?.lastName?.toLowerCase() || '';
            const lastNameB = b.attributes?.lastName?.toLowerCase() || '';
            return lastNameA.localeCompare(lastNameB);
        });
    }, [filteredStaff]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const staff = await fetchStaffMembers();
                setStaffMembers(staff);
                setFilteredStaff(staff); // Initially, display all staff members
            } catch (error) {
                console.error('Error fetching staff members:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Filter staff members when search term or selected center changes
    useEffect(() => {
        const filtered = staffMembers.filter((employee) => {
            const fullName = `${employee.attributes?.firstName || ''} ${employee.attributes?.lastName || ''}`.toLowerCase();
            const position = employee.attributes?.position?.toLowerCase() || '';
            const center = employee.attributes?.center?.toLowerCase() || '';

            const matchesSearchTerm = (
                fullName.includes(searchTerm.toLowerCase()) ||
                position.includes(searchTerm.toLowerCase()) ||
                center.includes(searchTerm.toLowerCase())
            );

            const matchesCenter = selectedCenter === 'All Centers' || (selectedCenter === 'Lead Center' ? center === 'lead' : center === selectedCenter.toLowerCase());

            return matchesSearchTerm && matchesCenter;
        });
        setFilteredStaff(filtered);
    }, [searchTerm, selectedCenter, staffMembers]);

    if (!user) {
        return null;
    }

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Typography color="error" align="center" variant="h6">
                Error loading staff members: {error.message}
            </Typography>
        );
    }

    return (
        <PageWrapper title="Network Directory">
            <Container sx={{ mt: 4 }}>
                {/* Search Input */}
                <TextField
                    fullWidth
                    label="Search for staff"
                    variant="outlined"
                    sx={{ mb: 2 }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search by name, position, or center"
                />

                {/* Center Dropdown */}
                <TextField
                    select
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 4 }}
                    value={selectedCenter}
                    onChange={(e) => setSelectedCenter(e.target.value)}
                    SelectProps={{
                        native: true,
                    }}
                >
                    <option value="All Centers">All Centers</option>
                    <option value="Lead Center">Lead Center</option>
                    {centers.map((center) => (
                        <option key={center} value={center}>
                            {center}
                        </option>
                    ))}
                </TextField>

                <Grid container spacing={3}>
                    {sortedStaff.length > 0 ? (
                        sortedStaff.map((employee) => (
                            <Grid item key={employee.id}>
                                <EmployeeCard employee={employee.attributes} />
                            </Grid>
                        ))
                    ) : (
                        <Typography variant="h6" align="center" sx={{ width: '100%', mt: 4 }}>
                            No staff members found.
                        </Typography>
                    )}
                </Grid>
            </Container>
        </PageWrapper>
    );
};