import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { AdminPage } from "./pages/admin-page";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { ProtectedPage } from "./pages/protected-page";
import { PublicPage } from "./pages/public-page";
import { Dashboard } from "./components/dashboard/Dashboard";
import { MainRoute } from "./mainRoute";
import { QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';

const queryClient = new QueryClient();

export const MainWrapper = () => {
    const dataSentRef = React.useRef(false); // Ref to track if data has been sent

    const {
        isLoading,
        isAuthenticated,
        logout,
        user
    } = useAuth0();

    // Send user information to Netlify function when user logs in
    React.useEffect(() => {
        if (isAuthenticated && user && user.partner && !dataSentRef.current) {
            axios.post('/.netlify/functions/sendToZapier', {
                name: user.name,
                email: user.email,
                picture: user.picture,
                sub: user.sub
            })
            .then(response => {
                console.log('Successfully sent user data to Netlify function:', response.data);
                dataSentRef.current = true; // Set ref to true after successful request
            })
            .catch(error => {
                console.error('Error sending user data to Netlify function:', error);
            });
        }
    }, [isAuthenticated, user]);

    if (isLoading) {
        return (
            <div className="page-layout">
                <PageLoader />
            </div>
        );
    } else {
        return (
            <>
                <QueryClientProvider client={queryClient}>
                    <Dashboard user={user}>
                        <MainRoute />
                    </Dashboard>
                </QueryClientProvider>
            </>
        );
    }
};