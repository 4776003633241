import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Button, TextField,} from '@mui/material';

import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import callOpenAI from '../components/callOpenAI'


import { MarketSegmentationTable } from "../components/MarketSegmentationTable";
import EAReports from "../components/eaReports";

export default function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
}

export const AI = (props) => {



    const { user } = useAuth0();
    const [data, setData] = useState(null);
    const [total, setTotal] = useState();
    const [progress, setProgress] = useState(0);

    
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(false);
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      const result = await callOpenAI(prompt);
      setResponse(result);
      setLoading(false);
    };


    const [responseData, setResponseData] = useState({});








    const uniqueEmails = Object.values(responseData).reduce((unique, item) => {
        if (!unique.includes(item.email)) {
            unique.push(item.email);
        }
        return unique;
    }, []);


    function calculateTotalLoggableHours() {
        const loggableHoursArray = Object.values(responseData).map(item => item.loggableHours);
        const totalLoggableHours = loggableHoursArray.reduce((acc, curr) => acc + parseFloat(curr), 0);
        return parseFloat(totalLoggableHours.toFixed(2));
    }

    // [...new Set(responseData.map(item => item.email))];
    // const uniqueEmails = [0, 1]

    let gridNum;
    if (user.counties >= 3) {
        gridNum = 3;
    } else {
        gridNum = 4;
    }



    if (!user && responseData == null) {
        return null;
    }


    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));



    return (
        <PageLayout>

            <div className="content-layout">

                <Typography sx={{ textTransform: 'capitalize' }}>
                    <h1 id="page-title" >
                      AI Generator 
                    </h1>
                </Typography>
                <Typography sx={{ textTransform: 'capitalize' }}>
                    <h4 id="page-subtitle" >
                        
                    </h4>
                </Typography>

                <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxWidth: '600px',
    
      }}
    >
      <TextField
        id="prompt-input"
        label="Enter your prompt"
        variant="outlined"
        multiline={true}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        fullWidth
      />

      {!loading
      ? <Button variant="contained" type="submit">
      Ask AI
    </Button>
    :<CircularIndeterminate/>
    }

     



      {response && (
        <Box
          sx={{
            width: '100%',
            marginTop: 2,
            padding: 2,
            borderRadius: 1,
            boxShadow: 1,
            bgcolor: 'background.paper',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Response:
          </Typography>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>{response}</Typography>
        </Box>
      )}
    </Box>

            </div>
        </PageLayout>
    );
};
