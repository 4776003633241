import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CountyChart from "../components/charts/CountyChart";

import CircularProgress from '@mui/material/CircularProgress';
import SurveyQuestions from "../components/SurveyQuestions"






import { PageWrapper } from "./PageWrapper";
import AssetsList from "../components/AssetsList";


export const SatsisfactionSurveyPage = (props) => {


    return (
       <PageWrapper title="Client Satisfaction Survey Questions" showSecondaryTitle={false} >
        <SurveyQuestions/>



                            













</PageWrapper>
    );
};
