import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

// const useStyles = makeStyles({
//     form: {
//         display: 'flex',
//         flexDirection: 'column',
//         maxWidth: '400px',
//         margin: '0 auto',
//         gap: '1rem',
//     },
// });

const Input = styled('input')({
    display: 'none',
});

const ReceiptForm = () => {
    // const classes = useStyles();
    const [receipt, setReceipt] = useState({
        date: '',
        merchant: '',
        amount: '',
        description: '',
        image: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'amount' && isNaN(Number(value))) {
            return; // ignore non-numeric input for amount
        }
        setReceipt((prevReceipt) => ({
            ...prevReceipt,
            [name]: name === 'amount' ? value : value.trim(),
        }));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            setReceipt((prevReceipt) => ({
                ...prevReceipt,
                image: reader.result,
            }));
        };

        reader.readAsDataURL(file);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(receipt);
    };

    return (
        <Box component="form"  onSubmit={handleSubmit}>
            <TextField
                label="Date"
                name="date"
                type="date"
                value={receipt.date}
                onChange={handleInputChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                label="Merchant"
                name="merchant"
                type="text"
                value={receipt.merchant}
                onChange={handleInputChange}
            />
            <TextField
                label="Amount"
                name="amount"
                type="text"
                value={`$${receipt.amount}`}
                onChange={handleInputChange}
                inputProps={{
                    pattern: '^[0-9]*$',
                }}
            />
            <TextField
                label="Description"
                name="description"
                type="text"
                value={receipt.description}
                onChange={handleInputChange}
            />
            <label htmlFor="image">
                <Input
                    accept="image/*"
                    id="image"
                    name="image"
                    type="file"
                    onChange={handleImageChange}
                />
                <Button variant="contained" component="span">
                    Upload Image
                </Button>
            </label>
            <Button variant="contained" type="submit">
                Submit
            </Button>
        </Box>
    );
};

export default ReceiptForm;
