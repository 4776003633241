import React from 'react';
import ReactPlayer from 'react-player';
import { Card, CardMedia, Typography } from '@mui/material';

function PodcastPlayer({ podcastTitle, episodeNumber, thumbnail, audioSrc }) {
    return (
        <Card sx={{pb: 2}}>
            <CardMedia
                component="img"
                height="140"
                image={thumbnail}
                alt={podcastTitle}
            />
               <Typography sx={{m: 1,fontSize: 14, fontWeight: 'bold', px: 4}}>
            Episode {episodeNumber}
            </Typography>
            <Typography sx={{m: 1,fontSize: 14, px: 4}}>
            {podcastTitle}
            </Typography>
            <ReactPlayer
                url={audioSrc}
                width="100%"
                height="50px"
                controls={true}
                style={{ padding: '10px 20px' }}
            />
        </Card>
    );
}

export default PodcastPlayer;