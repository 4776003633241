import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CountyChart from "../components/charts/CountyChart";

import CircularProgress from '@mui/material/CircularProgress';






import { useLocation } from 'react-router-dom'
import { MarketSegmentationTable } from "../components/MarketSegmentationTable";
import CenterChart from "../components/charts/centerChart";
import { MarketSegmentationTableDetails } from "../components/MarketSegmentationTableDetails";
import { PageWrapper } from "./PageWrapper";


export const MarketSegmentationDetailPage = (props) => {



    const location = useLocation()

    const { county, data } = location.state



    const { user } = useAuth0();
    const [total, setTotal] = useState();
    const [progress, setProgress] = useState(100);
    const [loading, setLoading] = useState(false);

    console.log(data, 'the data');


    const apiData = {
        query: '',
        filter: {
            connective: 'and',
            propositions: [
                {
                    attribute: 'fips_code',
                    relation: 'equals',
                    value: county.fips,
                },
                {
                    attribute: 'primary_naics_code_id',
                    relation: 'equals',
                    value: '',
                },
                {
                    relation: 'range',
                    attribute: 'in_business_on',
                    value: {
                        start: '2020-01-01',
                        end: '2022-12-31',
                        relation: 'contains',
                    },
                },
            ],
        },
        limit: 0,
    };


    const naicsCodes = ['11', '21', '22', '23', '31', '32', '33', '42', '44', '45', '48', '49', '51', '52', '53', '54', '55', '56', '61', '62', '71', '72', '81'];







    let gridNum;
    if (user.counties >= 3) {
        gridNum = 3;
    } else {
        gridNum = 4;
    }



    if (!user) {
        return null;
    }


    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const totalCount = Object.values(data).reduce((acc, cur) => acc + cur.count, 0);
    const formattedTotalCount = totalCount.toLocaleString();

    return (
       <PageWrapper title="2021-2022 Market Segmentation" secondaryTitle={`${county.county} County`}>

           
                        <Grid lg={4}>



                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14, textAlign: 'center', textTransform: 'capitalize' }} gutterBottom>
                                        {county.county} County
                                    </Typography>
                                    <Typography sx={{ textAlign: 'center', fontSize: 10 }} variant="h5" color="text.secondary" component="div">
                                        Total # of Startup Businesses
                                    </Typography>



                                    <Typography sx={{ textAlign: 'center', fontSize: 20, mt: 1.5, mb: 1.5, fontWeight: 'bold' }} variant="h5" color="text.secondary" component="div">
                                        {progress == 100
                                            ? formattedTotalCount
                                            : <CircularProgress />
                                        }
                                        {/* {progress.toString()} */}
                                        {/* {progress < 100
                        ? <CircularProgress variant="determinate" value={progress} />
                        : { totalCount }
                    } */}

                                    </Typography>

                                    <CenterChart data={data} isCounty={true} county={county} baseUrl="/market-segmentation/county-detail-code"  description={"# of Startups"} />
                              

                                </CardContent>
                                <CardActions>
                                    <Button size="small">See Details</Button>
                                </CardActions>
                            </Card>







                        </Grid>
                        <Grid lg={8}>


                      <Card sx={{ minWidth: 275 }}>
    <CardContent>
          <MarketSegmentationTableDetails isCounty={true} county={county} data={data}  />

        {/* <MarketSegmentationTable data={data} /> */}

    </CardContent>
    <CardActions>

    </CardActions>
</Card>





</Grid>








</PageWrapper>
    );
};
