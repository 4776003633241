import React, { useState, useRef } from 'react';
import { FormControl, FormGroup, Button, TextField, Box, Grid } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { S3 } from 'aws-sdk';
// import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import BasicDatePicker from './BasicDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';

const s3 = new S3({
    accessKeyId: 'AKIA2QV6NE27HFSGFVFM',
    secretAccessKey: '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',
    region: 'us-west-2',
});



// const useStyles = makeStyles({
//     formInput: {
//         marginBottom: '10px !important', // Adjust the margin value as needed
//     },
// });


const Upload = ({ user, setUploads }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [receipt, setReceipt] = useState({ date: null, merchant: '', amount: null, description: '' });
    const fileInput = useRef(null);
    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    // const classes = useStyles();

    const handleFileUpload = async () => {
        const newFile = fileInput.current.files[0].name;
        const fileExtension = newFile.split('.').pop();

        function formatDate(date) {
            let newDate = new Date(date);
            return newDate.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            }).replace(/\//g, '_');
        }


        function getYear(date) {
            let newDate = new Date(date);
            return newDate.toLocaleDateString('en-US', {
                year: 'numeric'
            })
        }

        function getMonth(date) {
            let newDate = new Date(date);
            return newDate.toLocaleDateString('en-US', {
                month: '2-digit'
            })
        }


        let year = getYear(receipt.date);
        let month = getMonth(receipt.date);

        const fileName = `${formatDate(receipt.date)}_${receipt.merchant}_${receipt.amount.toFixed(2)}_${user.name}.${fileExtension}`;

        const params = {
            Bucket: 'utahsbdc',
            Key: "transactions/" + year + "/" + month + "/" + fileName,
            Body: selectedFile,
        };




        try {
            await s3.putObject(params).promise();
            console.log('File uploaded successfully!');
            setReceipt({ date: null, merchant: '', amount: 0, description: '' });
            fileInput.current.value = '';

            const data = {
                user, url: `https://utahsbdc.s3-us-west-2.amazonaws.com/transactions/${params.Key}`, fileName: fileName, key: params.Key, ...receipt
            };
            const response = await axios.post('/.netlify/functions/uploadFile', data, {
                headers: {
                    // Overwrite Axios's automatically set Content-Type
                    'Content-Type': 'application/json'
                }
            });

            const emailResponse = await axios.post('/.netlify/functions/sendEmail', data, {
            
                headers: {
                    // Overwrite Axios's automatically set Content-Type
                    'Content-Type': 'application/json'
                }
            } );
            console.log(emailResponse);

            setUploads(prevUploads => prevUploads + 1);




            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {/* <Box sx={{}}>

                <FormControl>
                    <FormGroup>

                        <LocalizationProvider dateAdapter={AdapterDayjs}  >
                            <DatePicker
                                className={classes.formInput}
                                label="Transaction Date"
                                value={receipt.date}
                                onChange={(newValue) => setReceipt({ ...receipt, date: newValue })}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>



                        <TextField
                            className={classes.formInput}
                            sx={{ width: '100%' }}
                            label="Merchant"
                            value={receipt.merchant}
                            onChange={(event) => setReceipt({ ...receipt, merchant: event.target.value })}
                        />



                        <CurrencyTextField
                            className={classes.formInput}
                            sx={{ width: '100%' }}
                            label="Amount"
                            variant="outlined"
                            currencySymbol="$"
                            outputFormat="number"
                            value={receipt.amount}
                            minimumValue="0"
                            decimalCharacter="."
                            digitGroupSeparator=","
                            onChange={(event, value) => setReceipt({ ...receipt, amount: value })}
                        />


                        <TextField
                            className={classes.formInput}
                            sx={{ width: '100%' }}
                            label="Description"
                            name="description"
                            type="text"
                            value={receipt.description}
                            onChange={(event) => setReceipt({ ...receipt, description: event.target.value })}

                        />


                        <input className={classes.formInput} type="file" onChange={handleFileSelect} ref={fileInput} />
                        <Button className={classes.formInput} sx={{}} variant="contained" onClick={handleFileUpload}>
                            Submit Receipt
                        </Button>

                    </FormGroup>
                </FormControl>
            </Box> */}
        </>
    );


};

export default Upload;
