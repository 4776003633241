const categories = [

    { title: 'Agriculture, Forestry, Fishing and Hunting', visualCodes: '11', codes: ['11'] },
    { title: 'Mining, Quarrying, and Oil and Gas Extraction', visualCodes: '21', codes: ['21'] },
    { title: 'Utilities', visualCodes: '22', codes: ['22'] },
    { title: 'Construction', visualCodes: '23',codes: ['23'] },
    { title: 'Manufacturing', visualCodes: '31-33', codes: ['31', '32', '33'] },
    { title: 'Wholesale Trade', visualCodes: '42', codes: ['42'] },
    { title: 'Retail Trade', visualCodes: '44-45', codes: ['44', '45'] },
    { title: 'Transportation and Warehousing', visualCodes: '48-49', codes: ['48', '49'] },
    { title: 'Information', visualCodes: '51', codes: ['51'] },
    { title: 'Finance and Insurance', visualCodes: '52', codes: ['52'] },
    { title: 'Real Estate and Rental and Leasing', visualCodes: '53', codes: ['53'] },
    { title: 'Professional, Scientific, and Technical Services', visualCodes: '54', codes: ['54'] },
    { title: 'Management of Companies and Enterprises', visualCodes: '55', codes: ['55'] },
    { title: 'Administrative and Support and Waste Management and Remediation Services', visualCodes: '56', codes: ['56'] },
    { title: 'Educational Services', visualCodes: '61', codes: ['61'] },
    { title: 'Health Care and Social Assistance', visualCodes: '62', codes: ['62'] },
    { title: 'Arts, Entertainment, and Recreation', visualCodes: '71', codes: ['71'] },
    { title: 'Accommodation and Food Services', visualCodes: '72', codes: ['72'] },
    { title: 'Other Services (except Public Administration)', visualCodes: '81', codes: ['81'] },
    
    ];

    export default categories;