import React from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PDFViewer = ({ url }) => {
    const toolbarPluginInstance = toolbarPlugin();
    const searchPluginInstance = searchPlugin();
    const { Toolbar } = toolbarPluginInstance;

    return (
        <div style={{ overflow: 'hidden', height: '100vh' }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    {/* Toolbar for navigation and zoom controls */}
                    <Toolbar />
                    <div style={{ flex: 1, overflow: 'auto' }}>
                        <Viewer
                            fileUrl={url}
                            plugins={[
                                toolbarPluginInstance,
                                searchPluginInstance
                            ]}
                            defaultScale={SpecialZoomLevel.PageWidth} // Adjust the initial zoom level to fit the page width
                            initialPage={0} // Start on the first page
                            enableSmoothScroll
                            renderMode="canvas" // Use canvas rendering for better performance
                            withCredentials={false}
                        />
                    </div>
                </div>
            </Worker>
        </div>
    );
};

export default PDFViewer;
