import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { S3 } from 'aws-sdk';


const s3 = new S3({
    accessKeyId: 'AKIA2QV6NE27HFSGFVFM',
    secretAccessKey: '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',
    region: 'us-west-2',
});



const columns = [


    {
        field: 'date',
        headerName: 'Date',
        valueFormatter: (params) => {
            const date = new Date(params.value);
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        },
    },
    { field: 'purchaser', headerName: 'Purchaser', width: 150 },
    { field: 'merchant', headerName: 'Merchant', width: 200 },
    {
        field: 'amount',
        headerName: 'Amount',
        width: 150,
        valueFormatter: (params) => {
            const amount = Number(params.value).toFixed(2);
            return `$${amount}`;
        },
    },
    { field: 'description', headerName: 'Description', width: 200 },
    {
        field: 'download',
        headerName: 'Receipt',
        export: false,
        width: 100,
        renderCell: (params) => {




            return (
                <Button variant="outlined" onClick={() => handleDownload(params.row)}>
                    Download
                </Button>
            )
        }
    },
];

const handleDownload = (row) => {


    const awsParams = {
        Bucket: 'utahsbdc',
        Key: row.key,
        Expires: 60 * 5, // URL expires after 5 minutes
    };

    const url = s3.getSignedUrl('getObject', awsParams);

    console.log(row);
    window.open(url, '_blank');
    // Code to download the data goes here
};

export default function ReceiptReports({ data }) {

    const getRowId = (row) => row._id;

    return (

        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                // components={{ Toolbar: GridToolbar }}
                exportCsvOptions={{ includeColumns: columns.filter((column) => column.export !== false).map((column) => column.field) }}
                components={{
                    Toolbar: () => (
                        <GridToolbar>
                            <button onClick="">Download</button>
                        </GridToolbar>
                    ),
                }}
                checkboxSelection
                getRowId={getRowId}
            />
        </div>
    );
}