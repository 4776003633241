import React from "react";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

export const MarketSegmentationTableDetails = ({ data, county, isCounty}) => {

    console.log("COUNTY INFO", county);
    const navigate = useNavigate();
    const handleRowClick = (param, event) => {
        // Handle the row click event here
        console.log('Row clicked:', param.row);
        if(!isCounty) {
            navigate(`/market-segmentation/center-detail-code/${param.row.id}`);
        } else {
            navigate(`/market-segmentation/county-detail-code/${param.row.id}`, { state: {county: county}});
        }
   

      };
    

 console.log(data, 'tableDAta')
    const sectorLists = Object.values(data).map(({ sectorId, vCodes, count }) => ({
        'id': 44,
        'name': 'test',
        vCodes,
    }));


    const sectorList = Object.entries(data).map(([title, { count, vCodes }], index) => ({
        id: vCodes, sectorName: title,  count
      }));
      

      console.log(sectorList);




    const columns = [
        { field: 'id', headerName: 'ID', width: '5' },
        { field: 'sectorName', headerName: 'NAICS Sector', width: 300 },
        { field: 'count', headerName: 'Count', type: 'number' },

    ];

 


    return (
        <div style={{ height: 465, width: '100%' }}>
            
            <DataGrid
                rows={sectorList}
                columns={columns}
                pageSize={25}
                rowsPerPageOptions={[24]}
                onRowClick={handleRowClick}
                components={{
                    Toolbar: GridToolbar
                }} />


        </div>
    );
};
