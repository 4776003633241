import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export const GenerateVideo = () => {
  const [videoTitle, setVideoTitle] = useState('');
  const [script, setScript] = useState('');
  const [cta, setCta] = useState('');
  const [logoLink, setLogoLink] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      videoTitle,
      script,
      cta,
      logoLink,
    };

    try {
      const response = await axios.post('https://your-rest-api-endpoint.com/submit', payload);
      console.log(response.data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} autoComplete="off" sx={{ maxWidth: 480, margin: 'auto' }}>
      <TextField
        fullWidth
        required
        label="Video Title"
        value={videoTitle}
        onChange={(e) => setVideoTitle(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        required
        multiline="true"
        label="Script"
        value={script}
        onChange={(e) => setScript(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        required
        label="CTA"
        value={cta}
        onChange={(e) => setCta(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        required
        label="Logo (Link)"
        value={logoLink}
        onChange={(e) => setLogoLink(e.target.value)}
        margin="normal"
      />
      <Button fullWidth variant="contained" color="primary" type="submit" sx={{ mt: 2 }}>
        Submit
      </Button>
    </Box>
  );
};

