// useNotionData.js
import { useState, useEffect } from 'react';

export const useNotionData = () => {
  const [data, setData] = useState([]);
  const [pageDetails, setPageDetails] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch('/.netlify/functions/fetchNotionTable');
        const jsonResponse = await response.json();
        console.log(jsonResponse);
        const kpiData = jsonResponse.databaseData.results.map(page => ({
          kpi: page.properties.KPI.title[0].plain_text,
          value: page.properties.Value.rich_text[0].plain_text,
          id: page.id
        }));

       
        setPageDetails(jsonResponse.parentPageData);
        setData(kpiData);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, pageDetails, isLoading, error };
};