import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { gsap } from 'gsap';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CountyChart from "../components/charts/CountyChart";

import CircularProgress from '@mui/material/CircularProgress';

import { PageWrapper } from "./PageWrapper";
import HeyzineViewer from "../components/HeyZineViewer";
import PDFViewer from "../components/PDFViewer";
import ChartComponent from "../components/ChartComponent";
import BenchmarkGraphs from "../components/BenchmarkingGraphs";
import TotalsDisplay from "../components/TotalsDisplay";
import {
  Store as StoreIcon,
  AttachMoney as AttachMoneyIcon,
  People as PeopleIcon,
  TrendingUp as TrendingUpIcon,
  Work as WorkIcon,
  Balance as BalanceIcon,
  Person as PersonIcon,
  Login as LoginIcon,
  Schedule as ScheduleIcon,
  HourglassFull as HourglassFullIcon,
  Forum as ForumIcon,
  Groups as GroupsIcon,
  School as SchoolIcon,
  Event as EventIcon,
} from '@mui/icons-material';

import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';



const iconSize = 44;

const iconMap = {
  store: <StoreIcon sx={{ fontSize: iconSize }} />,
  attach_money: <AttachMoneyIcon sx={{ fontSize: iconSize }} />,
  people: <PeopleIcon sx={{ fontSize: iconSize }} />,
  trending_up: <TrendingUpIcon sx={{ fontSize: iconSize }} />,
  work: <WorkIcon sx={{ fontSize: iconSize }} />,
  balance: <BalanceIcon sx={{ fontSize: iconSize }} />,
  person: <PersonIcon sx={{ fontSize: iconSize }} />,
  login: <LoginIcon sx={{ fontSize: iconSize }} />,
  schedule: <ScheduleIcon sx={{ fontSize: iconSize }} />,
  hourglass_full: <HourglassFullIcon sx={{ fontSize: iconSize }} />,
  forum: <ForumIcon sx={{ fontSize: iconSize }} />,
  groups: <GroupsIcon sx={{ fontSize: iconSize }} />,
  school: <SchoolIcon sx={{ fontSize: iconSize }} />,
  event: <EventIcon sx={{ fontSize: iconSize }} />,
};

export const EAOverview = (props) => {

  return (
    <PageWrapper title="EA Talking Points" showSecondaryTitle={false} secondaryTitle={`2024 YTD`}>
     <PDFViewer url="https://utahsbdc.s3.us-west-2.amazonaws.com/documents/EATalkingPoints.pdf"/>
    </PageWrapper>
  );
};
