import React from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const AxleDisplay = ({ data, onViewData  }) => {
  const { responseData, formattedData } = data;

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {/* API DATA */}
      </Typography>

      {/* Displaying responseData */}
      {/* <Typography variant="h5" gutterBottom>
        Response Data
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell align="right">Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(responseData).map((code) => (
              <TableRow key={code}>
                <TableCell component="th" scope="row">
                  {code}
                </TableCell>
                <TableCell align="right">{responseData[code].count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}

      {/* Displaying formattedData */}
      <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>
        Results
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell align="left">Count</TableCell>
              <TableCell>Sector Name</TableCell>
    
              <TableCell align="left">Codes</TableCell>
              <TableCell align="Left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(formattedData).map((sectorName) => (
              <TableRow key={sectorName}>
                <TableCell align="left">{formattedData[sectorName].count}</TableCell>
                <TableCell component="th" scope="row">
                  {sectorName}
                </TableCell>
                
                <TableCell align="left">{formattedData[sectorName].codes.join(', ')}</TableCell>
                <TableCell align="center"><Button onClick={onViewData} >View Data</Button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AxleDisplay;