import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PageLayout } from "../components/page-layout";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useTheme, useMediaQuery } from "@mui/material";
export const PageWrapper = ({ title, subtitle, children, secondaryTitle, showSecondaryTitle = false }) => {
  const { user } = useAuth0();
  const [uploads, setUploads] = useState(0);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {}, []);
  if (!user) {
    return null;
  }

  return (
<>
      {/* Set maxWidth to 'false' to make the container full width */}

      <Container maxWidth='md' sx={{margin: 'auto', ml: '24px', py: theme.spacing(2)}} disableGutters>
        <Box> {/* Centering the content */}
          <Box sx={{mb: 2}}>
          <Typography variant="h1" sx={{ textTransform: 'capitalize',textAlign: 'left', fontSize: 30, pb: 0}} id="page-title">
            {title}
          </Typography>
          {showSecondaryTitle && (
            <Typography variant="h6" sx={{ textTransform: 'capitalize', py: 1, fontStyle: 'italic' }} id="page-subtitle">
              {secondaryTitle ? secondaryTitle : user.center + " Center"}
            </Typography>
          )}
          {subtitle && (
            <Typography sx={{ fontSize: 12 }}>
              {subtitle}
            </Typography>
          )}
          </Box>
          {children}
        </Box>
      </Container>
     
      </>
  );
};
