import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  FormControlLabel,
  TextField,
  Divider,
  Typography,
  Box
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fetchSuccessStories, fetchAllComments, fetchCommentsForStory, submitComment, updateStory } from "../services/apiService";
import axios from 'axios';
import { PageWrapper } from "./PageWrapper";
import UploadForm from "./UploadForm";

export const SuccessStoryOverviewPage = () => {
  const { user } = useAuth0();
  const [stories, setStories] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('businessName');
  const [open, setOpen] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);
  const [originalAttributes, setOriginalAttributes] = useState({});
  const [storyComments, setStoryComments] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [commentsPerPage] = useState(5);
  const [currentCommentPage, setCurrentCommentPage] = useState(1);
  const [filmDateDialogOpen, setFilmDateDialogOpen] = useState(false);
  const [isCommentsLoading, setCommentsLoading] = useState(false);

  useEffect(() => {
    const getSuccessStories = async () => {
      if (!user) {
        console.error('User is undefined');
        return;
      }
      if (!user.center) {
        console.error('User center is undefined');
        return;
      }
      try {
        const data = await fetchSuccessStories(user.center);
        setStories(data);
        console.log(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getSuccessStories();
  }, [user]);

  useEffect(() => {
    fetchAllCommentsForPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const fetchAllUserDetails = async () => {
      const uniqueUserIds = [...new Set(allComments.map(comment => comment.attributes.user_id))];
      for (const userId of uniqueUserIds) {
        await fetchUserDetails(userId);
      }
    };

    if (allComments.length > 0) {
      fetchAllUserDetails();
    }

    console.log('allComments', allComments)
  }, [allComments]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getAttributeValue = (story, attribute) => {
    if (attribute === 'center') {
      // Safely access nested properties
      return story.attributes.center?.data?.attributes?.Name || 'Unknown Center';
    }
    return story.attributes[attribute];
  };

  const sortedStories = stories.sort((a, b) => {
    const aValue = getAttributeValue(a, orderBy);
    const bValue = getAttributeValue(b, orderBy);

    if (aValue < bValue) {
      return order === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const fetchUserDetails = async (userId) => {
    if (userDetails[userId]) {
      return userDetails[userId];
    }
    try {
      const response = await axios.get(`/.netlify/functions/fetchUserDetails?userId=${userId}`);
      const userData = response.data;
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        [userId]: userData
      }));
      return userData;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return null;
    }
  };

  const handleRowClick = async (storyId) => {
    console.log(`Clicked story ID: ${storyId}`);
    const story = stories.find(story => story.id === storyId);
    if (story) {
      console.log(`Found story: ${story.attributes.businessName}`);
      setSelectedStory(story);
      setOriginalAttributes(story.attributes); // Store the original attributes
      fetchCommentsForSelectedStory(story.id);
      setOpen(true);
    } else {
      console.error(`Story with ID ${storyId} not found.`);
    }
  };

  const fetchCommentsForSelectedStory = async (storyId) => {
    setCommentsLoading(true);
    try {
      const response = await fetchCommentsForStory(storyId);
      setStoryComments(response || []);
      await Promise.all(response.map(comment => fetchUserDetails(comment.attributes.user_id)));
    } catch (error) {
      console.error('Error loading story comments:', error);
      setStoryComments([]);
    } finally {
      setCommentsLoading(false);
    }
  };

  const fetchAllCommentsForPage = async (page = 1) => {
    try {
      const response = await fetchAllComments(page);
      console.log('setResponse', response);
      setAllComments(response);
    } catch (error) {
      console.error('Error loading all comments:', error);
      setAllComments([]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedStory(null);
    setStoryComments([]);
  };

  const handleCommentSubmit = async () => {
    if (!newComment.trim()) return;
    try {
      const staffId = user.sub;
      const response = await submitComment(selectedStory.id, newComment, staffId);
      
      const newCommentData = {
        attributes: {
          Text: newComment,
          createdAt: new Date().toISOString(),
          user_id: user.sub,
          success_story: {
            data: {
              id: selectedStory.id,
              attributes: {
                businessName: selectedStory.attributes.businessName, // Ensure businessName is included
              },
            },
          },
        },
        userName: user.name || user.nickname || 'Unknown'
      };
  
      // Update the storyComments state with the new comment
      setStoryComments([...storyComments, newCommentData]);
  
      // Optionally, update the allComments state if you want to reflect it on the main page
      setAllComments([...allComments, newCommentData]);
  
      setNewComment('');
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCommentPageChange = (newPage) => {
    setCurrentCommentPage(newPage);
  };

  const handleCommentClick = (comment) => {
    const storyId = comment.attributes.success_story?.data?.id;
    if (storyId) {
      handleRowClick(storyId);
    } else {
      console.error('Success story ID not found for comment:', comment);
    }
  };

  const handleFilmDateChange = async (newDate) => {
    if (!selectedStory) return;
  
    const updatedAttributes = { ...selectedStory.attributes, filmDate: newDate };
    setSelectedStory({ ...selectedStory, attributes: updatedAttributes });
  
    // Determine which attributes have changed
    const changes = {};
    for (const key in updatedAttributes) {
      if (updatedAttributes[key] !== originalAttributes[key]) {
        changes[key] = updatedAttributes[key];
      }
    }
  
    try {
      const response = await updateStory(selectedStory.id, changes);
  
      // Adjusted response structure handling
      if (response && response.attributes) {
        const updatedStoryAttributes = {
          ...selectedStory.attributes, // Preserve existing attributes
          ...response.attributes, // Merge updated attributes
        };
  
        // Update the local stories state with the updated story
        setStories((prevStories) =>
          prevStories.map((story) =>
            story.id === selectedStory.id ? { ...story, attributes: updatedStoryAttributes } : story
          )
        );
  
        // Update the originalAttributes to the new state
        setOriginalAttributes(updatedStoryAttributes);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error('Error updating filmDate:', error);
    }
  };
  const handleFilmDateCheckboxChange = async (e) => {
    if (!selectedStory) return;
  
    const updatedAttributes = { ...selectedStory.attributes };
    updatedAttributes.filmDate = e.target.checked ? updatedAttributes.filmDate : null;
    setSelectedStory({ ...selectedStory, attributes: updatedAttributes });
  
    if (e.target.checked) {
      setFilmDateDialogOpen(true);
    } else {
      // If unchecked, remove filmDate from the API
      try {
        const changes = { filmDate: null };
        const response = await updateStory(selectedStory.id, changes);
  
        if (response && response.attributes) {
          const updatedStoryAttributes = {
            ...selectedStory.attributes,
            ...response.attributes,
          };
  
          setStories((prevStories) =>
            prevStories.map((story) =>
              story.id === selectedStory.id ? { ...story, attributes: updatedStoryAttributes } : story
            )
          );
  
          setOriginalAttributes(updatedStoryAttributes);
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error('Error removing filmDate:', error);
      }
    }
  };

  const handleCheckboxChange = async (field, checked) => {
    if (!selectedStory) return;
  
    const updatedAttributes = { ...selectedStory.attributes, [field]: checked };
    setSelectedStory({ ...selectedStory, attributes: updatedAttributes });
  
    // Determine which attributes have changed
    const changes = {};
    for (const key in updatedAttributes) {
      if (updatedAttributes[key] !== originalAttributes[key]) {
        changes[key] = updatedAttributes[key];
      }
    }
  
    try {
      const response = await updateStory(selectedStory.id, changes);
  
      // Adjusted response structure handling
      if (response && response.attributes) {
        const updatedStoryAttributes = {
          ...selectedStory.attributes, // Preserve existing attributes
          ...response.attributes, // Merge updated attributes
        };
  
        // Update the local stories state with the updated story
        setStories((prevStories) =>
          prevStories.map((story) =>
            story.id === selectedStory.id ? { ...story, attributes: updatedStoryAttributes } : story
          )
        );
  
        // Update the originalAttributes to the new state
        setOriginalAttributes(updatedStoryAttributes);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    }
  };
  const paginatedComments = allComments
    .sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt))
    .slice(
      (currentCommentPage - 1) * commentsPerPage,
      currentCommentPage * commentsPerPage
    );

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading success stories: {error.message}</p>;

  return (
    <PageWrapper title="Success Stories" secondaryTitle="2024 Success Stories" showSecondaryTitle={true}>
      <div>

     
        {/* <Typography variant="h6" gutterBottom>
          Legend: 
        </Typography> */}
        <Box sx={{my: 2}}>
        <Typography variant="body2" gutterBottom>
          <span style={{ backgroundColor: 'rgba(0, 173, 255, 0.1)', padding: '2px 5px', borderRadius: '3px' }}>Blue</span> - Filming Scheduled
        </Typography>
        <Typography variant="body2" gutterBottom>
          <span style={{ backgroundColor: 'rgba(0, 255, 0, 0.1)', padding: '2px 5px', borderRadius: '3px' }}>Green</span> - Filming Completed
        </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'businessName'}
                    direction={orderBy === 'businessName' ? order : 'asc'}
                    onClick={() => handleRequestSort('businessName')}
                  >
                    Business Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'center'}
                    direction={orderBy === 'center' ? order : 'asc'}
                    onClick={() => handleRequestSort('center')}
                  >
                    Center
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'clientName'}
                    direction={orderBy === 'clientName' ? order : 'asc'}
                    onClick={() => handleRequestSort('clientName')}
                  >
                    Contact Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'address'}
                    direction={orderBy === 'address' ? order : 'asc'}
                    onClick={() => handleRequestSort('address')}
                  >
                    Address
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'phone'}
                    direction={orderBy === 'phone' ? order : 'asc'}
                    onClick={() => handleRequestSort('phone')}
                  >
                    Phone Number
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'email'}
                    direction={orderBy === 'email' ? order : 'asc'}
                    onClick={() => handleRequestSort('email')}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'website'}
                    direction={orderBy === 'website' ? order : 'asc'}
                    onClick={() => handleRequestSort('website')}
                  >
                    Website
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedStories.map((story, index) => (
                <TableRow
                  key={story.id}
                  onClick={() => handleRowClick(story.id)}
                  style={{ cursor: 'pointer' }}
                  sx={{
                    backgroundColor: story.attributes.videoShot
                      ? 'rgba(0, 255, 0, 0.1)' // Green background if filming is completed
                      : story.attributes.filmDate
                      ? 'rgba(0, 173, 255, 0.1)' // Blue background if filming is scheduled
                      : index % 2 === 0
                      // ? 'rgba(0, 0, 0, 0.04)' // Alternating row colors
                      // : 'transparent'
                  }}
                >
                  <TableCell>{story.attributes.businessName}</TableCell>
                  <TableCell>{getAttributeValue(story, 'center')}</TableCell>
                  <TableCell>{story.attributes.clientName}</TableCell>
                  <TableCell>{`${story.attributes.address}, ${story.attributes.city}, ${story.attributes.state} ${story.attributes.zip}`}</TableCell>
                  <TableCell>{story.attributes.phone}</TableCell>
                  <TableCell>{story.attributes.email}</TableCell>
                  <TableCell>{story.attributes.website}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider sx={{ my: 2 }} />
        <strong>Latest Updates</strong>
        <div>
        {isCommentsLoading ? (
          <p>Loading comments...</p>
        ) : paginatedComments.length > 0 ? (
          paginatedComments.map((comment, index) => (
            <Paper
              key={index}
              style={{
                padding: '10px',
                margin: '10px 0',
                cursor: 'pointer'
              }}
              onClick={() => handleCommentClick(comment)}
            >
              <div style={{ fontSize: '0.9em', color: '#555' }}>
                <Typography variant="h6" sx={{fontStyle: 'italic'}}>
                  {comment.attributes.success_story?.data?.attributes?.businessName || 'Unknown Business'}
                </Typography>
                <span style={{ float: 'right', marginTop: '-10px' }}>
                  {new Date(comment.attributes.createdAt).toLocaleString()}
                </span>
                <Divider sx={{my:1}}/>
                <strong>{userDetails[comment.attributes.user_id]?.name || 'Unknown'}</strong>
              </div>
              <div style={{ marginTop: '5px' }}>
                {comment.attributes.Text}
              </div>
            </Paper>
          ))
        ) : (
          <p>No comments available.</p>
        )}
        </div>
        <div>
          <Button
            onClick={() => handleCommentPageChange(currentCommentPage - 1)}
            disabled={currentCommentPage === 1}
          >
            Previous
          </Button>
          <span>Page {currentCommentPage} of {Math.ceil(allComments.length / commentsPerPage)}</span>
          <Button
            onClick={() => handleCommentPageChange(currentCommentPage + 1)}
            disabled={currentCommentPage === Math.ceil(allComments.length / commentsPerPage)}
          >
            Next
          </Button>
        </div>

        <Dialog 
          open={open} 
          onClose={handleClose} 
          maxWidth="md" 
          fullWidth
        >
          <DialogTitle>Story Details</DialogTitle>
          <DialogContent>
            {selectedStory && (
              <>
                <Typography variant="h6" sx={{fontWeight: 'bold'}}>{selectedStory.attributes.businessName}</Typography>
                <Typography variant="body1">{selectedStory.attributes.address}</Typography>
                <Typography variant="body1">{selectedStory.attributes.city + ', ' +  selectedStory.attributes.state + ' ' +  selectedStory.attributes.zip}</Typography>
                <Typography variant="body1" sx={{mt: 2}}>{selectedStory.attributes.clientName}</Typography>
                <Typography variant="body1">{selectedStory.attributes.phone}</Typography>
                <Typography variant="body1" sx={{textTransform: 'lowercase'}}>{selectedStory.attributes.email}</Typography>
                <Typography variant="body1" sx={{textTransform: 'lowercase'}}>{selectedStory.attributes.website}</Typography>
                <br/>
                <Typography variant="body1" sx={{mt: 2}}><strong>Availability:</strong> {selectedStory.attributes.availability || 'Not specified'}</Typography>
               
                <Button
               
  variant="contained"
  color="secondary"
  onClick={() => window.open(selectedStory.attributes.vimeoReview, '_blank')}
  disabled={!selectedStory?.attributes?.vimeoReview}
  style={{ margin: '10px 0' }}
>
  Vimeo Review
</Button>

                <Button
                  variant="contained"
                  color="primary"
                  href={selectedStory.attributes.submissionLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ margin: '20px 0' }}
                >
                  View Google Drive
                </Button>
                <Divider sx={{my: 2}} />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stepper activeStep={-1} alternativeLabel>
  {[
    { label: 'Media Waiver', attribute: 'mediaWaiver', checked: selectedStory.attributes.mediaWaiver },
    { label: 'Filming Scheduled', attribute: 'filmDate', checked: !!selectedStory.attributes.filmDate },
    { label: 'Filming Completed', attribute: 'videoShot', checked: selectedStory.attributes.videoShot },
    { label: 'Article Completed', attribute: 'articleCompleted', checked: selectedStory.attributes.articleCompleted }
  ].map((step, index) => (
    <Step key={index}>
      <StepLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={step.checked}
              onChange={(e) => {
                if (step.label === 'Filming Scheduled') {
                  handleFilmDateCheckboxChange(e);
                } else {
                  handleCheckboxChange(step.attribute, e.target.checked);
                }
              }}
            />
          }
          label={step.label}
        />
      </StepLabel>
    </Step>
  ))}
</Stepper>

{/* Conditionally render the filmDate below the stepper */}
{selectedStory.attributes.filmDate && (
  <Typography variant="body2" sx={{ mt: 2 }}>
    <strong>Scheduled Film Date:</strong> {new Date(selectedStory.attributes.filmDate).toLocaleString()}
  </Typography>
)}
                </LocalizationProvider>

                <Dialog
                  open={filmDateDialogOpen}
                  onClose={() => setFilmDateDialogOpen(false)}
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle>Schedule Film Date & Time</DialogTitle>
                  <DialogContent>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
  <DateTimePicker
    label="Scheduled Film Date & Time"
    value={selectedStory.attributes.filmDate || null}
    onChange={(newValue) => {
      setSelectedStory((prevStory) => ({
        ...prevStory,
        attributes: { ...prevStory.attributes, filmDate: newValue }
      }));
      handleFilmDateChange(newValue);
    }}
    renderInput={(params) => <TextField {...params} />}
  />
</LocalizationProvider>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setFilmDateDialogOpen(false)} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>

                <DialogContentText>
                  <Divider sx={{my: 2}} /> 
                  <strong>Updates</strong>
                  {storyComments.length > 0 ? (
                    <div>
                      {storyComments
                        .sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt))
                        .map((comment, index) => (
                          <Paper
                            key={index}
                            style={{
                              padding: '10px',
                              margin: '10px 0',
                              backgroundColor: index === 0 ? 'rgb(236 246 255)' : '#f5f5f5',
                              border: index === 0 ? 'none' : 'none'
                            }}
                          >
                            <div style={{ fontSize: '0.9em', color: '#555' }}>
                              <strong>{userDetails[comment.attributes.user_id]?.name || 'Unknown'}</strong>
                              <span style={{ float: 'right' }}>{new Date(comment.attributes.createdAt).toLocaleString()}</span>
                            </div>
                            <div style={{ marginTop: '5px' }}>
                              {comment.attributes.Text}
                            </div>
                          </Paper>
                        ))}
                    </div>
                  ) : (
                    <p>No comments available.</p>
                  )}
                </DialogContentText>
                <TextField
                  label="Add a comment"
                  fullWidth
                  multiline
                  rows={4}
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  variant="outlined"
                  style={{ margin: '20px 0' }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCommentSubmit}
                  disabled={!newComment.trim()}
                >
                  Post Comment
                </Button>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      
    </PageWrapper>
  );
};