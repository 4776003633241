import React from 'react';
import { Grid, Box } from '@mui/material';
import CardValue from './CardValue'; // Importing the Course component

const ImpactList = ({ courses, userCertifications }) => {
  return (
    <Box sx={{ p: 1, m: 1 }}>
      <Grid container spacing={2} justifyContent="flex-start">
        {courses.map((course, index) => {
          const certification = userCertifications.find(cert => cert.title === course.title);
          return (
            <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
              <CardValue
                title={course.title}
                image={course.image}
                value={course.value}
                isCertified={!!certification}
                certifiedDate={certification?.certifiedDate}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ImpactList;
