import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SimpleCard from '../components/SimpleCard';
import { Link } from '@mui/material';
import financeCover from '../images/finance.png'
import EaCourses from '../components/EaCourses';
import { Input } from '@mui/material';

const StyledPaper = styled(Paper)({
    padding: '1rem',
    height: '100%',
    background: '#eeeeee'
});

const courseData = [
    {
        id: '1',
        title: 'Course outline',
        status: 'Reviewing',
    },
    {
        id: '2',
        title: 'Scripting',
        status: 'Reviewing',
    },
    {
        id: '3',
        title: 'Recording',
        status: 'In Progress',
    },
    {
        id: '4',
        title: 'Editing',
        status: 'In Progress',
    },
    {
        id: '5',
        title: 'Final Review',
        status: 'In Review',
    },
    {
        id: '6',
        title: 'Publishing',
        status: 'Completed',
    },
];

export const Knowledgebase = () => {
    const [data, setData] = React.useState(courseData);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(data);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setData(items);
    };

    return (
        <Container sx={{ mt: 4 }}>
            <Typography variant="h2" align="left" sx={{ mb: 2 }}>
               Knowledgebase
            </Typography>

            <Typography variant="p" align="left" sx={{ mb: 4, fontSize: 15 }}>
            Welcome to our Knowledge Base page, your central resource for Standard Operating Procedures (SOPs) and how-to guides. This platform serves as your tool for efficient and consistent task execution across our organization. Dive in, learn, and enhance your work productivity.            </Typography>
<Container sx={{mt: 5, textAlign: 'right'}}>
<Input type="search" placeholder='search' sx={{fontSize: 16}}/>
</Container>
            <Typography variant="h3" sx={{ mb: 0, mt: 4}}>
           SOPs
        </Typography>


            <Grid sx={{ mt: 2 }} container spacing={2}>
        
            <Grid item xs={12} md={2}>

<Box
    sx={{
        height: '100%',
    }}
>
   
      

      

        <Box                              >
                                <Link href="https://utahsbdc.mylearnworlds.com/course/business-formation" target="_blank" underline="none">
                                    <SimpleCard image="https://utahsbdc.org/wp-content/uploads/2023/07/sop.jpg" title="Staff Meeting Best Practices"  />

                                </Link>
                            </Box>

                       

       

 
</Box>
</Grid>

<Grid item xs={12} md={2}>

<Box
    sx={{
        height: '100%',
       
    }}
>
   
      

      

        <Box                              >
                                <Link href="https://utahsbdc.mylearnworlds.com/course/business-formation" target="_blank" underline="none">
                                    <SimpleCard image="https://utahsbdc.org/wp-content/uploads/2023/07/sop.jpg" title="Training Events"  />

                                </Link>
                            </Box>

                       

       

 
</Box>
</Grid>

            </Grid>


            <Typography variant="h3" sx={{ mb: 0, mt: 4}}>
           How To Guides
        </Typography>


            <Grid sx={{ mt: 2 }} container spacing={2}>
        
            <Grid item xs={12} md={2}>

<Box
    sx={{
        height: '100%',
    }}
>
   
      

      

        <Box                              >
                                <Link href="/guide" underline="none">
                                    <SimpleCard image="https://utahsbdc.org/wp-content/uploads/2023/07/guide.jpg" title="Exporting Market Segmentation Contact Data"  />

                                </Link>
                            </Box>

                       

       

 
</Box>
</Grid>

            </Grid>
        </Container>
    )
};
