import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';


function createData(title, status, priority, tentativeDate) {
    return { title, status, priority, tentativeDate };
}

const rows = [
    createData('Business Formation', 'Copywriting', 'High', 'June 30, 2023'),
    createData('Onboarding', 'Copywriting', 'High', 'June 30, 2023'),
    createData('Financing Your Business', 'Course Development', 'High', 'June 30, 2023'),
    createData('Marketing Your Business', 'Course Development', 'High', 'June 30, 2023'),
    createData('Cyber Security', 'Course Development', 'High', 'June 30, 2023'),
    createData('eCommerce', 'Course Development', 'High', 'June 30, 2023'),
    createData('Business Accounting & Budgeting', 'Not Started', 'Medium', 'June 30, 2023'),
    createData('Government Contracting', 'Not Started', 'Medium', 'June 30, 2023'),
    createData('International Trade', 'Not Started', 'Medium', 'June 30, 2023'),
    createData('Business Licensing', 'Not Started', 'Medium', 'June 30, 2023'),
  

].sort((a, b) => a.status.localeCompare(b.status));

export default function EaCourses() {
    // const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table aria-label="course table">
                <TableHead>
                    <TableRow>
                        <TableCell>Course Title</TableCell>
                        <TableCell align="center">Course Status</TableCell>
                        <TableCell align="center">Course Priority</TableCell>
                        <TableCell align="center">Tentative Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.title}>
                            <TableCell component="th" scope="row">
                                {row.title}
                            </TableCell>
                            <TableCell align="center">{row.status}</TableCell>
                            <TableCell align="center">{row.priority}</TableCell>
                            <TableCell align="center">{row.tentativeDate}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
