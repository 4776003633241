import React from "react";
import { PageLayout } from "../components/page-layout";
import { Divider, Typography } from "@mui/material";
import { PageWrapper } from "./PageWrapper";
import AIToolsCredentials from "../components/AIToolsCredentials";
import AdvisorTools from "../components/AdvisorTools";

export const AItoolsPage = () => (
  <PageWrapper title={"AI Tools"} showSecondaryTitle={false} >
    <div className="content-layout">
      <Typography sx={{ textTransform: 'capitalize', mb: 2, fontSize: 12, mt: 2}}>
      Welcome to the Advisor GPT Tools page. Below, you'll find your login information for ChatGPT and access to various GPT tools designed to assist you in providing expert business counseling. Use these tools to streamline your advising sessions and offer personalized guidance to your clients. Explore the options below to get started.
      </Typography>
      <Divider sx={{my: 2}}/>

      <Typography variant="h2" sx={{fontSize: 18, mb: 2}}>Accounts</Typography>
     <AIToolsCredentials/>
     <Divider sx={{my: 2}}/>
     <Typography variant="h2" sx={{fontSize: 18, my: 2}}>Advisor GPT Tools</Typography>
   
     <AdvisorTools/>
    </div>
    </PageWrapper>
);