

import React from 'react';
import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography } from '@mui/material';
// import Logo from './path-to-your-logo.png'; // Please update with your actual logo path
// import HotelImage from './imag'; // Please update with your actual hotel image path


const agendaData = [
  { time: '8:00AM', event: 'Hosted Breakfast' },
  { time: '8:30AM', event: 'Mike Finnerty – Welcome and Introductions' },
  { time: '8:45AM', event: 'Mike Finnerty – Preparations for State Senators Vickers and Ipson' },
  { time: '9:15AM', event: 'Mike Finnerty – SWOT Analysis' },
  { time: '10:45AM', event: 'Break' },
  { time: '11:00AM', event: 'State Senator Vickers and Ipson Discussion' },
  { time: '12:00PM', event: 'Hosted Lunch' },
  { time: '1:00PM', event: 'Aaron Griffiths – SBDC Dashboard, Market Segmentation, and Entrepreneur Academy' },
  { time: '2:30PM', event: 'Mike Finnerty – SBDC Goals, BEDL Committee Strategy, Accreditation' },
  { time: '2:45PM', event: 'Break' },
  { time: '3:00PM', event: 'Mike Finnerty – SBDC Huddle, Marketing Plans, Success Stories' },
  { time: '3:30PM', event: 'Jason Yerka – Operations Manual, CenterIC Training, Sub-Awards' },
  { time: '4:30PM', event: 'Mike Finnerty - Wrap Up' },
  { time: '4:45PM', event: 'Adjourn' },
  { time: '5:30PM', event: 'Hosted Dinner' },
];

export default function HotelInformation() {
  return (
    <>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt="Hotel" src="/images/other/hotel.jpg" sx={{ mr: 2, width: 56, height: 56 }} /> {/* Adjust size as needed */}
        <Box>
        
          <Typography sx={{fontWeight: 'bold', color: '#000'}}>
          SPRINGHILL SUITES ST. GEORGE / WASHINGTON
          </Typography>
      
          <Typography>
          122 NORTH GREEN SPRING DRIVE
            <br/>WASHINGTON, UTAH, 84780
          </Typography>
          <Typography>
          +1 435-628-3900
          </Typography>
         
         
        </Box>
      </Box>

  </>
  );
}
