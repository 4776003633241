import { useState, useEffect } from 'react';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { PageWrapper } from '../pages/PageWrapper';
import PodcastPlayer from './PodcastPlayer';
import NotionPage from './NotionPage';
// const useStyles = makeStyles({
//   card: {
//     maxWidth: 345,
//   },
//   media: {
//     height: 140,
//   },
// });


const SuccessStories = () => {

    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
          const response = await fetch('/.netlify/functions/fetchSuccessCenter?databaseID=64029831-dd93-441c-9837-079c89f52b9c&center=Logan');
          const jsonResponse = await response.json();
          setData(jsonResponse.results);
          
        };

       
      
        fetchData();
      }, []);



       useEffect(() => {
     

       
      
        console.log(data, 'data')
      }, [data]);

  


      
  // const classes = useStyles();
  const logos = [
    {
      title: 'Utah SBDC Logo',
      imageUrl: '/images/branding/Utah-color-with-band-thumb.png',
      linkUrl: '/images/branding/Utah-color-with-band-thumb.png',
      fileType: 'png',
    },
    {
      title: 'Utah SBDC Logo (Vector)',
      imageUrl: '/images/branding/Utah-color-with-band-thumb.png',
      linkUrl: '/images/branding/Utah-color-with-band.eps',
      fileType: 'eps',
    }, {
        title: 'Powered By SBA Logo (JPEG)',
        imageUrl: '/images/branding/SBA-PoweredBy.jpg',
        linkUrl: '/images/branding/SBA-PoweredBy.jpg',
        fileType: 'jpg',
      },
      , {
        title: 'Powered By SBA Logo (PNG)',
        imageUrl: '/images/branding/SBA-PoweredBy.png',
        linkUrl: '/images/branding/SBA-PoweredBy.png',
        fileType: 'png',
      },

      , {
        title: 'Powered By SBA Logo (EPS - Vector)',
        imageUrl: '/images/branding/SBA-PoweredBy.jpg',
        linkUrl: '/images/branding/SBA-PoweredBy-2color.eps',
        fileType: 'eps',
      },


      {
        title: 'Go Utah Logo',
        imageUrl: '/images/branding/Go_Utah_BLACK_Standard.png',
        linkUrl: '/images/branding/Go_Utah_BLACK_Standard.png',
        fileType: 'png',
      },

      {
        title: 'Go Utah White Logo',
        imageUrl: '/images/branding/Go_Utah_white_Standard.png',
        linkUrl: '/images/branding/Go_Utah_white_Standard.png',
        fileType: 'png',
        version: 'light'
      },


     
      
      //https://utah-sbdc-website.s3.us-west-2.amazonaws.com/sba-logos.zip
    // Add more logos here
  ];
  if (!data) return <p>No data provided</p>;

  return (
    <PageWrapper title="Success Stories" secondaryTitle={""}>
    <Grid container spacing={3}>
   
   <Typography sx={{px: 4, mt: 2, fontSize: 12}}>
   For your marketing convenience, we have provided direct links to the website articles, downloadable photos, and videos for each featured success story. Additionally, an embed code is available for seamless integration into your website.   </Typography>
        <Grid item  xs={12} sm={12} md={12}>


       <NotionPage data={data} />
        </Grid>
  
    </Grid>
    </PageWrapper>
  )
} 
export default SuccessStories;
