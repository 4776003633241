const getColorByLabel = (label) => {
    switch (label) {
      case 'Agriculture, Forestry, Fishing and Hunting':
      case 'Agriculture, Forestry, Fishing & Hunting':
        return 'rgba(233, 30, 99, 0.6)';
      case 'Mining, Quarrying, and Oil and Gas Extraction':
      case 'Mining, Quarrying, Oil & Gas Extraction':
        return 'rgba(33, 150, 243, 0.6)';
      case 'Utilities':
        return 'rgba(254, 234, 167, 0.6)';
      case 'Construction':
        return 'rgba(255, 87, 34, 0.6)';
      case 'Manufacturing':
        return 'rgba(142, 36, 170, 0.6)';
      case 'Wholesale Trade':
        return 'rgba(0, 188, 212, 0.6)';
      case 'Retail Trade':
        return 'rgba(244, 67, 54, 0.6)';
      case 'Transportation and Warehousing':
      case 'Transportation & Warehousing':
      case 'Transportation':
        return 'rgba(0, 150, 136, 0.6)';
      case 'Information':
        return 'rgba(233, 196, 106, 0.6)';
      case 'Finance and Insurance':
      case 'Finance & Insurance':
        return 'rgba(103, 58, 183, 0.6)';
      case 'Real Estate and Rental and Leasing':
      case 'Real Estate, Rental & Leasing':
        return 'rgba(3, 169, 244, 0.6)';
      case 'Professional, Scientific, and Technical Services':
      case 'Professional, Scientific & Technical Services':
        return 'rgba(245, 124, 0, 0.6)';
      case 'Management of Companies and Enterprises':
      case 'Management, Companies, Enterprises':
        return 'rgba(139, 195, 74, 0.6)';
      case 'Administrative and Support and Waste Management and Remediation Services':
      case 'Administrative, Support, Waste Management & Remediation':
        return 'rgba(162, 95, 173, 0.6)';
      case 'Educational Services':
        return 'rgba(205, 220, 57, 0.6)';
      case 'Health Care and Social Assistance':
      case 'Health Care & Social Assistance':
        return 'rgb(97, 200, 142, 0.6)';
      case 'Arts, Entertainment, and Recreation':
      case 'Arts, Entertainment & Recreation':
        return 'rgba(96, 125, 139, 0.6)';
      case 'Accommodation and Food Services':
      case 'Accommodation & Food Services':
        return 'rgba(239, 83, 80, 0.6)';
      case 'Other Services (except Public Administration)':
      case 'Other Services':
        return 'rgba(255, 193, 7, 0.6)';
      case 'Warehousing':
        return 'rgba(139, 195, 74, 0.6)';
      default:
        return '#FFFFFF';
    }
  };
  
  export default getColorByLabel;
  