import React, { useState, useEffect } from 'react';
import { Button, TextField, Container, Typography, Alert } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const UserProfile = () => {
  const { user } = useAuth0();
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState({ text: '', type: '' });

  useEffect(() => {
    if (password && confirmPassword) {
      if (password === confirmPassword) {
        setMessage({ text: 'Passwords match', type: 'success' });
      } else {
        setMessage({ text: 'Passwords do not match', type: 'error' });
      }
    } else {
      setMessage({ text: '', type: '' });
    }
  }, [password, confirmPassword]);

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const response = await fetch('/.netlify/functions/updateUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.sub,
          body: { password },
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setMessage({ text: 'Password updated successfully!', type: 'success' });
        // After a brief delay, redirect the user to the external login page
        setTimeout(() => {
          window.location.href = 'https://dashboard.utahsbdc.org';
        }, 2000); // Adjust delay as needed
      } else {
        setMessage({ text: data.error, type: 'error' });
      }
    } catch (error) {
      console.error('Error updating password:', error);
      setMessage({ text: 'Failed to update password. Please try again later.', type: 'error' });
    }
  };


  return (
    <Container maxWidth="sm">
      <Typography variant="h6" gutterBottom>
        Update Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="New Password"
          type="password"
          name="password"
          value={password}
          onChange={handleChangePassword}
          margin="normal"
          error={message.type === 'error'}
        />
        <TextField
          fullWidth
          label="Confirm New Password"
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleChangeConfirmPassword}
          margin="normal"
          error={message.type === 'error'}
        />
        {message.text && (
          <Alert severity={message.type} sx={{ mt: 2 }}>
            {message.text}
          </Alert>
        )}
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Update Password
        </Button>
      </form>
    </Container>
  );
};

export default UserProfile;
