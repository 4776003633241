import axios from 'axios';
import sectorMap from '../charts/sectorMap';

const apiData = {
  query: '',
  filter: {
    connective: 'and',
    propositions: [
      {
        attribute: 'fips_code',
        relation: 'equals',
        value: '',
      },
      {
        attribute: 'primary_naics_code_id',
        relation: 'equals',
        value: '',
      },
      {
        relation: 'range',
        attribute: 'in_business_on',
        value: {
          start: '2020-01-01',
          end: '2022-12-31',
          relation: 'contains',
        },
      },
    ],
  },
  limit: 900,
  offset: 0
};

const fetchData = async (fipsCode, sectorCodes, limit = 10, offset = 0) => {
  const data = { ...apiData, limit, offset };
  data.filter.propositions[0].value = fipsCode;

  const responses = await Promise.all(
    sectorCodes.map(code => {
      data.filter.propositions[1].value = code;
      return axios.post(
        "/.netlify/functions/contactApi",
        JSON.stringify(data)
      );
    })
  );

  const formattedData = {};
  const responseData = {};

  responses.forEach((response, index) => {
    const code = sectorCodes[index];
    responseData[code] = response.data;
    const sectorNames = Object.keys(sectorMap).filter(key => sectorMap[key].includes(code));
    sectorNames.forEach(sectorName => {
      if (!formattedData[sectorName]) {
        formattedData[sectorName] = {
          count: response.data.count,
          codes: [code],
          vCodes: code,
        };
      } else {
        formattedData[sectorName].count += response.data.count;
        if (!formattedData[sectorName].codes.includes(code)) {
          formattedData[sectorName].codes.push(code);
          formattedData[sectorName].codes.sort((a, b) => a - b);
          
          let groups = [];
          let group = [formattedData[sectorName].codes[0]];
          
          for (let i = 1; i < formattedData[sectorName].codes.length; i++) {
            if (formattedData[sectorName].codes[i] - 1 === formattedData[sectorName].codes[i - 1]) {
              group.push(formattedData[sectorName].codes[i]);
            } else {
              groups.push(group);
              group = [formattedData[sectorName].codes[i]];
            }
          }
          groups.push(group);
          
          formattedData[sectorName].vCodes = groups
            .map(group => group.length > 1 ? `${group[0]}-${group[group.length - 1]}` : `${group[0]}`)
            .join(', ');
        }
      }
    });
  });

  return { responseData, formattedData };
};

export { fetchData };
