import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const columns = [
    { field: 'date', headerName: 'Date', type: 'date', },
    { field: 'scorm', headerName: 'Course', width: 150 },
    { field: 'user', headerName: 'User', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    // {
    //     field: 'sessionLength',
    //     headerName: 'Session Length',
    //     type: 'number',
    //     valueFormatter: ({ value }) => {
    //         let num = parseFloat(value);
    //         return typeof num === 'number' ? num.toFixed(2) : '';
    //     }

    // },
    {
        field: 'loggableHours',
        headerName: 'Loggable Hours',
        type: 'number',
        valueFormatter: ({ value }) => {
            let num = parseFloat(value);
            return typeof num === 'number' ? num.toFixed(2) : '';
        }
    }
];



export default function EAReports({ data }) {

    const getRowId = (row) => row._id;

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                checkboxSelection
                getRowId={getRowId}
            />
        </div>
    );
}