import React from "react";
import { PageLayout } from "../components/page-layout";
import StrategicTeamsOverview from "../components/StrategicTeamsOverview";
import { Divider, Typography } from "@mui/material";
import { PageWrapper } from "./PageWrapper";
import StrategicPlan from "../components/StrategicPlan";

export const StrategicTeamsPage = () => (
  <PageWrapper title={"Strategic Plan"} showSecondaryTitle={false} >
    <div className="content-layout">
      <Typography variant="body1" sx={{ mb: 2,  mt: 2}}>
      During the 2024 Utah SBDC October Director's meeting, the team participated in a strategic planning session. This included the State Director, Associate State Director, Director of Innovation and Technology, as well as the Regional Directors and Associate Directors. Together, they collaborated to create a refined strategic direction that encapsulates the most crucial strategic elements. Open dialogues were encouraged, providing everyone with the chance to contribute. A consensus was reached as the team developed the following strategic plan.
      </Typography>
      <Divider/>
      <StrategicPlan/>
      {/* <StrategicTeamsOverview /> */}
    </div>
    </PageWrapper>
);