import React, { useState } from 'react';
import { Box, Button, Typography, CircularProgress, Alert } from '@mui/material';

const UploadForm = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setMessage('Please select a file.');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('/.netlify/functions/uploadExcel', {
        method: 'POST',
        body: formData,
      });

      const result = await response.text();
      setMessage(result);
    } catch (error) {
      setMessage('Error uploading file. Please try again.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleSubmit} 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        gap: 2, 
        mt: 4 
      }}
    >
      <Typography variant="h5" gutterBottom>
        Upload Excel File
      </Typography>
      <Button
        variant="outlined"
        component="label"
        color="primary"
      >
        Select File
        <input
          type="file"
          name="file"
          onChange={handleFileChange}
          accept=".xlsx, .xls"
          hidden
        />
      </Button>

      {file && (
        <Typography variant="body1">
          Selected File: {file.name}
        </Typography>
      )}

      <Button 
        type="submit" 
        variant="contained" 
        color="primary" 
        disabled={isLoading} 
        sx={{ width: '150px' }}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Upload'}
      </Button>

      {message && (
        <Alert severity={message.includes('Error') ? 'error' : 'success'}>
          {message}
        </Alert>
      )}
    </Box>
  );
};

export default UploadForm;
