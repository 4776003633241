import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Margin } from '@mui/icons-material';

const StyledCard = styled(Card)({
    width: "100%",
    margin: "5px 0px",
    minHeight: 275
});

const SimpleCard = ({ image, title, status, desc }) => {
    return (
        <StyledCard>
            <CardMedia
                component="img"
                height="auto"
                image={image}
                alt={title}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                {status ? <Typography variant="body2" color="text.secondary">
                    
                    Status: {status}
                </Typography>
                : null
            }
             {desc ? <Typography variant="body2" color="text.secondary">
                    
                    {desc}
                </Typography>
                : null
            }
               

            
            </CardContent>
        </StyledCard>
    );
}

export default SimpleCard;
