import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, Divider } from '@mui/material';
import PartnerData from './PartnerData';
import EntrepreneurAcademy from './EntrepreneurAcademy';

const ReportsTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Tabs value={value} onChange={handleChange} aria-label="reports tabs">
        <Tab label="SBDC Network Data" />
        <Tab label="Entrepreneur Academy" />
      </Tabs>
      <Divider sx={{ my: 2 }} />
      {value === 0 && (
        <Box>
          <PartnerData />
        </Box>
      )}
      {value === 1 && (
        <Box>
         
          <EntrepreneurAcademy />
        </Box>
      )}
    </Box>
  );
};

export default ReportsTabs;