import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, MenuItem, Select, Typography, FormControl, InputLabel, Paper, Grid, Divider } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import PartnerImpact from './PartnerImpact';
import TrainingsEventsTable from './TrainingsEventsTable';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const data = [
  { source: 'Word of Mouth', percentage: '46%' },
  { source: 'Government Programs', percentage: '13%' },
  { source: 'Online', percentage: '12%' },
  { source: 'SBA', percentage: '10%' },
  { source: 'Other', percentage: '8%' },
  { source: 'Chamber of Commerce', percentage: '3%' },
  { source: 'Lenders', percentage: '3%' },
  { source: 'Higher Ed', percentage: '3%' },
  { source: 'Goldman Sachs', percentage: '2%' },
];

const ReferralSourceTable = () => {
  return (
    <Box sx={{ width: '100%', padding: 0, mt: 2 }}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ backgroundColor: '#002d62', color: '#fff', padding: 3, borderRadius: '4px 4px 0 0' }}
      >
        Referral Source
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Referral Source</strong></TableCell>
              <TableCell align="right"><strong>%</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.source}</TableCell>
                <TableCell align="right">{row.percentage}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const PartnerData = () => {
  const [data, setData] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState('All Centers');
  const [totals, setTotals] = useState({});
  const [chartData, setChartData] = useState({ womenMinorityRural: {}, sectors: {} });
  const [trainings, setTrainings] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/.netlify/functions/getData', {
          params: {
            database: 'partnerReports',
            collection: 'sbdcData'
          }
        });
        setData(response.data);
        calculateTotals(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const calculateTotals = (data) => {
    const totals = {
      Clients: 0,
      Hours: 0,
      BusinessStarts: 0,
      Jobs: 0,
      Capital: 0,
      Sales: 0,
    };

    const womenMinorityRural = {
      Women: 0,
      Minority: 0,
      Rural: 0,
    };

    const sectors = {
      Agriculture: 0,
      Mining: 0,
      Utilities: 0,
      Construction: 0,
      Manufacturing: 0,
      Wholesale: 0,
      Retail: 0,
      Transportation: 0,
      Information: 0,
      'Finance & Insurance': 0,
      'Real Estate & Renting': 0,
      'Professional, Scientific & Tech Services': 0,
      'Management Of Companies & Enterprises': 0,
      'Administrative Support': 0,
      'Educational Services': 0,
      'Health Care': 0,
      'Arts & Recreation': 0,
      'Accommodation & Food Services': 0,
      'Other Services': 0,
      'Public Administration': 0,
    };

    const trainings = {
      'Event Count': 0,
      'Distinct Customers': 0,
    };

    data.forEach((center) => {
      totals.Clients += center.Clients || 0;
      totals.Hours += center.Hours || 0;
      totals.BusinessStarts += center['Business Starts'] || 0;
      totals.Jobs += center.Jobs || 0;
      totals.Capital += center.Capital || 0;
      totals.Sales += center.Sales || 0;

      womenMinorityRural.Women += center.SEDI.Women || 0;
      womenMinorityRural.Minority += center.SEDI.Minority || 0;
      womenMinorityRural.Rural += center.SEDI.Rural || 0;

      Object.keys(sectors).forEach((key) => {
        sectors[key] += center.NAICS[key] || 0;
      });

      trainings['Event Count'] += center['Trainings & Events']['Event Count'] || 0;
      trainings['Distinct Customers'] += center['Trainings & Events']['Distinct Customers'] || 0;
    });

    setTotals(totals);
    setChartData({ womenMinorityRural, sectors });
    setTrainings(trainings);
  };

  const handleCenterChange = (event) => {
    const center = event.target.value;
    setSelectedCenter(center);

    if (center === 'All Centers') {
      calculateTotals(data);
    } else {
      const centerData = data.find((c) => c.Center === center);
      setTotals({
        Clients: centerData.Clients || 0,
        Hours: centerData.Hours || 0,
        BusinessStarts: centerData['Business Starts'] || 0,
        Jobs: centerData.Jobs || 0,
        Capital: centerData.Capital || 0,
        Sales: centerData.Sales || 0,
      });
      setChartData({
        womenMinorityRural: centerData.SEDI,
        sectors: centerData.NAICS,
      });
      setTrainings(centerData['Trainings & Events']);
    }
  };

  if (!data) {
    return <p>Loading data...</p>;
  }

  const pieOptions = {
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const naicsPieOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <Box sx={{ padding: 2 }}>
    
      <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
        <InputLabel>Center</InputLabel>
        <Select value={selectedCenter} onChange={handleCenterChange} label="Center" fullWidth>
          <MenuItem value="All Centers">All Centers</MenuItem>
          {data.map((center) => (
            <MenuItem key={center._id} value={center.Center}>
              {center.Center}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <PartnerImpact totals={totals} />
      <Divider sx={{my: 2}} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6">SEDI Data</Typography>
            <Pie
              data={{
                labels: ['Women', 'Minority', 'Rural'],
                datasets: [
                  {
                    data: [chartData.womenMinorityRural.Women, chartData.womenMinorityRural.Minority, chartData.womenMinorityRural.Rural],
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                  },
                ],
              }}
              options={pieOptions}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography variant="h6">NAICS Sectors</Typography>
            <Pie
              data={{
                labels: Object.keys(chartData.sectors),
                datasets: [
                  {
                    data: Object.values(chartData.sectors),
                    backgroundColor: [
                      '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FF6384', '#36A2EB',
                      '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
                      '#9966FF', '#FF9F40', '#FF6384', '#36A2EB', '#FFCE56',
                    ],
                  },
                ],
              }}
              options={naicsPieOptions}
            />
          </Paper>
        </Grid>
      </Grid>
      <Divider sx={{my: 2}} />
      <TrainingsEventsTable trainings={trainings} />
      <ReferralSourceTable/>
    </Box>

    
  );
};

export default PartnerData;