import React from 'react';
import { Grid, Box } from '@mui/material';
import Course from './Course'; // Importing the Course component

const CoursesList = ({ courses, userCertifications }) => {
    console.log(courses);
    return (
      <Box sx={{ p: 1, m: 1 }}>
        <Grid container spacing={2} justifyContent="flex-start">
          {courses.map((course, index) => {
            const certification = userCertifications.find(cert => cert.title === course.title);
            return (
              <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
                <Course
                  title={course.title}
                  image={course.courseImage}
                  url={course.id} // Pass the url prop here
                  isCertified={!!course.certified}
                  certifiedOn={course.certifiedOn}
                  status={course.status}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };
  

export default CoursesList;
