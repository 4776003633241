import React from 'react';
import { Box, Card, CardContent, Typography, Grid, Button } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import BrushIcon from '@mui/icons-material/Brush';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import GroupIcon from '@mui/icons-material/Group';
import SpaIcon from '@mui/icons-material/Spa';
import GavelIcon from '@mui/icons-material/Gavel';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import CampaignIcon from '@mui/icons-material/Campaign';
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
import ListIcon from '@mui/icons-material/List'; // Import an icon for the Index tool


const advisorTools = [
  {
    name: "Index",
    url: "https://chatgpt.com/g/g-RF0UfkIyH-index", // You can set this to a specific URL if needed
    description: "Provides an overview and links to all available GPTs listed below.",
    icon: <ListIcon />
  },
  {
    name: "Angel Investment",
    url: "https://chatgpt.com/g/g-omTxgLEGj-angel-investment",
    description: "Provides expert guidance on angel investing, helping entrepreneurs attract investors, craft strong pitches, and understand key investment terms. Offers personalized advice for all business stages, focusing on a solid business model and value proposition.",
    icon: <AttachMoneyIcon />
  },
  {
    name: "Business License",
    url: "https://chatgpt.com/g/g-iR9xoUfZQ-business-license",
    description: "Helps users navigate the business license process by asking key questions about their business type and location. Directs them to local or county business license resources.",
    icon: <BusinessIcon />
  },
  {
    name: "Child Care & Home Care",
    url: "https://chatgpt.com/g/g-SzYf7vFzp-child-care-home-care",
    description: "Offers guidance for starting and operating child care and home care businesses in Utah, focusing on compliance with state regulations and providing legal accuracy.",
    icon: <ChildCareIcon />
  },
  {
    name: "Cosmetology Guide (Utah)",
    url: "https://chatgpt.com/g/g-RnRK9A1xn-cosmetology-guide-utah",
    description: "Covers beauty treatments and services in Utah, including hair, skin, and nail care. Explains licensing requirements for cosmetologists, including education and exams.",
    icon: <BrushIcon />
  },
  {
    name: "County Health Department",
    url: "https://chatgpt.com/g/g-IiCCi8je3-county-health-department",
    description: "Assists businesses with health department-related inquiries, focusing on food permits and safety certifications. Provides contact details for local county health departments.",
    icon: <HealthAndSafetyIcon />
  },
  {
    name: "Equity Investment",
    url: "https://chatgpt.com/g/g-WFM9sycgq-equity-investment",
    description: "Helps Utah entrepreneurs find financing options, including loans, grants, and venture capital, with tailored guidance on eligibility and application processes.",
    icon: <AttachMoneyIcon />
  },
  {
    name: "Guide to Using AI",
    url: "https://chatgpt.com/g/g-sp9g6GXQY-guide-to-using-ai",
    description: "Helps users harness AI for creative and practical tasks, offering clear guidance and troubleshooting. Simplifies AI concepts for effective integration into work or projects.",
    icon: <SmartToyIcon />
  },
  {
    name: "Hiring Guide",
    url: "https://chatgpt.com/g/g-GhQlpW7vh-hiring-guide",
    description: "Helps Utah small business owners navigate employee hiring requirements, covering state and federal regulations, taxes, and legal compliance.",
    icon: <GroupIcon />
  },
  {
    name: "Massage Therapy",
    url: "https://chatgpt.com/g/g-JHa5bn75R-massage-therapy",
    description: "Offers guidance on starting and running a massage therapy business, including licensing, marketing, and client management.",
    icon: <SpaIcon />
  },
  {
    name: "Number of Employee Laws",
    url: "https://chatgpt.com/g/g-lMWSLFdpJ-number-of-employee-laws",
    description: "Helps employers understand U.S. regulations based on company size. Highlights key laws like FMLA, ADA, and ACA.",
    icon: <GavelIcon />
  },
  {
    name: "Sales Tax",
    url: "https://chatgpt.com/g/g-j9bFb4ulp-sales-tax",
    description: "Provides expert guidance on Utah state sales and use tax, offering explanations on tax rates, exemptions, and filing requirements.",
    icon: <ReceiptIcon />
  },
  {
    name: "Selling Alcohol in Utah",
    url: "https://chatgpt.com/g/g-BUn9DBvXj-selling-alcohol-in-utah",
    description: "Step-by-step guidance for selling alcohol in Utah, helping users navigate state and city licensing requirements with personalized advice.",
    icon: <LocalBarIcon />
  },
  {
    name: "Selling Food",
    url: "https://chatgpt.com/g/g-5OKZ2cBS9-selling-food",
    description: "Step-by-step guidance for starting a food business in Utah, navigating regulations, permits, and certifications at various government levels.",
    icon: <FastfoodIcon />
  },
  {
    name: "Social Media Marketing",
    url: "https://chatgpt.com/g/g-jCmMj51CR-social-media-marketing",
    description: "Interactive social media marketing assistant for small businesses. Provides guidance on creating campaigns, optimizing content, and boosting online presence.",
    icon: <CampaignIcon />
  },
  {
    name: "Utah Tobacco Laws",
    url: "https://chatgpt.com/g/g-UUzZn0TDk-utah-tobacco-laws",
    description: "Information about Utah’s tobacco laws, covering sales restrictions, penalties, and compliance requirements for tobacco, nicotine, and e-cigarette products.",
    icon: <SmokingRoomsIcon />
  }
];

const AdvisorTools = () => {
  return (
    <Box sx={{ flexGrow: 1, p: 0 }}>
      <Grid container spacing={3}>
        {advisorTools.map((tool, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center">
                  {tool.icon}
                  <Typography variant="h6" gutterBottom sx={{ ml: 1 }}>
                    {tool.name}
                  </Typography>
                </Box>
                <Typography variant="body1" color="textSecondary" paragraph>
                  {tool.description}
                </Typography>
                <Button variant="contained" color="primary" href={tool.url} target="_blank" rel="noopener">
                 Use This GPT
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AdvisorTools;
