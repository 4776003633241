import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { PageWrapper } from '../../pages/PageWrapper';
import { useAuth0 } from "@auth0/auth0-react";
import { Select, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DateDisplay from '../DateDisplay';
import ImpactList from '../charts/ImpactList';

import { useNotionData } from '../utils/useNotionData'; // Make sure the path is correct
import { PageLoader } from '../page-loader';


const ImpactOverview = () => {
  const { data, pageDetails, isLoading, error } = useNotionData();
  console.log(pageDetails, 'details');
  const impact = [
    { title: 'Businesses Started', value: "", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/businessStarts.jpg' },
    { title: 'Loans Obtained',   value: "", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/LoansObtained.jpg' },
    { title: 'Clients Served',  value: "", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/clientsServed.jpg' },
    { title: 'Clients Sales Increase', value: "", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/ClientSales.jpg' },
    { title: 'Jobs Created', value: "", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/JobsCreated.jpg' },
    { title: 'Benefit-to-Cost Ratio', value: "", image: 'https://utahsbdc.org/wp-content/uploads/2023/10/benefit_cost.jpg' },
  ];



  // Initialize details with default values
let details = {
  title: '',
  lastUpdated: ''
};


// Check if pageDetails and necessary properties exist
if (pageDetails && pageDetails.properties) {

  const title = pageDetails.properties.Title.title[0].plain_text;
  const lastUpdatedUTC = pageDetails.properties["Data Last Updated"]?.date?.start;


    // Create a date object in UTC
    const utcDate = new Date(lastUpdatedUTC + 'T00:00:00Z');

    // Adjust to local time zone for display
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
    details = {
      title: title,
      lastUpdated: localDate.toLocaleDateString()
    };

}

  

  impact.forEach((impactItem) => {
    const matchedData = data.find(dataItem => dataItem.kpi === impactItem.title);
    if (matchedData) {
      impactItem.value = matchedData.value;
    }
  });



  console.log(pageDetails, "PAge Details")
  
  

// Example data structure for user certifications
const userCertifications = [
  { title: 'Forming Your Business', certifiedDate: '2023-01-15' },
  { title: 'Marketing Your Business', certifiedDate: '2023-05-20' },
  // ... other certifications
];
  

  const {
    isAuthenticated,
    logout,
    user
  } = useAuth0();
  useEffect(() => {
    
     

  }, []);

  



  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

if (isLoading) return <PageLoader/>;
if (error) return <p>Error: {error}</p>;

  return (
<>
<PageWrapper title={details.title} secondaryTitle={" "} subtitle={'Data as of ' + details.lastUpdated}>


<ImpactList courses={impact} userCertifications={userCertifications} />


      </PageWrapper>

</>
  
  );
};

export default ImpactOverview;


