import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Import your components
import UserProfile from "../components/UserProfile";
import CourseProgress from "../components/CourseProgress";
import { UserDetails } from "./userDetails";
import { PageLayout } from "../components/page-layout";
import { Chip } from "@mui/material";

export const ReportsPageUserDetails = (props) => {
    const { userId } = useParams();
    const { user } = useAuth0();
    const [responseData, setResponseData] = useState({});

    const fetchUserData = async () => {
        // Omitted for brevity
    };

    useEffect(() => {
        fetchUserData();
    }, [userId]);

    if (!user && !responseData) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <PageLayout>
         
             <Typography sx={{ textTransform: 'capitalize', mb: 2 }}>
                <h1 id="page-title">Entrepreneur Academy</h1>
            </Typography>
            <Typography sx={{ textTransform: 'capitalize', mb: 4 }}>
                <h4 id="page-subtitle">User Details</h4>
            </Typography>
            <Divider sx={{mb: 5}} />

            <Grid container spacing={2}>
                {/* Left Column for UserProfile */}
                <Grid item xs={12} md={3}>
                    <UserProfile userId={userId}/>
                </Grid>

                {/* Right Column for Other Details */}
                <Grid item xs={12} md={9}>
                    <Box>
                        {/* <Divider sx={{ my: 2 }}>User Progress</Divider> */}
                        <CourseProgress userId={userId}/>
                        
                        {/* Add more components as needed */}
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
            <Divider sx={{ my: 5 }}><Chip label="User Activity" size="large" sx={{fontSize: 14, p: 2, borderRadius: 0, background: '#002d62', color: '#fff'}} /></Divider>
                        <UserDetails userId={userId}/>
                        </Grid>
                        </Grid>
        </PageLayout>
    );
};
