import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SimpleCard from '../components/SimpleCard';
import { Link } from '@mui/material';
import financeCover from '../images/finance.png'
import EaCourses from '../components/EaCourses';

const StyledPaper = styled(Paper)({
    padding: '1rem',
    height: '100%',
    background: '#eeeeee'
});

const courseData = [
    {
        id: '1',
        title: 'Course outline',
        status: 'Reviewing',
    },
    {
        id: '2',
        title: 'Scripting',
        status: 'Reviewing',
    },
    {
        id: '3',
        title: 'Recording',
        status: 'In Progress',
    },
    {
        id: '4',
        title: 'Editing',
        status: 'In Progress',
    },
    {
        id: '5',
        title: 'Final Review',
        status: 'In Review',
    },
    {
        id: '6',
        title: 'Publishing',
        status: 'Completed',
    },
];

export const Guide = () => {
    const [data, setData] = React.useState(courseData);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(data);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setData(items);
    };

    return (
        <Container sx={{ mt: 4 }}>
            <Typography variant="h2" align="left" sx={{ mb: 2 }}>
              How To Guide | Exporting Market Segmentation Contact Data
            </Typography>

  


            <Grid sx={{ mt: 2 }} container spacing={2}>
        
            <Grid item xs={12} md={2}>

<Box
    sx={{
        height: '100%',
    }}
>
   
      
</Box>
</Grid>
<iframe src="https://scribehow.com/embed/Exporting_Market_Segmentation_Data_as_CSV__kRvvXyWwRdqkHoIvmfFZMw?removeLogo=true" width="100%" height="640" allowfullscreen frameborder="0"></iframe>

            </Grid>
        </Container>
    )
};
