// CenterChart.js
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import getColorByLabel from './configs/colors';
import createChartOptions from './configs/chartOptions';
Chart.register(ArcElement, Tooltip);

const CountyChart = ({title, county, baseUrl, data }) => {
  const navigate = useNavigate();

  const categoriesArray = Object.values(data).map(({ sectorName, count, sectorId }) => ({
    label: sectorName,
    count,
    sectorId,
  }));

  // Sort the array by count in descending order
  categoriesArray.sort((a, b) => b.count - a.count);

  // Create an array of labels and counts from the sorted array
  const sortedLabels = categoriesArray.map(category => category.label);
  const sortedCounts = categoriesArray.map(category => category.count);
  const sectorIds = categoriesArray.map(category => category.sectorId);

  console.log(sortedLabels);
  console.log('sectorIDSCountyChart', sectorIds);
  

  const backgroundColor = sortedLabels.map(label => getColorByLabel(label));
  const hoverBackgroundColor = backgroundColor;

  const chartData = {
    labels: sortedLabels,
    datasets: [
      {
        label: null,
        data: sortedCounts,
        backgroundColor: backgroundColor,
        borderColor: backgroundColor,
        borderWidth: 1,
      },
    ],
  };

  // Update the createChartOptions function
  const chartOptions = createChartOptions(title, county, baseUrl, navigate, sectorIds);



  return <Pie data={chartData} options={chartOptions} />;
};

export default CountyChart;