// chartOptions.js
const createChartOptions = (titleText, county, basePath, navigate, sectorIds) => {
  return {
    plugins: {
      title: {
        display: true,
        text: titleText,
      },
      legend: {
        display: false
      }
    },
    onClick: (evt) => {
      const chartInstance = evt.chart;
      console.log(evt, 'evt');
      const activeElements = chartInstance.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);
      console.log(activeElements);
      console.log(sectorIds);
      if (activeElements.length) {
        const index = activeElements[0].index;
        const sectorId = sectorIds[index];
        console.log(county, 'countyCHARTOPTIONS');

        if(!county) {
          navigate(`/market-segmentation/center-detail-code/${sectorId}`);
      } else {
          navigate(`/market-segmentation/county-detail-code/${sectorId}`, { state: {county: county}});
      }
  
      

       
      }
    },
  };
};

export default createChartOptions;
