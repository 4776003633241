import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PageWrapper } from "./PageWrapper";
import AgendaTable from "../components/Agenda"; // Make sure the path is correct

export const AgendaPage = (props) => {
  const [agendaData, setAgendaData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch agenda data on component mount
  useEffect(() => {
    const fetchAgendaData = async () => {
      try {
        const response = await axios.get('/api/agenda'); // Update this endpoint as needed
        setAgendaData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching agenda data", error);
        setLoading(false);
      }
    };

    fetchAgendaData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <PageWrapper title="Directors Meeting Agenda" showSecondaryTitle={false} secondaryTitle={`2024 YTD`}>
      <AgendaTable agendaData={agendaData} />
    </PageWrapper>
  );
};
