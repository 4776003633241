import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Card, CardContent, Typography } from '@mui/material';

const PieChartFunding = () => {
  const data = {
    labels: ['Congress', 'Utah Legislature', 'Host Match'],
    datasets: [
      {
        data: [1275009, 764332, 542868],
        backgroundColor: ['#ff0000', '#16A9BC', '#00356b'],
        hoverBackgroundColor: ['#ff0000', '#16A9BC', '#00356b']
      }
    ]
  };

  const options = {
    responsive: true,
    legend: {
      position: 'right'
    },
    title: {
      display: true,
      text: `Total: $ ${1275009 + 764332 + 542868}`
    }
  };

  return (
<>
        <Pie data={data} options={options} />
        </>
 
  );
};

export default PieChartFunding;