import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LoginIcon from '@mui/icons-material/Login';
import { Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';

function UserProfile({ userId }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const url = `/.netlify/functions/fetchUser/${userId}`;
        const response = await axios.get(url);
        setUser(response.data);
        console.log(response.data, 'user'); 
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, [userId]); // Ensure useEffect re-runs if userId changes

  if (!user) {
    return <div>Loading...</div>;
  }

  // Helper function to format Unix timestamps to readable dates
  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString("en-US", {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#002d62';
  
    // for (i = 0; i < 3; i += 1) {
    //   const value = (hash >> (i * 8)) & 0xff;
    //   color += `00${value.toString(16)}`.slice(-2);
    // }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        height: 125,
        width: '100%',
        mb: 2,
        borderRadius: 0,
        border: '5px solid #f5f5f5',
        fontSize: 25
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Card variant="outlined">
        <CardContent sx={{width: '100%'}}>
        <img style={{width: '100%'}} src="https://lwfiles.mycourse.app/631e631a474491b58ad0d9c0-public/56794b587298baf4b7072eef5aba21c5.png" />
         <Divider sx={{my: 2}}/>
         <Avatar {...stringAvatar(user.fields.cf_firstname + ' ' + user.fields.cf_lastname)} />
          <Typography variant="h4" component="div" sx={{ mb: 2 }}>
          {user?.fields?.cf_firstname || 'First Name Not Available'} {user?.fields?.cf_lastname || 'Last Name Not Available'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="body2">
              <PersonIcon />  Username: {user.username || 'Username Not Available'}
              
              </Typography>
              <Typography variant="body2">
                <BusinessIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Business Name: {user?.fields?.cf_businessname || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body2">
                <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Email: {user?.email || 'Email Not Available'}
              </Typography>
              <Typography variant="body2">
                <AccessTimeIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Created: {user.created ? formatDate(user.created) : 'Creation Date Not Available'}
              </Typography>
              <Typography variant="body2">
                <LoginIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Last Login: {user.last_login ? formatDate(user.last_login) : 'Never Logged In'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Displaying Tags */}
      <Typography variant="h6">Tags</Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {user?.tags?.map((tag, index) => (
          <Chip key={index} label={tag} />
        )) || <Typography>No Tags Available</Typography>}
      </Box>
    </Box>
  );
}

export default UserProfile;
