import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PieChart from "../components/charts/CountyChart";
import { CountyData } from "../components/CountyData";

import { Link, useLocation } from 'react-router-dom'
import { CenterData } from "../components/CenterData";
import AxleData from "../components/AxleData";




export const MarketSegmentationPage = () => {
    const { user } = useAuth0();
    console.log(user.counties, 'counties')
    const [data, setData] = useState(null);
    const countyObject = {
        "county": "San Juan",
        "fips": "49037"
    }






    let gridNum;
    if (user.counties.length >= 3) {
        gridNum = 4;
    } else {
        gridNum = 4;
    }



    if (!user) {
        return null;
    }


    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));


    return (
        <PageLayout>

            <div className="content-layout">

                <Typography sx={{ textTransform: 'capitalize' }}>
                    <h1 id="page-title" >
                    2022-2023 Market Segmentation
                    </h1>
                </Typography>
                <Typography sx={{ textTransform: 'capitalize' }}>
                    <h4 id="page-subtitle" >{user.center} Center</h4>
                </Typography>


                <div className="content__body">



                    <Grid container spacing={2}>
                        <Grid lg={gridNum}>
<CenterData/>







                        </Grid>


                        {user['counties'].map((county, index) => (
                          
                                <Grid lg={gridNum}>
                          

                                    <CountyData county={county} />
                            

                            </Grid>
                        ))}


                    </Grid>

                    <div>
                    {/* <AxleData  /> */}
             
                    

                    </div>

                </div>
           
            </div>


        </PageLayout>
    );
};
