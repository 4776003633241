import axios from 'axios';

async function callOpenAI(prompt, retries = 3, delay = 1000) {
  try {
    const API_KEY = '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';
    const API_URL = 'https://api.openai.com/v1/chat/completions';
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${API_KEY}`,
    };
    const data = {
      model: "gpt-4",
      messages: [
        { role: "system", content: "You are a helpful assistant." },
        { role: "user", content: prompt }
      ],
      max_tokens: 8000,
      temperature: 0.5,
    };

    const response = await axios.post(API_URL, data, { headers: headers });
    console.log(response);
    if (response.status === 200) {
      return response.data.choices[0].message.content;
    } else {
      throw new Error('Error calling OpenAI API');
    }
  } catch (error) {
    if (error.response && error.response.status === 429 && retries > 0) {
      console.warn(`Retrying after ${delay}ms...`);
      await new Promise(resolve => setTimeout(resolve, delay));
      return callOpenAI(prompt, retries - 1, delay * 2);
    } else {
      console.error(error);
      return null;
    }
  }
}

export default callOpenAI;