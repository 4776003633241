import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { PageWrapper } from '../../pages/PageWrapper';
import { useAuth0 } from "@auth0/auth0-react";
import { Select, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DateDisplay from '../DateDisplay';


const EditableDocument = () => {
  const {
    isAuthenticated,
    logout,
    user
  } = useAuth0();

  
  const [content, setContent] = useState('');
  const [data, setData] = useState('');
  const [selectedCenter, setSelectedCenter] = useState(user.center);


  const centers = [
    "lead",
    "blanding",
    "cedar city",
    "ephraim",
    "logan",
    "ogden",
    "kaysville",
    "sandy",
    "tooele",
    "orem",
    "vernal",
    "price",
    "st george"
  ];

  const handleEditorInit = (event, editor) => {
   editor.mode.set("readonly")
  };

  useEffect(() => {
    const fetchDocument = async () => {
      const response = await axios.get(`/.netlify/functions/document/:${selectedCenter}`);
      console.log(response.data, 'reponse');
      if (response.data.content) {
        setContent(response.data.content);
        setData(response.data);
      }
    };
    fetchDocument();
  }, [selectedCenter]);

  const handleChange = async (newContent) => {
    if(user.center == selectedCenter ) {
      setContent(newContent);
      await axios.put(`/.netlify/functions/document/:${selectedCenter}`, { content: newContent });
    }
   
  };

  const handleCenterChange = (event) => {
    setSelectedCenter(event.target.value);
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

  return (
<>
<PageWrapper title="Marketing Plan">
{user.center === 'lead' ? (

<Box sx={{width: '100%'}}>
  <Toolbar>
   
  <FormControl 
    fullWidth 
   

  >
    <InputLabel>Select a Center</InputLabel>
    <Select
      value={selectedCenter}
      onChange={handleCenterChange}
      sx={{flexGrow: 1}}
      renderValue={(selectedCenter) => capitalize(selectedCenter)}
    >
      {centers.map(center => (
        <MenuItem key={center} value={center}   sx={{ textTransform: 'capitalize' }}>
          {center}
        </MenuItem>
      ))}
    </Select>
    </FormControl>
   
  </Toolbar>
</Box>
       
        ) : null}



<Box sx={{display: 'block', width: '100%', mt: 1, mb: 1}}>
  <Typography>
    Last Updated: <DateDisplay date={data.updatedAt} />
  </Typography>
</Box>


      <Editor
        apiKey="blmtzks4duasyyh64ep4vmkrhhkxu5ohpbywb3jl19da7d5s"
        value={content}
        init={{
          height: 500,
          width: '100%',
          menubar: false,
          selector: 'textarea',
          plugins: "advlist anchor autolink autoresize autosave charmap code codesample directionality emoticons fullscreen help image importcss  insertdatetime link lists media  nonbreaking pagebreak  preview quickbars save searchreplace table  template tinydrive  visualblocks visualchars wordcount",
          toolbar: "aligncenter alignjustify alignleft alignnone alignright| anchor | blockquote blocks | backcolor | bold | copy | cut | fontfamily fontsize forecolor h1 h2 h3 h4 h5 h6 hr indent | italic | language | lineheight | newdocument | outdent | paste pastetext | print | redo | remove removeformat | selectall | strikethrough | styles | subscript superscript underline | undo | visualaid | a11ycheck advtablerownumbering typopgraphy anchor restoredraft casechange charmap checklist code codesample addcomment showcomments ltr rtl editimage fliph flipv imageoptions rotateleft rotateright emoticons export footnotes footnotesupdate formatpainter fullscreen help image insertdatetime link openlink unlink bullist numlist media mergetags mergetags_list nonbreaking pagebreak pageembed permanentpen preview quickimage quicklink quicktable cancel save searchreplace spellcheckdialog spellchecker | table tablecellprops tablecopyrow tablecutrow tabledelete tabledeletecol tabledeleterow tableinsertdialog tableinsertcolafter tableinsertcolbefore tableinsertrowafter tableinsertrowbefore tablemergecells tablepasterowafter tablepasterowbefore tableprops tablerowprops tablesplitcells tableclass tablecellclass tablecellvalign tablecellborderwidth tablecellborderstyle tablecaption tablecellbackgroundcolor tablecellbordercolor tablerowheader tablecolheader | tableofcontents tableofcontentsupdate | template typography | insertfile | visualblocks visualchars | wordcount",
          
           }}
       
          
        onEditorChange={handleChange}
      />
      </PageWrapper>

</>
  
  );
};

export default EditableDocument;


