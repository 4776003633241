import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PieChartIcon from '@mui/icons-material/PieChart';
import PaidIcon from '@mui/icons-material/Paid';
import PsychologyIcon from '@mui/icons-material/Psychology';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import { Link } from "react-router-dom";
import { Divider, ListSubheader } from '@mui/material';
import PollIcon from '@mui/icons-material/Poll';
import SchoolIcon from '@mui/icons-material/School';

export const leadCenterLinks = (
    <React.Fragment>

{/* <Link to={'course-review'}>
            <ListItemButton>
                <ListItemIcon>
                    <PsychologyIcon />
                </ListItemIcon>
                <ListItemText sx={{ color: '#000' }} primary="Course Review" />
            </ListItemButton>
        </Link> */}



        {/* <Link to={'receipt-submission'}>
            <ListItemButton>
                <ListItemIcon>
                    <PaidIcon />
                </ListItemIcon>
                <ListItemText sx={{ color: '#000' }} primary="Receipt Submission" />
            </ListItemButton>
        </Link> */}



{/* <ListSubheader component="div" inset>
      Content Development
    </ListSubheader> */}


        {/* <Link to={'ai'}>
            <ListItemButton>
                <ListItemIcon>
                    <PsychologyIcon />
                </ListItemIcon>
                <ListItemText sx={{ color: '#000' }} primary="AI" />
            </ListItemButton>
        </Link> */}

        {/* <Link to={'generate-video'}>
            <ListItemButton>
                <ListItemIcon>
                    <MovieCreationIcon />
                </ListItemIcon>
                <ListItemText sx={{ color: '#000' }} primary="Generate Video" />
            </ListItemButton>
        </Link> */}


<Link to={'/ea-report'}>
         <ListItemButton>
         <ListItemIcon>
         <SchoolIcon />
         </ListItemIcon>
         <ListItemText sx={{ color: '#000' }} primary="Entrepreneur Academy" />

       </ListItemButton>
       </Link>







    </React.Fragment>
);


