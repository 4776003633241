import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { PageWrapper } from './PageWrapper';
import UserProfile from './UserProfile';
import { Box } from '@mui/material';

export const UpdateUser = ({ userId }) => {
    const { user } = useAuth0();

 

    return (
      
        <PageWrapper title="Edit User Details"  showSecondaryTitle={false}> 
        <Box sx={{mt: 2}}>
            <UserProfile />
            </Box>
        </PageWrapper>
    );
};
