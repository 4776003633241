import axios from 'axios';

const API_URL = 'https://holy-renewal-e45d5ab32b.strapiapp.com/api'; // Replace with your Strapi API URL
// Fetch staff members with bearer token authentication
export const fetchStaffMembers = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/staff-members?populate=*`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching staff members:', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};

// https://holy-renewal-e45d5ab32b.strapiapp.com/api/accounts?populate=*

export const fetchAccounts = async (centerName) => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/accounts`, {
            params: {
                'populate[UserAccount][populate][0]': 'center'
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        // Filter the accounts based on the center name
        const filteredAccounts = response.data.data.filter(account => {
            const accountCenterName = account.attributes.UserAccount.center.data.attributes.Name;
            return accountCenterName.toLowerCase() === centerName.toLowerCase();
        });

        return filteredAccounts;
    } catch (error) {
        console.error('Error fetching Accounts', error);
        throw error;
    }
};

export const fetchSuccessStories = async (centerName) => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
      const params = {
        'populate': '*'
      };

      // Treat 'videographer' as 'lead'
      const effectiveCenter = centerName.toLowerCase() === 'videographer' ? 'lead' : centerName.toLowerCase();

      if (effectiveCenter !== 'lead') {
        params['filters[center][Name][$eqi]'] = effectiveCenter;
      }

      const response = await axios.get(`${API_URL}/success-stories`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return response.data.data;
    } catch (error) {
      console.error('Error fetching success stories:', error);
      throw error;
    }
};


export const fetchBranding = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/brandings?populate=*`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching staff members:', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};

export const fetchHeadlines = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/headlines?populate=*`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching Headlines', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};


export const fetchImpact = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/impact`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data.data);
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching impact', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};

export const toggleStoryField = async (storyId, field) => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
      const response = await axios.get(`${API_URL}/success-stories/${storyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const currentValue = response.data.data.attributes[field];
      const updatedResponse = await axios.put(
        `${API_URL}/success-stories/${storyId}`,
        {
          data: {
            [field]: !currentValue,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Return the entire updated story object
      return updatedResponse.data.data;
    } catch (error) {
      console.error('Error toggling story field:', error);
      throw error;
    }
};


  
  export const updateStory = async (storyId, updatedAttributes) => {
    console.log(storyId, updatedAttributes, 'story');
    const token = process.env.REACT_APP_API_TOKEN;
    try {
      const response = await axios.put(
        `${API_URL}/success-stories/${storyId}`,
        {
          data: updatedAttributes,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log('response', response)
  
      return response.data.data;
    } catch (error) {
      console.error('Error updating story:', error);
      throw error;
    }
  };
  export const fetchCommentsForStory = async (storyId) => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/comments`, {
            params: {
                'filters[success_story][id][$eq]': storyId, // Filter comments by storyId
                'populate': '*' // Populate any related fields if necessary
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.data; // Assuming comments are returned under `data.data`
    } catch (error) {
        console.error('Error fetching comments:', error);
        throw error;
    }
};


export const fetchAllComments = async (page = 1, limit = 5) => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
      const response = await axios.get(`${API_URL}/comments`, {
        params: {
          'populate': '*',
        //   'pagination[page]': page,
        //   'pagination[pageSize]': limit
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Assuming comments are returned under `data.data`
    } catch (error) {
      console.error('Error fetching all comments:', error);
      throw error;
    }
  };

export const submitComment = async (storyId, commentText, staffId) => {
    const token = process.env.REACT_APP_API_TOKEN;
    const requestData = {
      data: {
        Text: commentText,
        user_id: staffId,
        success_story: storyId
      }
    };
  
    console.log('Submitting comment with data:', requestData);
  
    try {
      const response = await axios.post(`${API_URL}/comments`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data;
    } catch (error) {
      console.error('Error submitting comment:', error);
      throw error;
    }
  };

export const fetchAgenda = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/agendas/1?populate[agenda_days][populate][agenda_events][populate]=presenter`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching agenda', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};      

