import React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';


  export default function ApiResponseTable({apiResponse}) {


  
  // Flatten the data and create a combined array of documents
  const documents = Object.values(apiResponse).flatMap(sector => sector.documents);
  
  // Assign unique IDs to each document
  documents.forEach((doc, index) => (doc.id = index + 1));
  
  // Define the columns for the DataGrid
  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'street', headerName: 'Street', width: 250},
    { field: 'city', headerName: 'City' },
    { field: 'state', headerName: 'State' },
    { field: 'postal_code', headerName: 'Postal Code' },
    { field: 'country_code', headerName: 'Country Code' },
    { field: 'phone', headerName: 'Phone' },
    { field: 'website', headerName: 'Website' },
    // { field: 'in_business', headerName: 'In Business' },
    { field: 'verified_on', headerName: 'Verified On' },
    // { field: 'created_at', headerName: 'Created At' },
    // { field: 'updated_at', headerName: 'Updated At' },
    { field: 'in_business_on', headerName: 'In Business On' },
    { field: 'in_business_determined_at', headerName: 'In Business Determined At' },
    { field: 'opened_for_business_on', headerName: 'Opened for Business On' },
    { field: 'estimated_opened_for_business', headerName: 'Estimated Opened for Business On' },
    // { field: 'in_business_research', headerName: 'In Business Research' },
    // { field: 'territory', headerName: 'Territory' },
    // { field: 'latitude', headerName: 'Latitude' },
    // { field: 'longitude', headerName: 'Longitude' },
    // { field: 'geo_match_level', headerName: 'Geo Match Level' },
    // { field: 'primary_sic_code_id', headerName: 'Primary SIC Code ID' },
    // { field: 'sic_code_ids', headerName: 'SIC Code IDs' },
    // { field: 'sic_code_ids_count', headerName: 'SIC Code IDs Count' },
    // { field: 'primary_naics_code_id', headerName: 'Primary NAICS Code ID' },
    // { field: 'naics_code_ids', headerName: 'NAICS Code IDs' },
    // { field: 'naics_code_ids_count', headerName: 'NAICS Code IDs Count' },
    // { 
    //   field: 'primary_contact.id', 
    //   headerName: 'Primary Contact ID', 
    //   valueGetter: (params) => {
    //     if (params.row.primary_contact && params.row.primary_contact.id) {
    //       return params.row.primary_contact.id;
    //     } else {
    //       return '';
    //     }
    //   }
    // },
    { 
      field: 'primary_contact.first_name', 
      headerName: 'Primary Contact First Name', 
      valueGetter: (params) => {
        if (params.row.primary_contact && params.row.primary_contact.first_name) {
          return params.row.primary_contact.first_name;
        } else {
          return '';
        }
      }
    },
    { 
      field: 'primary_contact.last_name', 
      headerName: 'Primary Contact Last Name', 
      valueGetter: (params) => {
        if (params.row.primary_contact && params.row.primary_contact.last_name) {
          return params.row.primary_contact.last_name;
        } else {
          return '';
        }
      }
    },
    { 
      field: 'primary_contact.job_titles', 
      headerName: 'Primary Contact Job Titles', 
      valueGetter: (params) => {
        if (params.row.primary_contact && params.row.primary_contact.job_titles) {
          return params.row.primary_contact.job_titles.join(', ');
        } else {
          return '';
        }
      }
    },
    { 
      field: 'primary_contact.job_titles_count', 
      headerName: 'Primary Contact Job Titles Count', 
      valueGetter: (params) => {
      if (params.row.primary_contact && params.row.primary_contact.job_titles_count) {
        return params.row.primary_contact.job_titles_count;
      } else {
        return '';
      }
    }
  },
  // { 
  //   field: 'primary_contact.management_level', 
  //   headerName: 'Primary Contact Management Level', 
  //   valueGetter: (params) => {
  //     if (params.row.primary_contact && params.row.primary_contact.management_level) {
  //       return params.row.primary_contact.management_level;
  //     } else {
  //       return '';
  //     }
  //   }
  // },
  // { 
  //   field: 'primary_contact.mapped_contact_id', 
  //   headerName: 'Primary Contact Mapped Contact ID', 
  //   valueGetter: (params) => {
  //     if (params.row.primary_contact && params.row.primary_contact.mapped_contact_id) {
  //       return params.row.primary_contact.mapped_contact_id;
  //     } else {
  //       return '';
  //     }
  //   }
  // },
  // { 
  //   field: 'primary_contact.primary', 
  //   headerName: 'Primary Contact Primary', 
  //   valueGetter: (params) => {
  //     if (params.row.primary_contact && params.row.primary_contact.primary) {
  //       return params.row.primary_contact.primary;
  //     } else {
  //       return '';
  //     }
  //   }
  // },
  { 
    field: 'location_employee_count', 
    headerName: 'Location Employee Count', 
    valueGetter: (params) => {
      if (params.row.location_employee_count !== undefined) {
        return params.row.location_employee_count;
      } else {
        return '';
      }
    }
  },
  { 
    field: 'estimated_location_employee_count', 
    headerName: 'Estimated Location Employee Count', 
    valueGetter: (params) => {
      if (params.row.estimated_location_employee_count !== undefined) {
        return params.row.estimated_location_employee_count;
      } else {
        return '';
      }
    }
  },
];
  

  


    return (
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid rows={documents} columns={columns} components={{ Toolbar: GridToolbar }} pageSize={10} />
      </div>
    );
  }  