import React from 'react';
import { PageWrapper } from '../../pages/PageWrapper';
import { useAuth0 } from "@auth0/auth0-react";
import CoursesList from '../charts/CoursesList';

const Onboarding = () => {
  const courses = [
    {
      title: 'Onboarding',
      id: 'https://online.utahsbdc.org/path-player?courseid=onboarding&unit=663afaed450fcb5dd90a6aecUnit',
      courseImage: 'https://lwfiles.mycourse.app/631e631a474491b58ad0d9c0-public/4549325dd36dc9301f04245dbb09769f.png',
      certified: false,
      certifiedOn: null,
      status: 'not started',
    },
  ];

  console.log(courses);

  // Example data structure for user certifications
  const userCertifications = [
    { title: 'Forming Your Business', certifiedDate: '2023-10-01' },
    { title: 'Marketing Your Business', certifiedDate: '2023-10-03' },
    // ... other certifications
  ];

  const { isAuthenticated, logout, user } = useAuth0();

  return (
    <>
      <PageWrapper title="Counselor Certification" secondaryTitle={" "}>
        <CoursesList courses={courses} userCertifications={userCertifications} />
      </PageWrapper>
    </>
  );
};

export default Onboarding;