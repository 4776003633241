import axios from 'axios';
import sectorMap from '../charts/sectorMap';

const apiData = {
  query: '',
  filter: {
    connective: 'and',
    propositions: [
      {
        attribute: 'fips_code',
        relation: 'equals',
        value: '',
      },
      {
        attribute: 'primary_naics_code_id',
        relation: 'equals',
        value: '',
      },
      {
        relation: 'range',
        attribute: 'in_business_on',
        value: {
          start: '2020-01-01',
          end: '2022-12-31',
          relation: 'contains',
        },
      },
    ],
  },
  limit: 0,
};

const naicsCodes = [
  '11', '21', '22', '23', '31', '32', '33', '42', '44', '45', '48', '49', '51',
  '52', '53', '54', '55', '56', '61', '62', '71', '72', '81'
];

const fetchCountyData = async (fipsCode) => {
  const data = { ...apiData };
  data.filter.propositions[0].value = fipsCode;

  const responses = await Promise.all(
    naicsCodes.map(code => {
      data.filter.propositions[1].value = code;
      return axios.post(
        "/.netlify/functions/api",
        JSON.stringify(data)
      );
    })
  );

  const formattedData = {};
  const responseData = {};

  responses.forEach((response, index) => {
    const code = naicsCodes[index];
    responseData[code] = response.data;
    const sectorNames = Object.keys(sectorMap).filter(key => sectorMap[key].includes(code));
    sectorNames.forEach(sectorName => {
      if (!formattedData[sectorName]) {
        formattedData[sectorName] = {
          count: response.data.count,
          codes: [code],
          vCodes: code,
        };
      } else {
        formattedData[sectorName].count += response.data.count;
        if (!formattedData[sectorName].codes.includes(code)) {
          formattedData[sectorName].codes.push(code);
          if (formattedData[sectorName].codes.length === 1) {
            formattedData[sectorName].vCodes = formattedData[sectorName].codes.join("-");
          } else {
            formattedData[sectorName].vCodes = `${formattedData[sectorName].codes[0]}-${formattedData[sectorName].codes[formattedData[sectorName].codes.length - 1]}`;
          }
        }
      }
    });
  });

  return { responseData, formattedData };
};

export { fetchCountyData };