import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CountyChart from "../components/charts/CountyChart";
import CircularProgress from '@mui/material/CircularProgress';
import { PageWrapper } from "./PageWrapper";
import HeyzineViewer from "../components/HeyZineViewer";
import PDFViewer from "../components/PDFViewer";
import ChartComponent from "../components/ChartComponent";
import BenchmarkGraphs from "../components/BenchmarkingGraphs";
import TotalsDisplay from "../components/TotalsDisplay";
import ReportsTabs from "../components/ReportsTabs";
import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';



export const PartnerReports = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/.netlify/functions/fetchPartnerData');
      const jsonResponse = await response.json();
      console.log(jsonResponse);
    };

    fetchData();
  }, []);

  return (
    <PageWrapper title="Utah SBDC Network" showSecondaryTitle={true} secondaryTitle={`Quarterly Impact Report`}>
      <Typography>Last Updated 9/18/2024</Typography>
   
      <ReportsTabs />

    </PageWrapper>
  );
};