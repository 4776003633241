import React from 'react';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { PageWrapper } from '../pages/PageWrapper';
import AgendaTable from './AgendaTable';


// const useStyles = makeStyles({
//   card: {
//     maxWidth: 345,
//   },
//   media: {
//     height: 140,
//   },
// });


const DirectorsMeeting = () => {
  // const classes = useStyles();
  const logos = [
    {
      title: 'Utah SBDC Logo',
      imageUrl: '/images/branding/Utah-color-with-band-thumb.png',
      linkUrl: '/images/branding/Utah-color-with-band-thumb.png',
      fileType: 'png',
    },
    {
      title: 'Utah SBDC Logo (Vector)',
      imageUrl: '/images/branding/Utah-color-with-band-thumb.png',
      linkUrl: '/images/branding/Utah-color-with-band.eps',
      fileType: 'eps',
    }, {
        title: 'Powered By SBA Logo (JPEG)',
        imageUrl: '/images/branding/SBA-PoweredBy.jpg',
        linkUrl: '/images/branding/SBA-PoweredBy.jpg',
        fileType: 'jpg',
      },
      , {
        title: 'Powered By SBA Logo (PNG)',
        imageUrl: '/images/branding/SBA-PoweredBy.png',
        linkUrl: '/images/branding/SBA-PoweredBy.png',
        fileType: 'png',
      },

      , {
        title: 'Powered By SBA Logo (EPS - Vector)',
        imageUrl: '/images/branding/SBA-PoweredBy.jpg',
        linkUrl: '/images/branding/SBA-PoweredBy-2color.eps',
        fileType: 'eps',
      },


      {
        title: 'Go Utah Logo',
        imageUrl: '/images/branding/Go_Utah_BLACK_Standard.png',
        linkUrl: '/images/branding/Go_Utah_BLACK_Standard.png',
        fileType: 'png',
      },

      {
        title: 'Go Utah White Logo',
        imageUrl: '/images/branding/Go_Utah_white_Standard.png',
        linkUrl: '/images/branding/Go_Utah_white_Standard.png',
        fileType: 'png',
        version: 'light'
      },


     
      
      //https://utah-sbdc-website.s3.us-west-2.amazonaws.com/sba-logos.zip
    // Add more logos here
  ];

  const handleDownload = (imageUrl, linkUrl, fileName) => {
    const link = document.createElement('a');
    link.href = linkUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <PageWrapper title="Directors Meeting" secondaryTitle=" ">
    <Grid>
     <AgendaTable/>
    </Grid>
    </PageWrapper>
  );
};

export default DirectorsMeeting;
