import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Typography } from '@mui/material';

const AxleBusinessGrid = ({ data }) => {
  const extractDocuments = (data) => {
    const { responseData } = data;
    let documents = [];
    for (const key in responseData) {
      if (responseData[key].hasOwnProperty('documents')) {
        documents = responseData[key].documents;
        break;
      }
    }
    return documents;
  };

  const documents = extractDocuments(data);
  const documentsWithId = documents.map((doc, index) => ({ id: index, ...doc }));

  const columns = [
    { field: 'name', headerName: 'Business Name', width: 200 },
    { field: 'in_business', headerName: 'In Business', width: 150 },
    { field: 'verified_on', headerName: 'Verified On', width: 150 },
    { field: 'created_at', headerName: 'Created At', width: 200 },
    { field: 'updated_at', headerName: 'Updated At', width: 200 },
    { field: 'street', headerName: 'Street', width: 200 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'state', headerName: 'State', width: 100 },
    { field: 'postal_code', headerName: 'Postal Code', width: 150 },
    { field: 'country_code', headerName: 'Country Code', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'location_employee_count', headerName: 'Employee Count', width: 150 },
    { field: 'location_sales_volume', headerName: 'Sales Volume', width: 150 },
    // Add more fields as per your requirement
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Typography variant="h5" sx={{mt: 5}} gutterBottom>
        Business Information
      </Typography>
      <Paper elevation={3} style={{ height: 450, width: '100%', padding: '16px', marginBottom: '16px' }}>
        <DataGrid
          rows={documentsWithId}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection={false}
        />
      </Paper>
    </div>
  );
};

export default AxleBusinessGrid;
