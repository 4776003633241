// src/components/AIToolsCredentials.js
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { fetchAccounts } from '../services/apiService';

const AIToolsCredentials = () => {
  const { user } = useAuth0();
  const [aiToolsData, setAiToolsData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        const accounts = await fetchAccounts(user.center);
        setAiToolsData(accounts);
      } catch (error) {
        console.error('Error fetching accounts:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    loadAccounts();
  }, [user.center]);

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error: {error.message}</Typography>;

  return (
    <Grid container spacing={2}>
      {aiToolsData.map((tool, index) => {
        const userAccount = tool.attributes.UserAccount;
        if (!userAccount) {
          return null; // Skip rendering if UserAccount is undefined
        }
        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                  {userAccount.Name}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Username: 
                  <Typography sx={{fontWeight: 'bold', mb: 2}}>{userAccount.UserName}</Typography>
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Default Password: <Typography sx={{fontWeight: 'bold'}}>{userAccount.DefaultPassword}</Typography>
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{mt: 1}}>
                  Note: If the password has been changed and you need help logging in, please contact Aaron Griffiths at: aaron.griffiths@usu.edu
                </Typography>
                <Button
                  sx={{mt: 2}}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const link = userAccount.Link;
                    if (link) {
                      window.open(link, '_blank');
                    } else {
                      console.error('Link is not available');
                    }
                  }}
                >
                  Go to Account
                </Button>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AIToolsCredentials;