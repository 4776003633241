import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SimpleCard from '../components/SimpleCard';
import { Link } from '@mui/material';
import financeCover from '../images/finance.png'
import EaCourses from '../components/EaCourses';

const StyledPaper = styled(Paper)({
    padding: '1rem',
    height: '100%',
    background: '#eeeeee'
});

const courseData = [
    {
        id: '1',
        title: 'Course outline',
        status: 'Reviewing',
    },
    {
        id: '2',
        title: 'Scripting',
        status: 'Reviewing',
    },
    {
        id: '3',
        title: 'Recording',
        status: 'In Progress',
    },
    {
        id: '4',
        title: 'Editing',
        status: 'In Progress',
    },
    {
        id: '5',
        title: 'Final Review',
        status: 'In Review',
    },
    {
        id: '6',
        title: 'Publishing',
        status: 'Completed',
    },
];

export const CourseReview = () => {
    const [data, setData] = React.useState(courseData);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(data);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setData(items);
    };

    return (
        <Container sx={{ mt: 4 }}>
            <Typography variant="h4" align="left" sx={{ mb: 2 }}>
                Entrepreneur Academy Course Progress
            </Typography>

            <Typography variant="p" align="left" sx={{ mb: 4 }}>
                We're looking for any team members who can provide feedback and review the content we've created to ensure that our course is of the highest quality and meets the needs of our clients. Your input is crucial in making this course a success and we would greatly appreciate your assistance.
            </Typography>

            <Grid sx={{ mt: 2 }} container spacing={2}>
            <Grid item xs={12} md={2}>

<Box
    sx={{
        height: '100%',
    }}
>
    <StyledPaper>
        <Typography variant="h5" sx={{ mb: 2 }}>
            Copywriting
        </Typography>

      

        <Box                              >
                                <Link href="https://utahsbdc.mylearnworlds.com/course/business-formation" target="_blank" underline="none">
                                    <SimpleCard image="https://lwfiles.mycourse.app/631e631a474491b58ad0d9c0-public/cbaec50a4abcc47fb63000a26a6167f6.png" title="Business Formation" status="Generating course assets, videos and voice overs" />

                                </Link>
                            </Box>

                       

       

    </StyledPaper>
</Box>


</Grid>


                <Grid item xs={12} md={2}>

                    <Box
                        sx={{
                            height: '100%',
                        }}
                    >
                        <StyledPaper>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                Copywriting Review
                            </Typography>

                            <Box                              >
                                <Link href="https://docs.google.com/document/d/1KgEgoD4A_HC97ymJy_JZ4b27wO6OBce-WdxLcpRxjWY/edit" target="_blank" underline="none">
                                    <SimpleCard image="https://lwfiles.mycourse.app/631e631a474491b58ad0d9c0-public/973af29e8e7f00de4f157a9a6b534dce.jpeg" title="Financing Your Business" status="Currently needing feedback on copy for the course" />

                                </Link>
                            </Box>




                        </StyledPaper>
                    </Box>


                </Grid>

                <Grid item xs={12} md={2}>

                    <Box
                        sx={{
                            height: '100%',
                        }}
                    >
                        <StyledPaper>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                Course Development
                            </Typography>

                            <Box                              >
            <Link href="https://docs.google.com/document/d/1KgEgoD4A_HC97ymJy_JZ4b27wO6OBce-WdxLcpRxjWY/edit" target="_blank" underline="none">
                <SimpleCard image="https://lwfiles.mycourse.app/631e631a474491b58ad0d9c0-public/f083a08274e9f6139040638f54c8d894.png" title="eCommerce" status="Currently being written" />

            </Link>
        </Box>

                           
                            <Box                              >
            <Link href="https://docs.google.com/document/d/1KgEgoD4A_HC97ymJy_JZ4b27wO6OBce-WdxLcpRxjWY/edit" target="_blank" underline="none">
                <SimpleCard image="https://lwfiles.mycourse.app/631e631a474491b58ad0d9c0-public/ee6bd5cbd22cd0d3ff0bcf74c7d5d9fa.png" title="Marketing" status="Currently being written" />

            </Link>
        </Box>

        <Box                              >
            <Link href="https://docs.google.com/document/d/1KgEgoD4A_HC97ymJy_JZ4b27wO6OBce-WdxLcpRxjWY/edit" target="_blank" underline="none">
                <SimpleCard image="https://utahsbdc.org/wp-content/uploads/2023/05/IMG_5281.jpg" title="Cyber Security" status="Currently being written" />

            </Link>
        </Box>

                        </StyledPaper>
                        
                    </Box>

                    


                </Grid>
                <Grid item xs={12} md={2}>

                    <Box
                        sx={{
                            height: '100%',
                        }}
                    >
                        <StyledPaper>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                Course Review
                            </Typography>

                            <Box                              >

                            </Box>


                        </StyledPaper>
                    </Box>


                </Grid>


                <Grid item xs={12} md={2}>

                    <Box
                        sx={{
                            height: '100%',
                        }}
                    >
                        <StyledPaper>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                Published
                            </Typography>

                            <Box                              >

                            </Box>


                        </StyledPaper>
                    </Box>


                </Grid>

            </Grid>

            <Grid sx={{ mt: 2 }} container spacing={2}>
                <Grid item xs={12} md={12}>

                    <Box
                        sx={{
                            height: '100%',
                        }}
                    >
                        <StyledPaper>
                            <Typography variant="h5" sx={{ mb: 2 }}>
                                Development Queue
                            </Typography>



                            <Box >

                                <EaCourses />

                            </Box>


                        </StyledPaper>
                    </Box>


                </Grid>

            </Grid>
        </Container>
    )
};
