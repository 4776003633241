import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Card, Chip, CardContent, Typography, Box, Button, Alert, CircularProgress, Breadcrumbs, Link, Divider, Avatar, CardHeader } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';

const StrategicTeamDetail = () => {
  const { id } = useParams();
  const { user } = useAuth0();
  const [team, setTeam] = useState(null);
  const [members, setMembers] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [currentTeam, setCurrentTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const [signingUp, setSigningUp] = useState(false);
  const [objectives, setObjectives] = useState([]);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get('/.netlify/functions/fetchTeams');
        const teamData = response.data.find(team => team.id === parseInt(id));
        setTeam(teamData);
        console.log(teamData);
      } catch (error) {
        console.error('Error fetching team data:', error);
      }
    };

    fetchTeamData();
    fetchTeamMembers(id);
    fetchUserCurrentTeam();
    fetchObjectives(id);
  }, [id]);

  const fetchTeamMembers = async (teamId) => {
    try {
      const response = await axios.get(`/.netlify/functions/signupTeam?id=${teamId}`);
      if (response.data) {
        setMembers(response.data);
        response.data.forEach(member => {
          if (member && member.userId) {
            fetchUserDetails(member.userId);
          }
        });
      }
    } catch (error) {
      console.error('Error fetching team members:', error);
    }
  };

  const fetchUserDetails = async (userId) => {
    try {
      const response = await axios.get(`/.netlify/functions/fetchUserDetails?userId=${userId}`);
      if (response.data) {
        setUserDetails(prevDetails => ({
          ...prevDetails,
          [userId]: response.data
        }));
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const fetchUserCurrentTeam = async () => {
    try {
      const response = await axios.get(`/.netlify/functions/getUserTeam?userId=${user.sub}`);
      if (response.data) {
        setCurrentTeam(response.data);
      }
    } catch (error) {
      console.error('Error fetching user current team:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchObjectives = async (teamId) => {
    try {
      const response = await axios.get(`/.netlify/functions/getTeamDetails?teamId=${teamId}`);
      if (response.data) {
        setObjectives(response.data.objectives);
      }
      console.log(response, 'objectives');
    } catch (error) {
      console.error('Error fetching objectives:', error);
    }
  };

  const handleSignUp = async () => {
    setSigningUp(true);
    try {
      const response = await axios.post(`/.netlify/functions/signupTeam`, {
        userId: user.sub,
        teamId: id
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        await fetchUserCurrentTeam();
        await fetchTeamMembers(id);
        if (response.data.userId) {
          await fetchUserDetails(response.data.userId);
        }
      }
    } catch (error) {
      console.error('Error signing up for the team:', error);
    } finally {
      setSigningUp(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!team) {
    return <Typography>Team not found</Typography>;
  }

  const isUserMember = members.some(member => member.userId === user.sub);

  return (
    <Box sx={{ p: 3 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link component={RouterLink} to="/strategic-plan" underline="hover" color="inherit">
          Strategic Plan
        </Link>
        <Typography color="textPrimary">{team.title}</Typography>
      </Breadcrumbs>
      
      {/* Card for Title and Description */}
      <Card sx={{ mb: 3 }}>
        <CardHeader title={team.title} sx={{backgroundColor: team.color, color: "#fff"}}/>
        <CardContent>
         
          <Typography variant="body1" sx={{fontSize: 12, mt: 2}} color="textSecondary" component="p">
            {team.mission}
          </Typography>
        </CardContent>
      </Card>

      {/* Card for Vision */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{fontSize: 20}} component="div" gutterBottom>
            Vision
          </Typography>
          <Typography variant="body1" sx={{fontSize: 12, mt: 2}} color="textSecondary" component="p">
            {team.vision}
          </Typography>
        </CardContent>
      </Card>

      {/* Card for Team Members */}
      <Box sx={{mb:3}}>
        <Typography variant="h6" sx={{fontSize: 18}} component="div" gutterBottom>
          Team Members
        </Typography>
        <Divider />
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', marginTop: '20px' }}>
          {[...Array(5)].map((_, index) => (
            <Card key={index} sx={{ width: 150, height: 150, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {members[index] && userDetails[members[index].userId] ? (
                  <>
                    <Avatar
                      src={userDetails[members[index].userId].picture}
                      alt={userDetails[members[index].userId].name}
                      sx={{ width: 50, height: 50, marginBottom: '10px' }}
                    />
                    <Typography variant="body1" color="textSecondary" component="p" align="center">
                      {userDetails[members[index].userId].name}
                    </Typography>
                    {members[index].memberType === 'leader' && (
                      <Chip
                        label="Team Leader"
                        sx={{ marginTop: '10px' }}
                      />
                    )}
                  </>
                ) : (
                  <Typography variant="body1" color="textSecondary" component="p" align="center">
                    Slot Available
                  </Typography>
                )}
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>

      {objectives.length > 0 && (
        <>
          <Typography variant="h6" sx={{ fontSize: 18 }} component="div" gutterBottom>
            Objectives
          </Typography>
          <Divider />
          <Box sx={{ marginTop: '20px' }}>
            {objectives.map((objective) => (
              <Box key={objective._id} sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ fontSize: 16, mb: 1, borderBottom: "2px solid", borderColor: team.color, p:1, pr: 5, borderRadius: 0, display: "inline-block"}}>
                  Year {objective.year}
                </Typography>
                {objective.goals.map((goal) => (
                  <Card key={goal.goal_number} sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14, mb: 1, fontWeight: 'bold' }} variant="body1" color="textSecondary">
                        Goal {goal.goal_number}: {goal.goal}
                      </Typography>
                      <Typography sx={{ fontSize: 12 }} variant="body1" color="textSecondary">
                        Objective: {goal.objective}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      <Typography variant="body2" color="textSecondary">
                        Target Date: {goal.deadline ? goal.deadline : 'No deadline'}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            ))}
          </Box>
        </>
      )}

      {signingUp ? (
        <CircularProgress style={{ marginTop: '20px' }} />
      ) : (
        <>
          {/* Commenting out the Join Team button */}
          {/* {!isUserMember && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSignUp}
              disabled={members.length >= 5}
              style={{ marginTop: '20px' }}
            >
              Join Team
            </Button>
          )} */}

          {/* {!isUserMember && members.length < 5 && currentTeam && currentTeam.teamId !== id && (
            <Alert severity="info" style={{ marginTop: '20px' }}>
              Joining this team will remove you from your current team: {currentTeam.teamName}.
            </Alert>
          )} */}
        </>
      )}
    </Box>
  );
};

export default StrategicTeamDetail;