import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Rating,
  Checkbox,
  Typography,
} from "@mui/material";
import axios from 'axios';
import { PageWrapper } from "./PageWrapper";
import { format } from 'date-fns';
import UploadForm from './UploadForm'; // Import the UploadForm component

export const ClientSatisfactionOverviewPage = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('responseDate');
  const [open, setOpen] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState('');
  const [uploadModalOpen, setUploadModalOpen] = useState(false); // State for modal

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/.netlify/functions/fetchSatisfaction');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching satisfaction data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  // Reverse the centerIds mapping
const centerNames = {
    59001: 'Online Only',
    59002: 'Logan',
    59004: 'Sandy',
    59005: 'Tooele',
    59006: 'Orem',
    59007: 'Ephraim',
    59008: 'Blanding',
    59010: 'Vernal',
    59012: 'Ogden',
    59015: 'Price',
    59016: 'St George',
    59017: 'Cedar City',
    59027: 'Kaysville',
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpen = (suggestion) => {
    setSelectedSuggestion(suggestion);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContactChange = (id) => {
    setData((prevData) =>
      prevData.map((item) =>
        item._id === id ? { ...item, contact: 2 } : item
      )
    );
  };

  const sortedData = data.sort((a, b) => {
    if (orderBy === 'satisfaction' || orderBy === 'contact') {
      return order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
    }
    if (orderBy === 'responseDate') {
      return order === 'asc'
        ? new Date(a[orderBy]) - new Date(b[orderBy])
        : new Date(b[orderBy]) - new Date(a[orderBy]);
    }
    if (orderBy === 'center') {
      const centerA = centerNames[a.center] || a.center;
      const centerB = centerNames[b.center] || b.center;
      return order === 'asc'
        ? centerA.localeCompare(centerB)
        : centerB.localeCompare(centerA);
    }
    if (orderBy === 'name') {
      const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
      const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
      return order === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    }
    return order === 'asc'
      ? a[orderBy].localeCompare(b[orderBy])
      : b[orderBy].localeCompare(a[orderBy]);
  });

  const averageSatisfaction = data.length
    ? data.reduce((acc, item) => acc + item.satisfaction, 0) / data.length
    : 0;

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <PageWrapper title="Client Satisfaction Overview">
      <Button variant="contained" color="primary" onClick={() => setUploadModalOpen(true)}>
        Update Data
      </Button>
      <Dialog open={uploadModalOpen} onClose={() => setUploadModalOpen(false)}>
        <DialogTitle>Upload Data</DialogTitle>
        <DialogContent>
          <UploadForm />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadModalOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h6" sx={{ my: 2 }} gutterBottom>
  Average Satisfaction: ({averageSatisfaction.toFixed(1)}){' '}
  <Rating value={averageSatisfaction} readOnly precision={0.1} />
</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'responseDate'}
                  direction={orderBy === 'responseDate' ? order : 'asc'}
                  onClick={() => handleRequestSort('responseDate')}
                >
                  Response Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'business'}
                  direction={orderBy === 'business' ? order : 'asc'}
                  onClick={() => handleRequestSort('business')}
                >
                  Business
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'center'}
                  direction={orderBy === 'center' ? order : 'asc'}
                  onClick={() => handleRequestSort('center')}
                >
                  Center
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'targetStaff'}
                  direction={orderBy === 'targetStaff' ? order : 'asc'}
                  onClick={() => handleRequestSort('targetStaff')}
                >
                  Target Staff
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'satisfaction'}
                  direction={orderBy === 'satisfaction' ? order : 'asc'}
                  onClick={() => handleRequestSort('satisfaction')}
                >
                  Satisfaction
                </TableSortLabel>
              </TableCell>
              <TableCell>Suggestions</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'contact'}
                  direction={orderBy === 'contact' ? order : 'asc'}
                  onClick={() => handleRequestSort('contact')}
                >
                  Contact Requested
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((item) => (
              <TableRow key={item._id}>
                <TableCell>{format(new Date(item.responseDate), 'yyyy-MM-dd')}</TableCell>
                <TableCell>
                  <a
                    href={`https://clients.utahsbdc.org/centeric/cdetail?key=${item.customerId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {`${item.firstName} ${item.lastName}`}
                  </a>
                </TableCell>
                <TableCell>{item.business}</TableCell>
                <TableCell>{centerNames[item.center] || item.center}</TableCell>
                <TableCell>{item.targetStaff}</TableCell>
                <TableCell>
                  <Rating value={item.satisfaction} readOnly />
                </TableCell>
                <TableCell>
                  {item.suggestions ? (
                    <Button variant="outlined" onClick={() => handleOpen(item.suggestions)}>
                      View Comment
                    </Button>
                  ) : (
                    "No Comment"
                  )}
                </TableCell>
                <TableCell>
                  {item.contact === 1 ? (
                    <Checkbox
                      checked={item.contact === 2}
                      onChange={() => handleContactChange(item._id)}
                    />
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Suggestion</DialogTitle>
        <DialogContent>
          <DialogContentText>{selectedSuggestion}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </PageWrapper>
  );
};