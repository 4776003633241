import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const data = [
    { year: '2021', IowaClients: 607, KansasClients: 849, UtahClients: 406, IowaCapital: 99604210, KansasCapital: 81918631, UtahCapital: 112986506, IowaStarts: 208, KansasStarts: 175, UtahStarts: 354 },
    { year: '2022', IowaClients: 620, KansasClients: 934, UtahClients: 633, IowaCapital: 121595607, KansasCapital: 95840444, UtahCapital: 88719162, IowaStarts: 213, KansasStarts: 200, UtahStarts: 367 },
    { year: '2023', IowaClients: 598, KansasClients: 819, UtahClients: 743, IowaCapital: 82687654, KansasCapital: 99577760, UtahCapital: 119882431, IowaStarts: 249, KansasStarts: 223, UtahStarts: 363 },
  ];

// Helper function to format the tick values as dollar currency
const formatCurrency = (value) => `$${value.toLocaleString('en-US')}`;

const CustomTooltip = ({ active, payload, label, formatter }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          {payload && payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${formatter(entry.value)}`}
            </p>
          ))}
        </div>
      );
    }
  
    return null;
  };
  

const BenchmarkChart = ({ dataKey, title, useCurrencyFormat, margin }) => (
    <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
      <Typography variant="h6" component="h2" gutterBottom>
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={margin}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis tickFormatter={useCurrencyFormat ? formatCurrency : undefined} />
          <Tooltip content={<CustomTooltip formatter={useCurrencyFormat ? formatCurrency : value => value} />} />
          <Legend />
          <Line type="monotone" dataKey={`Utah${dataKey}`} stroke="#034B85" name="Utah SBDC" />
          <Line type="monotone" dataKey={`Kansas${dataKey}`} stroke="#EA3546" name="Kansas SBDC" />
          <Line type="monotone" dataKey={`Iowa${dataKey}`} stroke="#F9C80E" activeDot={{ r: 8 }} name="Iowa SBDC" />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
  
  const defaultMargin = { top: 16, right: 32, left: 0, bottom: 0 };
  const capitalMargin = { top: 16, right: 32, left: 80, bottom: 0 };
  
  const BenchmarkGraphs = () => (
    <div>
      <BenchmarkChart dataKey="Clients" title="Clients 5+ Hours" useCurrencyFormat={false} margin={defaultMargin} />
      <BenchmarkChart dataKey="Capital" title="Capital Infusion Amount" useCurrencyFormat={true} margin={capitalMargin} />
      <BenchmarkChart dataKey="Starts" title="New Business Starts" useCurrencyFormat={false} margin={defaultMargin} />
    </div>
  );
  
  export default BenchmarkGraphs;