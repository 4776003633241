import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

// Styling for the paper component
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function CourseStats() {
  // Your data object
  const data = {
    students: 438,
    videos: 1,
    learning_units: 20,
    video_time: 83,
    avg_score_rate: 0,
    success_rate: 0.46,
    total_study_time: 16782,
    avg_time_to_finish: 646,
    social_interactions: 0,
    certificates_issued: 0,
  };

  // Utility function to convert seconds to a more readable format
  const formatTime = (seconds) => {
    if (seconds < 3600) { // Less than 1 hour
      return `${(seconds / 60).toFixed(1)} minutes`;
    } else { // Equal to or more than 1 hour
      return `${(seconds / 3600).toFixed(1)} hours`;
    }
  };

  return (
    <Grid container spacing={3}>
      {Object.entries(data).map(([key, value]) => (
        <Grid item xs={12} sm={6} md={4} key={key}>
          <Item elevation={3}>
            <Typography variant="h6" component="h3" gutterBottom>
              {key.replace(/_/g, ' ').toUpperCase()}
            </Typography>
            <Typography variant="body1">
              {key === 'total_study_time'
                ? formatTime(value)
                : typeof value === 'number' && key.includes('rate')
                ? `${(value * 100).toFixed(2)}%`
                : value}
            </Typography>
          </Item>
        </Grid>
      ))}
    </Grid>
  );
}

export default CourseStats;
