import axios from 'axios';
import sectorMap from '../charts/sectorMap';

const apiData = {
  query: '',
  filter: {
    connective: 'and',
    propositions: [
      {
        attribute: 'fips_code',
        relation: 'equals',
        value: '',
      },
      {
        attribute: 'primary_naics_code_id',
        relation: 'equals',
        value: '',
      },
      {
        relation: 'range',
        attribute: 'in_business_on',
        value: {
          start: '2020-01-01',
          end: '2022-12-31',
          relation: 'contains',
        },
      },
    ],
  },
  limit: 0,
};

const fetchData = async (
  fipsCode, 
  naicsCode, 
  startDate, 
  endDate, 
  businessAttribute = 'in_business_on', 
  employeeCount = '', 
  employeeCountCondition = '',
  salesVolume = '',
  salesVolumeCondition = '',
  limit = 0  // Add limit parameter with a default value of 0
) => {
  // Creating a deep copy of apiData
  const data = JSON.parse(JSON.stringify(apiData));
  
  data.filter.propositions[0].value = fipsCode;
  data.filter.propositions[1].value = naicsCode;
  data.filter.propositions[2].attribute = businessAttribute;
  data.filter.propositions[2].value.start = startDate;
  data.filter.propositions[2].value.end = endDate;
  data.limit = limit;  // Use the limit parameter in the API request data

  // Conditionally add employee count and condition to the API request if they are submitted
  if (employeeCount && employeeCountCondition) {
    data.filter.propositions.push({
      attribute: 'location_employee_count',
      relation: employeeCountCondition,
      value: employeeCount,
    });
  }

  // Conditionally add sales volume and its condition to the API request if they are submitted
  if (salesVolume && salesVolumeCondition) {
    data.filter.propositions.push({
      attribute: 'location_sales_volume',
      relation: salesVolumeCondition,
      value: salesVolume,
    });
  }

  try {
    const response = await axios.post("/.netlify/functions/api", JSON.stringify(data));
    
    const formattedData = {};
    const responseData = {};
    responseData[naicsCode] = response.data;
    
    const sectorNames = Object.keys(sectorMap).filter(key => sectorMap[key].includes(naicsCode));
    sectorNames.forEach(sectorName => {
      if (!formattedData[sectorName]) {
        formattedData[sectorName] = {
          count: response.data.count,
          codes: [naicsCode],
          vCodes: naicsCode,
        };
      } else {
        formattedData[sectorName].count += response.data.count;
        if (!formattedData[sectorName].codes.includes(naicsCode)) {
          formattedData[sectorName].codes.push(naicsCode);
          formattedData[sectorName].vCodes = formattedData[sectorName].codes.join("-");
        }
      }
    });

    return { responseData, formattedData };
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export { fetchData };