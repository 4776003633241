import React, { useState, useEffect } from 'react';
import { Button, Select, MenuItem, TextField, FormControl, InputLabel, Tooltip } from '@mui/material';
import { fetchData } from './utils/api';
import AxleDisplay from './AxleDisplay';
import AxleBusinessGrid from './AxleBusinessGrid';


const AxleData = ({userData}) => {
  const [fipsCode, setFipsCode] = useState('');
  const [naicsCode, setNaicsCode] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [businessAttribute, setBusinessAttribute] = useState('in_business_on');
  const [apiData, setApiData] = useState(null);
  const [employeeCount, setEmployeeCount] = useState('');
  const [employeeCountCondition, setEmployeeCountCondition] = useState('');
  const [salesVolume, setSalesVolume] = useState('');
  const [salesVolumeCondition, setSalesVolumeCondition] = useState('');
  const [limit, setLimit] = useState(0);  // New state variable for limit

  const [detailedData, setDetailedData] = useState(null);  // New state variable for detailed data






  const sectorMap = {
    "Agriculture, Forestry, Fishing and Hunting": ["11"],
    "Mining, Quarrying, and Oil and Gas Extraction": ["21"],
    "Utilities": ["22"],
    "Construction": ["23"],
    "Manufacturing": ["31", "32", "33"],
    "Wholesale Trade": ["42"],
    "Retail Trade": ["44", "45"],
    "Transportation and Warehousing": ["48", "49"],
    "Information": ["51"],
    "Finance and Insurance": ["52"],
    "Real Estate and Rental and Leasing": ["53"],
    "Professional, Scientific, and Technical Services": ["54"],
    "Management of Companies and Enterprises": ["55"],
    "Administrative and Support and Waste Management and Remediation Services": ["56"],
    "Educational Services": ["61"],
    "Health Care and Social Assistance": ["62"],
    "Arts, Entertainment, and Recreation": ["71"],
    "Accommodation and Food Services": ["72"],
    "Other Services (except Public Administration)": ["81"]
  };

  const businessAttributes = [
    {value: 'created_at', label: 'Created At'},
    {value: 'in_business_determined_at', label: 'In Business Determined At'},
    {value: 'in_business_on', label: 'In Business On'},
    {value: 'opened_for_business_on', label: 'Opened For Business On'},
    {value: 'estimated_opened_for_business', label: 'Estimated Opened For Business'}
  ];



const businessAttributeDescriptions = `
    created_at - The date and time when the place record was created.
    in_business_determined_at - The date and time when the in business status was made.
    in_business_on - The date the business was determined to be in business.
    opened_for_business_on - The date when the place opened for business.
    estimated_opened_for_business - Estimates when the place opened for business.
  `;

  const employeeCountConditions = [
    {value: '', label: 'None'},
    {value: 'greater_than', label: 'Greater Than'},
    {value: 'less_than', label: 'Less Than'},
    {value: 'missing', label: 'Missing'},
    {value: 'present', label: 'Present'}
  ];


  const salesVolumeConditions = [
    {value: '', label: 'None'},
    {value: 'greater_than', label: 'Greater Than'},
    {value: 'less_than', label: 'Less Than'},
    {value: 'missing', label: 'Missing'},
    {value: 'present', label: 'Present'}
  ];

  useEffect(() => {
    if (employeeCountCondition === 'missing' || employeeCountCondition === 'present'  || employeeCountCondition === '') {
      setEmployeeCount(0);
    }
    if (salesVolumeCondition === 'missing' || salesVolumeCondition === 'present' || salesVolumeCondition === '' ) {
      setSalesVolume(0);
    }

    console.log(detailedData, 'detailedData');
  }, [employeeCountCondition, salesVolumeCondition, detailedData]);



  const fetchDataWithParams = async (limit) => {
    // Basic form validation
    if (!fipsCode || !naicsCode || !startDate || !endDate || !businessAttribute) {
      alert("All fields are required!");
      return;
    }

    const data = await fetchData(fipsCode, naicsCode, startDate, endDate, businessAttribute, employeeCount, employeeCountCondition, salesVolume, salesVolumeCondition, limit);
    return data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await fetchDataWithParams(0);  // Using 0 as limit for initial fetch
    setApiData(data);
  };

  const handleViewData = async (newLimit) => {
    const data = await fetchDataWithParams(newLimit);
    setDetailedData(data);
  };


  return (
    <div>
<form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{display: 'flex', gap: '16px', marginBottom: '16px'}}>
      <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="fips-label">FIPS Code</InputLabel>
          <Select
          required
            labelId="fips-label"
            value={fipsCode}
            onChange={(e) => setFipsCode(e.target.value)}
            label="FIPS Code"
          >
            {userData.counties.map((countyData) => (
              <MenuItem key={countyData.fips} value={countyData.fips}>
                {countyData.county} ({countyData.fips})
              </MenuItem>
            ))}
          </Select>
        </FormControl>


        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="naics-label">NAICS Code</InputLabel>
          <Select
          required
            labelId="naics-label"
            value={naicsCode}
            onChange={(e) => setNaicsCode(e.target.value)}
            label="NAICS Code"
          >
            {Object.entries(sectorMap).map(([sector, codes]) => (
              codes.map(code => (
                <MenuItem key={code} value={code}>
                  {sector} ({code})
                </MenuItem>
              ))
            ))}
          </Select>
        </FormControl>

        

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="business-attribute-label">Business Attribute</InputLabel>
          <Tooltip title={businessAttributeDescriptions} arrow placement="top-start">
          <Select
          required
            labelId="business-attribute-label"
            value={businessAttribute}
            onChange={(e) => setBusinessAttribute(e.target.value)}
            label="Business Attribute"
          >
            {businessAttributes.map((attr) => (
              <MenuItem key={attr.value} value={attr.value}>
                {attr.label}
              </MenuItem>
            ))}
          </Select>
          </Tooltip> 
        </FormControl>

        <TextField
        required
          label="Start Date"
          type="date"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />

        <TextField
        required
          label="End Date"
          type="date"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />

       
<FormControl fullWidth variant="outlined" margin="normal">
    <InputLabel id="employee-condition-label">Employee Count Condition</InputLabel>
    <Select
        labelId="employee-condition-label"
        value={employeeCountCondition}
        onChange={(e) => setEmployeeCountCondition(e.target.value)}
        label="Employee Count Condition"
    >
       
        {employeeCountConditions.map((condition) => (
            <MenuItem key={condition.value} value={condition.value}>
                {condition.label}
            </MenuItem>
        ))}
    </Select>
</FormControl>



        <TextField
          label="Employee Count"
          type="number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={employeeCount}
          onChange={(e) => setEmployeeCount(e.target.value)}
          disabled={employeeCountCondition === 'missing' || employeeCountCondition === 'present' || employeeCountCondition === ''}
        />

<FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="sales-volume-condition-label">Sales Volume Condition</InputLabel>
          <Select
            labelId="sales-volume-condition-label"
            value={salesVolumeCondition}
            onChange={(e) => setSalesVolumeCondition(e.target.value)}
            label="Sales Volume Condition"
          >
            
            {salesVolumeConditions.map((condition) => (
              <MenuItem key={condition.value} value={condition.value}>
                {condition.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Sales Volume"
          type="number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={salesVolume}
          onChange={(e) => setSalesVolume(e.target.value)}
          disabled={salesVolumeCondition === 'missing' || salesVolumeCondition === 'present' || salesVolumeCondition === ''}
        />

        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
        </div>
      </form>
      <div>
        {apiData && <AxleDisplay data={apiData} onViewData={() => handleViewData(50)} />}
      </div>

      <div>
        {detailedData &&  <AxleBusinessGrid data={detailedData} />}
       
      </div>
    </div>
  );
};

export default AxleData;