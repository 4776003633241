import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { CodeSnippet } from "../components/code-snippet";
import { PageLayout } from "../components/page-layout";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import ComputerIcon from '@mui/icons-material/Computer';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';




import { MarketSegmentationTable } from "../components/MarketSegmentationTable";
import EAReports from "../components/eaReports";


export const ReportsPage = (props) => {



    const { user } = useAuth0();
    const [data, setData] = useState(null);
    const [total, setTotal] = useState();
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(true);


    const [responseData, setResponseData] = useState({});



    useEffect(() => {
        // async function fetchData() {


        //     const responses = await Promise.all(
        //         naicsCodes.map(code => {
        //             const data = { ...apiData };
        //             data.filter.propositions[1].value = code;
        //             return axios.post('/.netlify/functions/api', JSON.stringify(data));
        //         })
        //     );

        //     const data = {};
        //     let count = 0;
        //     responses.forEach((response, index) => {
        //         const code = naicsCodes[index];
        //         data[code] = response.data;
        //         count++;
        //         console.log((count / naicsCodes.length) * 100);
        //         setProgress(Math.round((count / naicsCodes.length) * 100));
        //     });
        //     setResponseData(data);
        // }

        // fetchData();

        const fetchCollectionData = async (collectionName) => {
            // const apiUrl = "/.netlify/functions/getData";
            const apiUrl = "/.netlify/functions/getData";
            const endpoint = `${apiUrl}?database=eaReports&collection=${collectionName}`;

            try {
                const response = await axios.get(endpoint);
                const data = response.data;
                setResponseData(data);
                console.log(data);
            } catch (error) {
                console.error(error);
            }
        };

        // Call the function with the collection parameter
        let centerName = user.center.replace(/\s+/g, '');
        fetchCollectionData(centerName);




    }, []);





    const uniqueEmails = Object.values(responseData).reduce((unique, item) => {
        if (!unique.includes(item.email)) {
            unique.push(item.email);
        }
        return unique;
    }, []);


    function calculateTotalLoggableHours() {
        const loggableHoursArray = Object.values(responseData).map(item => item.loggableHours);
        const totalLoggableHours = loggableHoursArray.reduce((acc, curr) => acc + parseFloat(curr), 0);
        return parseFloat(totalLoggableHours.toFixed(2));
    }

    // [...new Set(responseData.map(item => item.email))];
    // const uniqueEmails = [0, 1]

    let gridNum;
    if (user.counties >= 3) {
        gridNum = 3;
    } else {
        gridNum = 4;
    }



    if (!user && responseData == null) {
        return null;
    }


    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));



    return (
        <PageLayout>

            <div className="content-layout">

                <Typography sx={{ textTransform: 'capitalize' }}>
                    <h1 id="page-title" >
                        Entrepreneur Academy
                    </h1>
                </Typography>
                <Typography sx={{ textTransform: 'capitalize' }}>
                    <h4 id="page-subtitle" >
                        {user.center} Center Usage Report
                    </h4>
                </Typography>

                <Typography sx={{ my: 2, fontStyle: 'italic' }}>
                    Data through 1/31/2023
                </Typography>


                <div className="content__body">
                    <Grid container spacing={2}>

                        <Grid lg={4}>


                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ textAlign: 'center', mb: 1 }}>
                                        <ComputerIcon fontSize="large" />
                                    </Typography>
                                    <Typography sx={{ textAlign: 'center', fontSize: 12 }}>
                                        Total # of Sessions
                                    </Typography>

                                    <Typography sx={{ textAlign: 'center', pt: 1, fontSize: 24, fontWeight: 'bold' }}>
                                        {responseData
                                            ? responseData.length
                                            : 0
                                        }

                                    </Typography>

                                </CardContent>
                                <CardActions>

                                </CardActions>
                            </Card>








                        </Grid>

                        <Grid lg={4}>


                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ textAlign: 'center', mb: 1 }}>
                                        <PersonIcon fontSize="large" />
                                    </Typography>
                                    <Typography sx={{ textAlign: 'center', fontSize: 12 }}>
                                        Total # Unique Users
                                    </Typography>

                                    <Typography sx={{ textAlign: 'center', pt: 1, fontSize: 24, fontWeight: 'bold' }}>
                                        {uniqueEmails.length}

                                    </Typography>

                                </CardContent>
                                <CardActions>

                                </CardActions>
                            </Card>








                        </Grid>

                        <Grid lg={4}>


                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ textAlign: 'center', mb: 1 }}>
                                        <AccessTimeFilledIcon fontSize="large" />
                                    </Typography>
                                    <Typography sx={{ textAlign: 'center', fontSize: 12 }}>
                                        Total Loggable Hours
                                    </Typography>

                                    <Typography sx={{ textAlign: 'center', pt: 1, fontSize: 24, fontWeight: 'bold' }}>
                                        {calculateTotalLoggableHours()}

                                    </Typography>

                                </CardContent>
                                <CardActions>

                                </CardActions>
                            </Card>








                        </Grid>

                        <Grid lg={12}>


                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>


                                    <EAReports data={responseData} />

                                </CardContent>
                                <CardActions>

                                </CardActions>
                            </Card>








                        </Grid>







                    </Grid>

                </div>
            </div>
        </PageLayout>
    );
};
