import React from 'react';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { PageWrapper } from '../pages/PageWrapper';
import PodcastPlayer from './PodcastPlayer';
// const useStyles = makeStyles({
//   card: {
//     maxWidth: 345,
//   },
//   media: {
//     height: 140,
//   },
// });


const Podcasts = () => {
  // const classes = useStyles();
  const logos = [
    {
      title: 'Utah SBDC Logo',
      imageUrl: '/images/branding/Utah-color-with-band-thumb.png',
      linkUrl: '/images/branding/Utah-color-with-band-thumb.png',
      fileType: 'png',
    },
    {
      title: 'Utah SBDC Logo (Vector)',
      imageUrl: '/images/branding/Utah-color-with-band-thumb.png',
      linkUrl: '/images/branding/Utah-color-with-band.eps',
      fileType: 'eps',
    }, {
        title: 'Powered By SBA Logo (JPEG)',
        imageUrl: '/images/branding/SBA-PoweredBy.jpg',
        linkUrl: '/images/branding/SBA-PoweredBy.jpg',
        fileType: 'jpg',
      },
      , {
        title: 'Powered By SBA Logo (PNG)',
        imageUrl: '/images/branding/SBA-PoweredBy.png',
        linkUrl: '/images/branding/SBA-PoweredBy.png',
        fileType: 'png',
      },

      , {
        title: 'Powered By SBA Logo (EPS - Vector)',
        imageUrl: '/images/branding/SBA-PoweredBy.jpg',
        linkUrl: '/images/branding/SBA-PoweredBy-2color.eps',
        fileType: 'eps',
      },


      {
        title: 'Go Utah Logo',
        imageUrl: '/images/branding/Go_Utah_BLACK_Standard.png',
        linkUrl: '/images/branding/Go_Utah_BLACK_Standard.png',
        fileType: 'png',
      },

      {
        title: 'Go Utah White Logo',
        imageUrl: '/images/branding/Go_Utah_white_Standard.png',
        linkUrl: '/images/branding/Go_Utah_white_Standard.png',
        fileType: 'png',
        version: 'light'
      },


     
      
      //https://utah-sbdc-website.s3.us-west-2.amazonaws.com/sba-logos.zip
    // Add more logos here
  ];



  return (
    <PageWrapper title="Podcasts" secondaryTitle={"Startup Utah"}>
    <Grid container spacing={3}>
   
   <Typography sx={{px: 4, mt: 2, fontSize: 12}}>
   Spotlighting the Innovators and Entrepreneurs of the Beehive State. Each episode delves into the unique journeys, challenges, and triumphs of Utah's vibrant startup scene. From culinary delights to tech disruptors, join us as we celebrate the stories of those who dream big and make it happen. Brought to you by the Utah SBDC, championing the spirit of small business success.
   </Typography>
        <Grid item  xs={12} sm={12} md={12}>
        <Card sx={{p: 5, pb: 2}}>

        <iframe src="https://podcasters.spotify.com/pod/show/utah-sbdc/embed/episodes/Pretty-Birds-Flight-Comedy--Culture--and-Culinary-Success-in-Utah-e2bdqou/a-aaidt14" height="auto" width="100%" frameborder="0" scrolling="no"></iframe>
    
         {/* <PodcastPlayer
        episodeNumber={1}
        podcastTitle="Pretty Bird's Flight: Comedy, Culture, and Culinary Success in Utah"
        thumbnail="https://utahsbdc.org/wp-content/uploads/2023/02/Logo-PrettyBird.jpg"
        audioSrc="https://utahsbdc.org/wp-content/uploads/2023/11/StartupUtah_ep1_PrettyBirdHotChicken.mp3"
      /> */}
       <Typography sx={{m: 1,fontSize: 14, fontWeight: 'bold'}}>
            Episode 1 - Pretty Bird's Flight
            </Typography>
            <Typography sx={{m: 1,fontSize: 14}}>
            Discover the story behind Pretty Bird Hot Chicken, a Utah favorite with a humorous cinematic origin. Dive into the challenges, passions, and the undeniable spirit of innovation that led this startup to soar. Brought to you by Startup Utah and the Utah SBDC.
            </Typography>
    
        </Card>
        </Grid>
  
    </Grid>
    </PageWrapper>
  );
};

export default Podcasts;
